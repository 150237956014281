/* eslint-disable @typescript-eslint/no-unsafe-argument */
import React, { useEffect, useMemo, useState } from 'react'
import { createTheme, ThemeProvider } from '@mui/material'
import { SnackbarProvider } from '../components/snackbar/snackbar'
import { enqueueSnackbar } from 'notistack'
import { useLocation, useSearchParams } from 'react-router-dom'
import {
  getAreaList,
  getProjectStatus,
  getROIs,
} from '../services/collation-service'
import { useTranslation } from 'react-i18next'
import axios from 'axios'
import { meAPI } from '../modules/auth/signup/auth-services'

export const highlightsContext = React.createContext<any>({} as any)
export const UserContext = React.createContext<any>({} as any)
export const ProjectContext = React.createContext<any>({} as any)
export const TargetContext = React.createContext<any>({} as any)
export const StatusContext = React.createContext<any>({} as any)

// create layout context
export const layoutContext = React.createContext({
  layout: [[0, 50, 50, 0], true, false, false, true],
  updateLayout: (layout: any[]) => {},
})

// custom mui theme here
const theme = createTheme({
  typography: {
    fontFamily: ['Outfit', 'sans-serif'].join(','),
    button: {
      textTransform: 'none',
    },
  },
})
// Color mapping for different feedback states

const colorMapping = {
  like: '#ddffd7',
  dislike: '#ffebeb',
  null: '#FFF6BB',
}
// Function to wrap children with the context providers and theme provider
const GlobalContextProvider: React.FC<{ children: React.ReactNode }> = ({
  children,
}) => {
  const [highlightList, setHighlightList] = React.useState<any[]>([])
  const [projectId, setProjectId] = useState<any>(null)
  const [targetId, setTargetId] = useState<any>(null)
  const [phraseId, setPhraseId] = useState<any>(null)

  const [highlightAllStates, setHighlightAllStates] = useState({})

  const [selectedHighlight, setSelectedHighlight] = React.useState<any[]>([])
  const [selectedHighlightId, setSelectedHighlightId] = useState<string>('')
  const [collationList, setCollationList] = React.useState<any>(null)
  const [areaList, setAreaList] = React.useState<any>([])
  const [isAreaDataLoading, setIsAreaDataLoading] = useState<boolean>(true)
  const [isNewAreaAdded, setIsNewAreaAdded] = useState<boolean>(true)
  const [roiList, setRoiList] = React.useState<any>([])
  const [isROIDataLoading, setIsROIDataLoading] = useState<boolean>(true)
  const [isNewRoiAdded, setIsNewRoiAdded] = useState<boolean>(false)
  const [isCollationDataLoading, setIsCollationDataLoading] =
    useState<boolean>(false)
  const [isManualCollationInProgress, setIsManualCollationInProgress] =
    useState<boolean>(false)
  const [showTargetPdfAndCollation, setShowTargetPdfAndCollation] = useState<
    boolean | any
  >(false)
  const [isCollationUpdating, setIsCollationUpdating] = useState(false)
  const projectNameLs = localStorage.getItem(
    `project_name_with_id_${projectId}`,
  )
  const [projectName, setProjectName] = useState(() => {
    return projectNameLs ? JSON.parse(projectNameLs) : ''
  })

  // Simplify layout state initialization
  const [layout, setLayout] = useState(() => {
    const layoutFromLS = localStorage.getItem('layoutTypeObject')
    return layoutFromLS
      ? JSON.parse(layoutFromLS)
      : [[0, 50, 50, 0], true, false, false, true]
  })

  const [collationName, setCollationName] = useState('')
  const [isPhraseEditMode, setIsPhraseEditMode] = useState({
    isEditMode: false,
    areaId: null,
    phraseId: null,
    phrases: [],
  })
  const [isPhraseAddMode, setIsPhraseAddMode] = useState({
    isAddMode: false,
    areaId: null,
    phraseId: null,
    phrases: [],
  })
  const [isCollationEditMode, setIsCollationEditMode] = useState({
    isEditMode: false,
    phraseId: null,
    collation: null,
  })
  // Project level status for (Collation, Source Phrases & Target Documents)
  const [projectStatus, setProjectStatus] = useState('')
  const [collationStatus, setCollationStatus] = useState('')
  const [targetDocumentStatus, setTargetDocumentStatus] = useState('')
  const [sourcePhraseStatus, setSourcePhraseStatus] = useState('')
  const [targetFinalizeStatus, setTargetFinalizeStatus] = useState('')
  const [manualCollation, setManualCollation] = useState({
    isEditMode: false,
    phraseId: null,
  })
  const [selectedCollation, setSelectedCollation] = useState<any>([])
  const [isAreaSearched, setIsAreaSearched] = useState(false)
  const [reqireCallGetTarget, setReqireCallGetTarget] = useState(false)
  const [sourcePhrases, setSourcePhrases] = useState<any[]>([])
  const [isProgressComplete, setIsProgressComplete] = useState<any>(true)
  const [userRole, setUserRole] = useState<string>('')
  const [areaQuery, setAreaQuery] = useState<string>('')
  const location = useLocation()
  const [queryParameters] = useSearchParams()
  const { t } = useTranslation()

  useEffect(() => {
    // No need for the new URLSearchParams here since useSearchParams hook is used
    // which is specifically designed for this purpose in React Router v6.
    const newProjectId = queryParameters.get('project_id')
    const newTargetId = queryParameters.get('target_id')

    if (newProjectId !== null) {
      setProjectId(newProjectId) // Sets projectId if present in the URL
    }

    if (newTargetId !== null) {
      setTargetId(newTargetId) // Sets targetId if present in the URL
    }

    // This effect should run whenever `location.search` changes,
    // which happens when the URL's search parameters change.
  }, [location.search, queryParameters])

  useEffect(() => {
    const handleStorageChange = (event) => {
      if (event.key === `project_name_with_id_${projectId}`) {
        // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
        setProjectName(event.newValue ? JSON.parse(event.newValue).name : '')
      }
    }

    window.addEventListener('storage', handleStorageChange)
    return () => {
      window.removeEventListener('storage', handleStorageChange)
    }
  }, [])

  const updateProjectName = (newName) => {
    localStorage.setItem(
      `project_name_with_id_${projectId}`,
      JSON.stringify(newName),
    )
    setProjectName(newName)
  }

  const handleGetAreaList = (id) => {
    roiList?.length > 0 || areaList?.length > 0
      ? setIsAreaDataLoading(false)
      : setIsAreaDataLoading(true)
    setIsNewAreaAdded(false)
    getAreaList(id)
      .then((res) => {
        if (res.status === 200) {
          setIsAreaDataLoading(false)
          setIsNewAreaAdded(true)
          setAreaList(res?.data)
          if (res?.data?.length === 0) {
            setShowTargetPdfAndCollation(false)
            setCollationList(null)
          }
        }
      })
      .catch((error) => {
        // To handle cancel axios error
        if (axios.isCancel(error)) {
          console.debug('Request canceled:', error)
        } else if (
          error?.response?.data?.detail &&
          error?.response?.request?.status !== 500 &&
          error?.response?.request?.status !== 502
        ) {
          setIsNewAreaAdded(true)
          setIsAreaDataLoading(false)
          enqueueSnackbar(`${error?.response?.data?.detail}`, {
            variant: 'error',
            persist: true,
          })
        }
        if (error?.response?.request?.status === 502) {
          setIsNewAreaAdded(true)
          setIsAreaDataLoading(false)
          enqueueSnackbar(`Something went wrong`, {
            variant: 'error',
            persist: true,
          })
        }
      })
  }
  const handleGetROIs = (id) => {
    roiList?.length > 0 || areaList?.length > 0
      ? setIsROIDataLoading(false)
      : setIsROIDataLoading(true)
    setIsNewRoiAdded(false)
    getROIs(id)
      .then((res) => {
        if (res.status === 200) {
          setIsROIDataLoading(false)
          setIsNewRoiAdded(true)
          setRoiList(res.data.data)
        }
      })
      .catch((error) => {
        setIsROIDataLoading(false)
        setIsNewRoiAdded(false)
        // To handle cancel axios error
        if (axios.isCancel(error)) {
          console.debug('Request canceled:', error)
        } else if (
          error?.response?.data?.detail &&
          error?.response?.request?.status !== 500 &&
          error?.response?.request?.status !== 502
        ) {
          enqueueSnackbar(`${error?.response?.data?.detail}`, {
            variant: 'error',
            persist: true,
          })
        }
        if (error?.response?.request?.status === 502) {
          enqueueSnackbar(`Something went wrong`, {
            variant: 'error',
            persist: true,
          })
        }
      })
  }
  const handleProjectStatus = (newProjectId) => {
    if (newProjectId) {
      getProjectStatus(newProjectId)
        .then((res) => {
          if (res && res.status === 200) {
            setProjectStatus(res?.data?.project_status)
            setCollationStatus(res?.data?.collation_status)
            setTargetDocumentStatus(res?.data?.target_documents_status)
            setSourcePhraseStatus(res?.data?.source_phrase_status)
            setTargetFinalizeStatus(res?.data?.are_targets_finalized)
          }
        })
        .catch((error) => {
          // To handle cancel axios error
          if (axios.isCancel(error)) {
            console.debug('Request canceled:', error)
          } else if (
            error?.response?.data?.detail &&
            error.response.request.status === 403
          ) {
            enqueueSnackbar(`${error?.response?.data?.detail}.`, {
              variant: 'error',
              persist: true,
            })
          }
          if (error?.response?.request?.status === 502) {
            enqueueSnackbar(`Something went wrong`, {
              variant: 'error',
              persist: true,
            })
          }
        })
    }
  }

  // To revert all states values to initial values when user route back to Home page
  useEffect(() => {
    setProjectName(projectNameLs ? JSON.parse(projectNameLs) : '')
    if (
      location.pathname?.includes('home') ||
      location.pathname?.includes('result') ||
      location.pathname?.includes('account') ||
      location.pathname?.includes('user')
    ) {
      localStorage.removeItem(`project_name_with_id_${projectId}`)
      setHighlightList([])
      setCollationList([])
      setSelectedCollation([])
      setSelectedHighlight([])
      setSelectedHighlightId('')
      // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
      setLayout([[0, 50, 50, 0], true, false, false, true])
      setHighlightAllStates({})
      setProjectId(null)
      setTargetId(null)
      setProjectName(null)
      setShowTargetPdfAndCollation(false)
      setAreaList([])
      setRoiList([])
      setSourcePhrases([])
      setProjectStatus('')
      setTargetDocumentStatus('')
      setCollationStatus('')
      setSourcePhraseStatus('')
      setTargetFinalizeStatus('')
      setCollationName('')
      setIsProgressComplete(true)
      setIsPhraseEditMode({
        isEditMode: false,
        areaId: null,
        phraseId: null,
        phrases: [],
      })
      setIsCollationEditMode({
        isEditMode: false,
        phraseId: null,
        collation: null,
      })
      setIsPhraseAddMode({
        isAddMode: false,
        areaId: null,
        phraseId: null,
        phrases: [],
      })
      setIsCollationUpdating(false)
      localStorage.setItem(
        'layoutTypeObject',
        JSON.stringify([[0, 50, 50, 0], true, false, false, true]),
      )
      setManualCollation({
        isEditMode: false,
        phraseId: null,
      })
      setIsManualCollationInProgress(false)
      setIsAreaSearched(false)
      setReqireCallGetTarget(false)
      localStorage.removeItem('project_details')
      localStorage.removeItem('target_scale')
      localStorage.removeItem('source_scale')
      localStorage.removeItem('target_rotation')
      localStorage.removeItem('source_rotation')
      setAreaQuery('')
      setPhraseId(null)
    }
  }, [location.pathname])
  useEffect(() => {
    if (
      location.pathname?.includes('home') ||
      location.pathname?.includes('account')
    ) {
      setProjectStatus('')
      setTargetFinalizeStatus('')
    }
  }, [projectStatus])

  // To set color of highlight based on feedback value
  const revertColor = (feedback: any) => {
    if (feedback === 'True' || feedback === true) {
      return colorMapping.like
    }
    if (feedback === 'False' || feedback === false) {
      return colorMapping.dislike
    } else {
      return colorMapping.null
    }
  }

  // To set color of highlight based on feedback value
  const darkColor = (feedback: any) => {
    if (feedback === 'True' || feedback === true) {
      return colorMapping.like
    }
    if (feedback === 'False' || feedback === false) {
      return colorMapping.dislike
    } else {
      return colorMapping.null
    }
  }
  // useMemo for context values
  const highlightsValue = useMemo(
    () => ({
      highlightList,
      setHighlightList,
      selectedHighlight,
      setSelectedHighlight,
      darkColor,
      revertColor,
      areaList,
      setAreaList,
      roiList,
      setRoiList,
      collationName,
      setCollationName,
      isPhraseEditMode,
      setIsPhraseEditMode,
      handleGetAreaList,
      handleGetROIs,
      showTargetPdfAndCollation,
      setShowTargetPdfAndCollation,
      collationList,
      setCollationList,
      manualCollation,
      setManualCollation,
      phraseId,
      setPhraseId,
      isAreaDataLoading,
      isROIDataLoading,
      setIsAreaDataLoading,
      setIsROIDataLoading,
      isCollationEditMode,
      setIsCollationEditMode,
      isCollationDataLoading,
      setIsCollationDataLoading,
      isNewRoiAdded,
      setIsNewRoiAdded,
      isNewAreaAdded,
      setIsNewAreaAdded,
      isManualCollationInProgress,
      setIsManualCollationInProgress,
      selectedCollation,
      setSelectedCollation,
      isCollationUpdating,
      setIsCollationUpdating,
      isPhraseAddMode,
      setIsPhraseAddMode,
      selectedHighlightId,
      setSelectedHighlightId,
      isAreaSearched,
      setIsAreaSearched,
      highlightAllStates,
      setHighlightAllStates,
      handleProjectStatus,
      reqireCallGetTarget,
      setReqireCallGetTarget,
      sourcePhrases,
      setSourcePhrases,
      isProgressComplete,
      setIsProgressComplete,
      areaQuery,
      setAreaQuery,
    }),
    [
      highlightList,
      selectedHighlight,
      areaList,
      roiList,
      collationName,
      isPhraseEditMode,
      showTargetPdfAndCollation,
      collationList,
      manualCollation,
      phraseId,
      isAreaDataLoading,
      isROIDataLoading,
      isCollationEditMode,
      isCollationDataLoading,
      isNewRoiAdded,
      isNewAreaAdded,
      isManualCollationInProgress,
      selectedCollation,
      isCollationUpdating,
      isPhraseAddMode,
      selectedHighlightId,
      isAreaSearched,
      highlightAllStates,
      reqireCallGetTarget,
      sourcePhrases,
      isProgressComplete,
      areaQuery,
    ],
  )

  // Centralized function to update layout and sync with localStorage
  const updateLayout = (newLayout: any[]) => {
    if (newLayout) {
      if (newLayout[0][0] > 2 && newLayout[1]) {
        const modifiedLayout = [
          newLayout[0],
          false,
          newLayout[2],
          newLayout[3],
          newLayout[4],
        ]

        setLayout(modifiedLayout)
        localStorage.setItem('layoutTypeObject', JSON.stringify(modifiedLayout))
      } else if (newLayout[0][0] <= 2 && !newLayout[1]) {
        const modifiedLayout = [
          newLayout[0],
          true,
          newLayout[2],
          newLayout[3],
          newLayout[4],
        ]
        setLayout(modifiedLayout)
        localStorage.setItem('layoutTypeObject', JSON.stringify(modifiedLayout))
      } else {
        setLayout(newLayout)
        localStorage.setItem('layoutTypeObject', JSON.stringify(newLayout))
      }
    }
  }
  useEffect(() => {
    // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
    if (layout[0][0] > 2 && layout[1]) {
      const newLayout = [layout[0], false, layout[2], layout[3], layout[4]]

      localStorage.setItem('layoutTypeObject', JSON.stringify(newLayout))
    }
    if (layout[0][0] <= 2 && !layout[1]) {
      const newLayout = [layout[0], true, layout[2], layout[3], layout[4]]
      localStorage.setItem('layoutTypeObject', JSON.stringify(newLayout))
    }
  }, [layout])

  // Context value should use the updateLayout for modifications
  const layoutValue = useMemo(
    () => ({
      layout,
      updateLayout, // use this function to update layout
    }),
    [layout, updateLayout],
  )
  // Context value should use for project states
  const userDetailsValue = useMemo(
    () => ({
      userRole,
      setUserRole,
    }),
    [userRole],
  )
  // Context value should use for project states
  const projectDetailsValue = useMemo(
    () => ({
      projectName,
      updateProjectName,
      projectId,
      setProjectId,
    }),
    [projectName, projectId],
  )
  // Context value should use target id
  const targetDetailsValue = useMemo(
    () => ({
      targetId,
      setTargetId,
    }),
    [targetId],
  )
  // Context value should use status
  const statusValue = useMemo(
    () => ({
      projectStatus,
      setProjectStatus,
      collationStatus,
      setCollationStatus,
      targetDocumentStatus,
      setTargetDocumentStatus,
      sourcePhraseStatus,
      setSourcePhraseStatus,
      targetFinalizeStatus,
      setTargetFinalizeStatus,
    }),
    [
      projectStatus,
      collationStatus,
      targetDocumentStatus,
      sourcePhraseStatus,
      targetFinalizeStatus,
    ],
  )

  return (
    <ThemeProvider theme={theme}>
      <UserContext.Provider value={userDetailsValue}>
        <ProjectContext.Provider value={projectDetailsValue}>
          <TargetContext.Provider value={targetDetailsValue}>
            <highlightsContext.Provider value={highlightsValue}>
              <StatusContext.Provider value={statusValue}>
                <layoutContext.Provider value={layoutValue}>
                  <SnackbarProvider>{children}</SnackbarProvider>
                </layoutContext.Provider>
              </StatusContext.Provider>
            </highlightsContext.Provider>
          </TargetContext.Provider>
        </ProjectContext.Provider>
      </UserContext.Provider>
    </ThemeProvider>
  )
}

export default GlobalContextProvider
