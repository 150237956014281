import React, { useEffect, useState, useRef, Fragment, useContext } from 'react'

// Import your variables.scss to use the variables
import '../../styles/variables.scss'
import {
  Box,
  FormControl,
  Stack,
  Tab,
  TablePagination,
  Tabs,
  TextField,
  Typography,
  InputLabel,
  Badge,
  CircularProgress,
  FormHelperText,
} from '@mui/material'
import '../project-setting/project-setting.scss'
import OutlineTextButton from '../../components/button/textOutlineButton'
import TextButton from '../../components/button/textButton'
import { ReactComponent as MagniferIcon } from '../../assets/images/magnifer.svg'
import TableComponent from '../../components/table/table'
import type { MRT_ColumnDef } from 'material-react-table'
import type { SelectChangeEvent } from '@mui/material/Select'
import ButtonIcon from '../../components/button/iconButton'
import { ReactComponent as AddIcon } from '../../assets/images/add.svg'
import { TargetDocumentTab } from './target-document'
import MembersTabs from './members-tab'
import FormDialog from '../../components/form-dialog/formDialog'
import InputBox from '../../components/input/input'
import { SingleUpload } from '../../components/file-upload/singleUpload'
import ByteConverter from '../../components/file-upload/byteConverter'

import { enqueueSnackbar } from 'notistack'
import ActionDialog from './addMembers'
import CancelConformationFormDialog from '../../components/form-dialog/cancel-conformation'
import CancelDeleteConformationFormDialog from '../../components/form-dialog/cancel-delete-conformation'
import {
  archiveAPI,
  createProjectAPI,
  deleteProject,
} from '../home/home-api-services'
import { useParams, useLocation, useNavigate, Link } from 'react-router-dom'
import {
  editProjectAPI,
  getPreviousSourceFilesAPI,
  getProjectSettingData,
} from './project-setting-api-serive'
import DateFormatter from '../../utils/dateFormatter'
import TruncateText from '../../components/tooltip/truncate'
import moment from 'moment'
import { useTranslation } from 'react-i18next'
import { getLocalizedPath } from '../../utils/shareLocalizePath'
import axios from 'axios'
import { StatusContext } from '../../context/globalContext'

interface Props {}

interface TabPanelProps {
  children?: React.ReactNode
  index: number
  value: number
}

interface RowData {
  id: number
  name: string
  is_previous_version: boolean
  status: string
  matched_results: number
  best_rank: number
  created_by: string
  created_at: string
  last_modified_at: string
  last_modified_by: string
  action: any
  is_previous_file: boolean
}

function CustomTabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box>
          <Typography component="div">{children}</Typography>
        </Box>
      )}
    </div>
  )
}

function a11yProps(index: number) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  }
}

export const ProjectSetting: React.FC<Props> = (props) => {
  // Accessing the `PROJECT_ID` parameter from the URL
  const location = useLocation()
  const controllerRef = useRef<AbortController | null>(null)
  const searchParams = new URLSearchParams(location.search)
  const projectId = searchParams.get('project_id')
  const navigate = useNavigate()
  const [value, setValue] = React.useState(0)
  const [isMounted, setIsMounted] = useState<boolean | any>(false)
  const { projectStatus } = useContext<any>(StatusContext)
  // Project setting details
  const [isDataLoading, setDataLoading] = useState<boolean | any>(true)
  const [projectsSettingData, setProjectsSettingData] = useState<any>([])

  const [searchTerm, setSearchTerm] = useState('')

  const [projectName, setProjectName] = useState<string>('')
  const [editProjectName, setEditedProjectName] = useState<string>('')
  const [exsistingSrcDocName, setExistingSrcDoc] = useState<string>('')
  const [file, setFile] = useState<File | null>(null)
  const [uploadProgress, setUploadProgress] = useState<any>(null)
  const [isProgressComplete, setIsProgressComplete] = useState<boolean>(false)
  const [isFileSizeValid, setFileSizeValidation] = useState<boolean>(true)
  const [isCancelUploadConfirmDialogOpen, setCancelUploadConfirmDialogOpen] =
    useState(false)
  const [cancelResult, setCancelResult] = useState<string>()
  const [isEditProjectOpen, setIsEditProjectOpen] = useState(false)
  const [isAdvanceDialogOpen, setIsAdvanceDialogOpen] = useState(false)

  const [isCancelArchiveConfirmDialogOpen, setCancelArchiveConfirmDialogOpen] =
    useState(false)

  const [isCancelDeleteConfirmDialogOpen, setCancelDeleteConfirmDialogOpen] =
    useState(false)
  const [isPrevFilesLoading, setPrevFilesLoading] = useState<boolean | any>(
    false,
  )
  const [previousSrcFiles, setPreviousSrcFilesData] = useState<any[]>([])
  const [isPerviousSrcVersionDialogOpen, setPreviousSrcVersionsDialog] =
    useState(false)
  const [isDeleteConfirmDialogOpen, setDeleteDialogOpen] = useState(false)
  const [badge, setBadgeValue] = useState(0)
  const [currentProjectName, setCurrentProjectName] = useState<any>('')
  const [statusDisplayName, setStatusDisplayName] = useState('')
  const maxFileSize = 30 * 1024 * 1024
  const prevFilesRows: RowData[] = []
  const { t, i18n } = useTranslation()
  const currentLanguage = i18n.language
  const isUserManagementAvailable =
    process.env.REACT_APP_ENABLE_USER_MANAGEMENT === 'true'
  useEffect(() => {
    setIsMounted(true) // Set isMounted to true when component is mounted
    return () => {
      setIsMounted(false) // Set isMounted to false when component is unmounted
    }
  }, [])

  useEffect(() => {
    if (isMounted) {
      getProjectsSettingData()
      getPreviousSourceFiles()
    }
  }, [isMounted])
  useEffect(() => {}, [searchTerm])

  // To handle cancelation of file upload.
  // useEffect(() => {
  //   if (cancelResult === 'confirm') {
  //     handleFileUploadCancel()
  //   } else {
  //     setCancelUploadConfirmDialogOpen(false)
  //   }
  // }, [cancelResult])

  const disableEditButton =
    !projectName || uploadProgress !== null || !isFileSizeValid

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue)
  }
  // To edit projects
  const handleOpenEditProject = () => {
    setIsEditProjectOpen(true)
    const filename = projectsSettingData[0]?.name.toString() as string
    const sourceDocument =
      projectsSettingData[0]?.source_document.name.toString() as string
    setProjectName(filename)
    setExistingSrcDoc(sourceDocument)
    getPreviousSourceFiles()
  }
  const handleCloseEditProject = () => {
    setProjectName('')
    setExistingSrcDoc('')
    setFile(null)
    setFileSizeValidation(true)
    setIsProgressComplete(false)
    setUploadProgress(null)
    setIsEditProjectOpen(false)
  }
  // To open Advanced
  const handleOpenAdvanceDialog = () => {
    setIsAdvanceDialogOpen(true)
  }
  const handleCloseAdvanceDialog = () => {
    setProjectName('')
    setIsAdvanceDialogOpen(false)
  }
  const handleProjectNameChange = (
    event: React.ChangeEvent<HTMLInputElement>,
  ) => {
    setEditedProjectName(event.target.value.trimStart())
    setProjectName(event.target.value.trimStart())
  }
  const handleFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setFileSizeValidation(true)
    const selectedFile = e.target.files ? e.target.files[0] : null
    if (selectedFile) {
      setFile(selectedFile)
      if (selectedFile && selectedFile.size < maxFileSize) {
        setFileSizeValidation(true)
      } else {
        setFileSizeValidation(false)
      }
    }
  }

  // To set cancel conformation dialog box
  const handleOpenCancelUploadConfirmDialog = () => {
    setCancelUploadConfirmDialogOpen(true)
  }
  const handlCloseCancelUploadConfirmDialog = (result: string) => {
    if (result === 'confirm') {
      setCancelUploadConfirmDialogOpen(false)
      editProject()
    } else {
      setCancelUploadConfirmDialogOpen(false)
    }
  }

  // const handleFileUploadCancel = () => {
  //   if (controllerRef.current) {
  //     controllerRef.current.abort()
  //     setCancelUploadConfirmDialogOpen(false)
  //     enqueueSnackbar(`Cancelled file upload`, {
  //       variant: 'error',
  //       persist: true,
  //     })
  //     setFile(null)
  //     setProjectName('')
  //     setUploadProgress(null)
  //   }
  //   handleCloseEditProject()
  // }

  // To set file and project name through props & close dialog
  const editProject = () => {
    var productFormData = new FormData()
    setIsProgressComplete(false)
    // Set up AbortController
    const controller = new AbortController()
    controllerRef.current = controller

    if (file) {
      if (editProjectName.length > 0) {
        productFormData.append('name', projectName)
      }
      productFormData.append('source_document', file)
      edit(productFormData, controller)
    } else {
      productFormData.append('name', projectName)
      edit(productFormData)
    }
  }

  const edit = (data, controller?) => {
    const onUploadProgress = (progressEvent: any) => {
      const progress = Math.round(
        (progressEvent.loaded * 100) / progressEvent.total,
      )
      setUploadProgress(
        (prevProgress: { filename: string; progress: number }) => ({
          filename: file?.name,
          progress: Math.max(progress, prevProgress?.progress ?? 0),
        }),
      )
    }
    if (file) {
      editProjectAPI(data, projectId, onUploadProgress, controller?.signal)
        .then((res) => {
          if (res.status === 200) {
            setUploadProgress(null)
            setIsProgressComplete(true)
            handleCloseEditProject()
            setFile(null)
            setProjectName('')

            enqueueSnackbar(t(`Project ${projectName} edited successfully`), {
              variant: 'success',
              persist: true,
            })
            getProjectsSettingData()
          }
        })
        .catch((error) => {
          handleCloseEditProject()
          setFile(null)
          setUploadProgress(null)
          setIsProgressComplete(true)
          if (
            error?.response?.request?.status !== 500 &&
            error?.response?.request?.status !== 502
          ) {
            if (error?.response?.data?.detail)
              enqueueSnackbar(`  ${error?.response?.data?.detail}`, {
                variant: 'error',
                persist: true,
              })
          }
          if (error?.response?.request?.status === 502) {
            enqueueSnackbar(`Something went wrong`, {
              variant: 'error',
              persist: true,
            })
          }
        })
    } else {
      editProjectAPI(data, projectId)
        .then((res) => {
          if (res.status === 200) {
            setUploadProgress(null)
            setIsProgressComplete(true)
            handleCloseEditProject()

            enqueueSnackbar(t(`Project ${projectName} edited successfully`), {
              variant: 'success',
              persist: true,
            })
            getProjectsSettingData()
          }
        })
        .catch((error) => {
          setFile(null)
          setUploadProgress(null)
          setIsProgressComplete(true)
          setFile(null)
          if (
            error?.response?.request?.status !== 500 &&
            error?.response?.request?.status !== 502
          ) {
            if (error?.response?.data?.detail)
              enqueueSnackbar(`  ${error?.response?.data?.detail}`, {
                variant: 'error',
                persist: true,
              })
          }
          if (error?.response?.request?.status === 502) {
            enqueueSnackbar(`Something went wrong`, {
              variant: 'error',
              persist: true,
            })
          }
        })
    }
  }

  // Open  archive  dialog
  const handleOpenArchiveConfirmDialog = () => {
    setCancelArchiveConfirmDialogOpen(true)
  }
  const handlCloseArchiveConfirmDialog = (result?: string) => {
    if (result === 'confirm') {
      archiveOrUnarchiveProjects(projectId, true)
      handleCloseAdvanceDialog()
    }
    setCancelArchiveConfirmDialogOpen(false)
  }
  // Open Deleet dialog
  const handleOpenDeleteConfirmDialog = () => {
    setCancelDeleteConfirmDialogOpen(true)
  }
  const handlCloseDeleteConfirmDialog = (result?: string) => {
    if (result === 'confirm') {
      setDeleteDialogOpen(true)
      setCancelDeleteConfirmDialogOpen(false)
    } else {
      setCancelDeleteConfirmDialogOpen(false)
    }
  }
  const handleCloseDeleteConfirm = (result?: string) => {
    if (result === 'archive') {
      archiveOrUnarchiveProjects(projectId, true)
    } else if (result === 'delete') {
      deleteProjectById(projectId)
      handleCloseAdvanceDialog()
    }

    setDeleteDialogOpen(false)
  }
  const gotoHome = () => {
    navigate(`${getLocalizedPath(currentLanguage, `/home`)}`)
  }
  // Fetching Project setting on load
  const getProjectsSettingData = () => {
    setDataLoading(true)
    getProjectSettingData(projectId)
      .then((res) => {
        if (res) {
          setDataLoading(false)
          setProjectsSettingData([res.data])
          setCurrentProjectName(res.data.name)
        }
      })
      .catch((error) => {
        // To handle cancel axios error
        if (axios.isCancel(error)) {
          console.debug('Request canceled:', error)
        } else if (
          error?.response?.data?.detail &&
          error?.response?.request?.status !== 500 &&
          error?.response?.request?.status !== 502
        ) {
          setDataLoading(false)
          enqueueSnackbar(`${error?.response?.data?.deatil}`, {
            variant: 'error',
            persist: true,
          })
        }
        if (error?.response?.request?.status === 502) {
          setDataLoading(false)
          enqueueSnackbar(`Something went wrong`, {
            variant: 'error',
            persist: true,
          })
        }
        gotoHome()
      })
  }

  // To Delete the project

  const deleteProjectById = (id: any) => {
    deleteProject(id)
      .then((res) => {
        enqueueSnackbar(`${res.detail}`, {
          variant: 'success',
          persist: true,
        })
        navigate(
          `${getLocalizedPath(currentLanguage, `/home?filter=active`)}`,
          {
            replace: true,
          },
        )
      })
      .catch((error) => {
        navigate(`${getLocalizedPath(currentLanguage, `/home`)}`)
        // To handle cancel axios error
        if (axios.isCancel(error)) {
          console.debug('Request canceled:', error)
        } else if (
          error?.response?.data?.detail &&
          error?.response?.request?.status !== 500 &&
          error?.response?.request?.status !== 502
        ) {
          enqueueSnackbar(`${error?.response?.data?.detail}`, {
            variant: 'error',
            persist: true,
          })
        }
        if (error?.response?.request?.status === 502) {
          enqueueSnackbar(`Something went wrong`, {
            variant: 'error',
            persist: true,
          })
        }
      })
  }

  const archiveOrUnarchiveProjects = (id: any, val: boolean) => {
    const params = {
      archive: val,
    }
    archiveAPI(id, params)
      .then((res) => {
        enqueueSnackbar(`${res.detail}`, {
          variant: 'success',
          persist: true,
        })

        navigate(
          `${getLocalizedPath(currentLanguage, `/home?filter=archived`)}`,
          {
            replace: true,
          },
        )
      })
      .catch((error) => {
        // To handle cancel axios error
        if (axios.isCancel(error)) {
          console.debug('Request canceled:', error)
        } else if (
          error?.response?.data?.detail &&
          error?.response?.request?.status !== 500 &&
          error?.response?.request?.status !== 502
        ) {
          enqueueSnackbar(`  ${error?.response?.data?.detail}`, {
            variant: 'error',
            persist: true,
          })
        }
        if (error?.response?.request?.status === 502) {
          enqueueSnackbar(`Something went wrong`, {
            variant: 'error',
            persist: true,
          })
        }
      })
  }

  // Open previous Version dialog
  const handleOpenPreviousVersionsDialog = () => {
    setPreviousSrcVersionsDialog(true)
  }
  const handlClosePreviousVersionsDialog = (result?: string) => {
    setPreviousSrcVersionsDialog(false)
  }
  const getPreviousSourceFiles = () => {
    setPrevFilesLoading(true)
    getPreviousSourceFilesAPI(projectId)
      .then((res) => {
        if (res) {
          setPrevFilesLoading(false)
          setPreviousSrcFilesData([...res.data])
        }
      })
      .catch((error) => {
        if (
          error?.response?.request?.status !== 500 &&
          error?.response?.request?.status !== 502
        ) {
          enqueueSnackbar(t(`Something went wrong`), {
            variant: 'error',
            persist: true,
          })
        }
        if (error?.response?.request?.status === 502) {
          enqueueSnackbar(`Something went wrong`, {
            variant: 'error',
            persist: true,
          })
        }
      })
  }

  const columnsPreviousFiles: Array<MRT_ColumnDef<RowData>> = [
    {
      accessorKey: 'name',
      header: 'FILE NAME',
      maxSize: 120,
      enableSorting: false,
      accessorFn: (row) => (
        <div>
          <div className="blue-header-font ">
            {row.name?.length > 55 ? (
              <TruncateText text={row?.name} maxLength={55} />
            ) : (
              row?.name
            )}
          </div>
        </div>
      ),
    },
    {
      accessorKey: 'created_by',
      header: 'UPLOADED BY',
      size: 120,
      enableSorting: false,
      accessorFn: (row) => (
        <div>
          <div className="grey-header-font">
            {row.created_by.length > 28 ? (
              <TruncateText text={row?.created_by} maxLength={28} />
            ) : (
              row?.created_by
            )}
          </div>
          <div className="grey-subtitle-font">
            <DateFormatter dateString={formatDateToLocalTime(row.created_at)} />
          </div>
        </div>
      ),
    },
  ]
  const formatDateToLocalTime = (utcDateString) => {
    // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
    const date = moment(utcDateString)
    const formattedDate = date.format('YYYY/MM/DD')
    const formattedTime = date.format('HH:mm')
    return `${formattedDate} ${formattedTime}`
  }

  const decide = () => {
    file === null ? editProject() : handleOpenCancelUploadConfirmDialog()
  }
  const dialogArchiveMessage = t('Are you sure, you want to Archive')
  const dialogDeleteMessage = t('Are you sure, you want to Delete')

  // to change status text from slug format to displayName format
  useEffect(() => {
    if (
      projectStatus === 'COLLATION_AREA_SETTING' ||
      projectStatus === 'Collation Area Setting'
    ) {
      setStatusDisplayName(t('Collation Area Setting'))
    } else if (
      projectStatus === 'COLLATION_IN_PROGRESS' ||
      projectStatus === 'PREPARING_FOR_COLLATION' ||
      projectStatus === 'INHERITING_COLLATIONS'
    ) {
      setStatusDisplayName(t('Collation In Progress'))
    } else if (projectStatus === 'COLLATION_VERIFICATION') {
      setStatusDisplayName(t('Collation Verification'))
    } else if (projectStatus === 'WAITING_FOR_TARGET_DOCUMENTS') {
      setStatusDisplayName(t('Waiting for Target Documents'))
    } else if (projectStatus === 'WAITING_FOR_COLLATION_APPROVAL') {
      setStatusDisplayName(t('Waiting for Collation Approval'))
    } else if (projectStatus === 'COMPLETED') {
      setStatusDisplayName(t('Completed'))
    } else if (projectStatus === '') {
      setStatusDisplayName('')
    }
  }, [projectStatus])

  return (
    <div className="global-page-wrapper">
      <Stack
        className="project-details-wrapper"
        style={{ padding: '16px 14px', height: '149px' }}
        direction="column"
        justifyContent="start"
        alignItems="start"
      >
        <div className="project-details-actions">
          <div className="details">
            {!isDataLoading && (
              <Link
                to={getLocalizedPath(
                  currentLanguage,
                  `/projects?project_id=${projectsSettingData[0].id}`,
                )}
              >
                <h1 className="project-setting-name">
                  {projectsSettingData[0].name.length > 30 ? (
                    <TruncateText
                      text={projectsSettingData[0].name}
                      maxLength={30}
                    />
                  ) : (
                    projectsSettingData[0].name
                  )}
                </h1>
              </Link>
            )}

            {projectsSettingData[0]?.status && !isDataLoading && (
              <div className="status">
                {statusDisplayName || projectsSettingData[0]?.status?.name}
              </div>
            )}
          </div>

          <div className="actions">
            <OutlineTextButton
              text={'Advanced'}
              bgColor={'#fff'}
              color={'var(--primary-600)'}
              outlineColor={'var(--primary-600)'}
              // bgColor={
              //   disableInviteButton
              //     ? 'var(--primary-300)' // Use primary-300 for the disable color
              //     : 'var(--primary-600)'
              // }
              // disabled={disableInviteButton}
              onClick={handleOpenAdvanceDialog}
              style={{
                width: '79px',
                height: '34px',
                fontSize: '12px',
                fontWeight: '500',
                lineHeight: '18px',
              }}
            />

            <TextButton
              text={t('Edit')}
              color={'#fff'}
              bgColor={'var(--primary-600)'}
              disabled={false}
              onClick={handleOpenEditProject}
              style={{
                height: '34px',
                padding: '8px 12px',
                fontSize: '12px',
                fontWeight: '500',
              }}
            />
          </div>
        </div>

        <div className="files-deatils">
          <span className="header">{t('Source File:')}</span>
          <span className="value">
            {projectsSettingData[0]?.source_document.name}
          </span>
        </div>
        <div className="files-deatils">
          <span className="header">{t('Uploaded by:')} </span>
          <span className="value" style={{ display: 'flex', gap: '5px' }}>
            {projectsSettingData[0]?.source_document.created_by}{' '}
            {projectsSettingData[0]?.source_document.created_by && (
              <DateFormatter
                dateString={formatDateToLocalTime(
                  projectsSettingData[0]?.source_document?.created_at,
                )}
              />
            )}
          </span>
        </div>
      </Stack>

      <Stack className="project-details-wrapper" direction="column">
        <Box sx={{ width: '100%', height: 'auto' }}>
          <Box sx={{ borderBottom: 1, borderColor: 'divider', padding: '0' }}>
            <Tabs
              value={value}
              onChange={handleChange}
              aria-label="basic tabs example"
            >
              <Tab
                {...props}
                {...a11yProps(0)}
                label={
                  <div
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'space-between',
                      width: '160px',
                    }}
                  >
                    <div>{t('Target Documents')}</div>
                    <div>
                      <Badge
                        badgeContent={
                          projectsSettingData[0]?.target_document_count > 0
                            ? projectsSettingData[0]?.target_document_count
                            : '0'
                        }
                        color="primary"
                      ></Badge>
                    </div>
                  </div>
                }
              />

              <Tab
                label="Members"
                {...a11yProps(1)}
                disabled={!isUserManagementAvailable}
              />
            </Tabs>
          </Box>
          <CustomTabPanel value={value} index={0}>
            <TargetDocumentTab projectId={projectId} />
          </CustomTabPanel>
          <CustomTabPanel value={value} index={1}>
            <MembersTabs projectId={projectId} />
          </CustomTabPanel>
        </Box>

        {/* Dialog Start here */}
        <FormDialog
          open={isEditProjectOpen}
          title={t('Edit Project')}
          onClose={() => {
            uploadProgress >= 0
              ? handleCloseEditProject()
              : handleOpenCancelUploadConfirmDialog()
          }}
          disableClose={uploadProgress}
          setProjectName={setProjectName}
          setFile={setFile}
          setUploadProgress={setUploadProgress}
        >
          <Stack display={'flex'} direction={'column'}>
            {/* Dialog ProjectName input field */}
            <FormControl variant="standard">
              <InputLabel shrink htmlFor="name-input">
                {t('Project Name')}
              </InputLabel>
              <InputBox
                id="name-input"
                value={projectName}
                placeholder={t('Enter Project Name')}
                onChange={handleProjectNameChange}
                disabled={uploadProgress && !isProgressComplete}
                error={projectName?.length > 255}
              />
              {projectName?.length > 255 && ( // Display error message if not valid
                <FormHelperText error>
                  {t('Project name must be 255 characters or less.')}
                </FormHelperText>
              )}
            </FormControl>
            {previousSrcFiles?.length === 0 && <br />}

            <Stack display={'flex'} direction={'column'}>
              {previousSrcFiles?.length > 0 && (
                <div
                  onClick={handleOpenPreviousVersionsDialog}
                  style={{
                    fontSize: '13px',
                    fontWeight: '500',
                    display: 'flex',
                    flexDirection: 'row-reverse',
                    position: 'relative',
                    top: '17px',
                    color: 'var(--primary-600)',
                    zIndex: '99',
                  }}
                >
                  <span style={{ cursor: 'pointer' }}>
                    {t('View Previous Files')}
                  </span>
                </div>
              )}

              <FormControl variant="standard">
                <SingleUpload
                  exsistingSrcDocName={exsistingSrcDocName}
                  file={file}
                  setFile={setFile}
                  handleFileChange={handleFileChange}
                  uploadProgress={uploadProgress}
                  isProgressComplete={isProgressComplete}
                  buttonName={t('Replace')}
                  documentTitle={t('Source Document')}
                  color={'var(--gray-white)'}
                  bgColor={
                    uploadProgress && !isProgressComplete
                      ? 'var(--primary-300)'
                      : 'var(--primary-600)'
                  }
                  style={{
                    borderTopLeftRadius: 0,
                    borderBottomLeftRadius: 0,
                    borderTopRightRadius: '8px',
                    borderBottomRightRadius: '8px',
                    marginTop: '25px',
                    paddingTop: '9px',
                    paddingBottom: '8px',
                    minWidth: 'fit-content',
                  }}
                />

                <div className="file-size-error">
                  {!isFileSizeValid && <>{t('File exceeds maximum limit')}</>}
                </div>
                <div className="file-size-error">
                  {file?.size === 0 && (
                    <>{t('File is empty, please choose appropriate file')}</>
                  )}
                </div>
                <div className="selected-file-size">
                  {file && uploadProgress >= 0 && (
                    <ByteConverter bytes={file?.size} decimal={2} />
                  )}
                </div>
              </FormControl>
            </Stack>
          </Stack>

          <div
            style={{
              display: 'flex',
              justifyContent: 'flex-end',
              marginTop: '16px',
            }}
          >
            {/* Dialog Craete Button */}

            <TextButton
              text={t(`Save`)}
              color={'#fff'}
              bgColor={
                disableEditButton ||
                projectName.length > 255 ||
                file?.size === 0
                  ? 'var(--primary-300)' // Use primary-300 for the disable color
                  : 'var(--primary-600)'
              }
              disabled={
                disableEditButton ||
                projectName.length > 255 ||
                file?.size === 0
              }
              onClick={decide}
              style={{
                width: '100%',
                fontSize: '14px',
                textTransform: 'capitalize',
                boxShadow: 'none',
                padding: '8px 0',
              }}
            />

            {/* <Button
            variant="contained"
            fullWidth
            disabled={disableCreateButton} // Disable button based on state
            onClick={createProject}
            style={{
              backgroundColor: disableCreateButton
                ? 'var(--primary-300)' // Use primary-300 for the disable color
                : 'var(--primary-600)', // Use primary-600 for the button color
              color: 'var(--gray-white)',
              fontSize: '14px',
              textTransform: 'capitalize',
              boxShadow: 'none',
              padding: '8px 0',
            }}
          >
            Create
          </Button> */}
          </div>
        </FormDialog>

        <ActionDialog
          open={isAdvanceDialogOpen}
          title={t('Advanced')}
          onClose={() => {
            handleCloseAdvanceDialog()
          }}
        >
          <div className="archived-dialog-wrapper">
            <div className="content">
              <div className="type-of-project">{t('Archive Project')}</div>
              <div className="message">
                {t('The Project will be archived and can be restored')}
              </div>
            </div>
            <div className="actions-button ">
              <TextButton
                text={t('Archive')}
                color={'#fff'}
                bgColor={'var(--primary-600)'}
                disabled={false}
                onClick={() => {
                  handleOpenArchiveConfirmDialog()
                }}
                style={{
                  width: '83px',
                  height: '39px',
                  fontSize: '14px',
                  fontWeight: '500',
                  lineHeight: '21px',
                }}
              />
            </div>
          </div>

          <div className="archived-dialog-wrapper">
            <div className="content">
              <div className="type-of-project">{t('Delete Project')}</div>
              <div className="message">
                {t('The Project will be Deleted Permanetly')}
              </div>
            </div>
            <div className="actions-button ">
              <TextButton
                text={t('Delete')}
                color={'#fff'}
                bgColor={'var(--primary-600)'}
                disabled={false}
                onClick={() => {
                  handleOpenDeleteConfirmDialog()
                }}
                style={{
                  width: '83px',
                  height: '39px',
                  fontSize: '14px',
                  fontWeight: '500',
                  lineHeight: '21px',
                }}
              />
            </div>
          </div>
        </ActionDialog>

        <ActionDialog
          open={isPerviousSrcVersionDialogOpen}
          maxWidth="sm"
          title={t('Previous Files')}
          onClose={() => {
            handlClosePreviousVersionsDialog()
          }}
        >
          <div
            style={{
              border: '1px solid var(--gray-200)',
              borderRadius: '8px',
              width: '34rem',
            }}
          >
            {isPrevFilesLoading ? (
              <div style={{ padding: '9rem', textAlign: 'center' }}>
                <CircularProgress />
              </div>
            ) : (
              <TableComponent
                rows={previousSrcFiles}
                columns={columnsPreviousFiles}
                isSort={false}
                sorting={[]}
                enableBottomTool={false}
                errorMessage={t('No files found')}
                enableResize={false}
              />
            )}
          </div>
        </ActionDialog>
        {/*  On cancel of upload file */}
        <CancelConformationFormDialog
          open={isCancelUploadConfirmDialogOpen}
          onClose={handlCloseCancelUploadConfirmDialog}
          message={`Are you sure, you want to proceed with the replacement of the file, as this action cannot be cancelled?`}
        ></CancelConformationFormDialog>

        <CancelConformationFormDialog
          open={isCancelArchiveConfirmDialogOpen}
          onClose={handlCloseArchiveConfirmDialog}
          message={`${dialogArchiveMessage} ${currentProjectName}`}
        ></CancelConformationFormDialog>

        <CancelConformationFormDialog
          open={isCancelDeleteConfirmDialogOpen}
          onClose={handlCloseDeleteConfirmDialog}
          message={`${dialogDeleteMessage} ${currentProjectName}`}
        ></CancelConformationFormDialog>

        <CancelDeleteConformationFormDialog
          open={isDeleteConfirmDialogOpen}
          onClose={handleCloseDeleteConfirm}
          message={t(
            'To avoid data loss, consider using the archive project feature instead. Once you Delete a project, it cannot be undone or recovered',
          )}
        ></CancelDeleteConformationFormDialog>
      </Stack>
    </div>
  )
}

export default ProjectSetting
