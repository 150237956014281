// import { type GridRowsProp, type GridColDef } from '@mui/x-data-grid'
import { ReactComponent as DownloadIcon } from '../../assets/images/download_white.svg'
import { ReactComponent as ResultIcon } from '../../assets/images/download.svg'
import { ReactComponent as OpenIcon } from '../../assets/images/clipboard_check.svg'
import { ReactComponent as AddIcon } from '../../assets/images/add.svg'
import { ReactComponent as MagniferIcon } from '../../assets/images/magnifer.svg'
import {
  TextField,
  Select,
  MenuItem,
  FormControl,
  Typography,
  Button,
  InputLabel,
  Stack,
  IconButton,
  InputAdornment,
  FormLabel,
  RadioGroup,
  FormControlLabel,
  Radio,
  Box,
  Icon,
  Checkbox,
  OutlinedInput,
  ListItemText,
  TablePagination,
  CircularProgress,
} from '@mui/material'
import ButtonIcon from '../../components/button/iconButton'
import type { SelectChangeEvent } from '@mui/material/Select'
import { useContext, useEffect, useState } from 'react'
import TextButton from '../../components/button/textButton'
import FormDialog from '../../components/form-dialog/formDialog'
import { Link, useNavigate } from 'react-router-dom'
import InputBox from '../../components/input/input'
import { enqueueSnackbar } from 'notistack'
import TableComponent from '../../components/table/table'
import type { MRT_ColumnDef, MRT_SortingState } from 'material-react-table'

import OutlineTextButton from '../../components/button/textOutlineButton'

import RefreshIcon from '../../assets/images/refresh.svg'
import ActiveRefreshIcon from '../../assets/images/active_refresh.svg'
import CancelConformationFormDialog from '../../components/form-dialog/cancel-conformation'
import { ReactComponent as XIcon } from '../../assets/images/x.svg'
import '../results/results.scss'
import {
  approvedOrUnapproveAPI,
  downloadAPI,
  getResultsAPI,
} from './results-api-service'
import { useTranslation } from 'react-i18next'

import TruncateText from '../../components/tooltip/truncate'
import LightTooltip from '../../components/tooltip/tooltip'
import { StatusContext } from '../../context/globalContext'
import { getProjectSettingData } from '../project-setting/project-setting-api-serive'
import DateFormatter from '../../utils/dateFormatter'
import moment from 'moment'
import { getLocalizedPath } from '../../utils/shareLocalizePath'
import axios from 'axios'
import { logoutAPI, meAPI } from '../auth/signup/auth-services'
import Cookies from 'js-cookie'
import { useAuth } from '../../utils/AuthContext'
import { log } from 'console'

interface Props {}
interface VerifiedBy {
  id: any
  name: string
  email: string
  role: string
  is_active: boolean
}
interface ApprovedBy {
  id: any
  name: string
  email: string
  role: string
  is_active: boolean
}
interface RowData {
  id: number
  area_id: number
  source_phrase_id: number
  collation_area_name: string
  source_phrase_text: any
  target_phrase_text: any
  target_document: string
  target_document_id: number
  verification_status: boolean
  belongs_to_latest_target_document: boolean
  latest_comment: string
  verified_by: VerifiedBy
  verified_at: string
  approved_by: ApprovedBy
  approved_at: string
  collation_id: number
  collation_result_id: number
}

interface ResultItem {
  collation_area_name: string
  source_phrase_text: string
  source_phrase_page: number
  target_phrase_text: string
  target_phrase_page: number
  latest_comment: string
  collation_result_id: number
  approved_by: ApprovedBy
  verified_by: VerifiedBy
}

const rows: RowData[] = []

function ResultComponent(props: Props): JSX.Element {
  const [isMounted, setIsMounted] = useState<boolean | any>(false)
  const navigate = useNavigate()
  const searchParams = new URLSearchParams(location.search)
  const projectId = searchParams.get('project_id')
  const [filter, setFilter] = useState('unapproved')

  // Table states
  const [isLoading, setLoading] = useState<boolean | any>(false)
  const [disableSelect, setDisableSelect] = useState<boolean | any>(false)

  const [approveClickedRowIds, setApprovClickedRowIds] = useState<number[]>([])
  const [unapproveClickedRowIds, setUnapprovClickedRowIds] = useState<number[]>(
    [],
  )
  const [currentProjectName, setCurrentProjectName] = useState<any>('')
  const [status, setStatus] = useState<any>('')

  const [disableDisableDownloadCSV, setDisableDownloadCSV] = useState<
    boolean | any
  >(false)

  const [results, setResults] = useState<ResultItem[]>([])
  const [searchTerm, setNewSearchTerm] = useState('')
  const [completeSearchTerm, setCompleteSearchTerm] = useState('')
  const [sorting, setSorting] = useState<MRT_SortingState>([])
  const [currentSortVal, setCurretSortVal] = useState<string>()

  const [loggedInUserId, setloggedInUserId] = useState<any>(null)
  const [isManualFilter, setIsManualFilter] = useState<boolean>(false)
  const [isDataLoading, setDataLoading] = useState<boolean | any>(true)

  const initialDropdown = [
    'COLLATION AREA NAME',
    'SOURCE PHRASE',
    'TARGET PHRASE',
    'STATUS',
    'TARGET DOCUMENT',

    'VERIFIED BY',
  ]
  // Pagination use state
  const [page, setPage] = useState<number>(0)
  const [rowsPerPage, setRowsPerPage] = useState(() => {
    const savedRowsPerPage = localStorage.getItem(`resultsRowsPerPage`)
    return savedRowsPerPage ? parseInt(savedRowsPerPage, 10) : 10
  })
  const [rowCount, setRowCount] = useState<any>(1)

  const [selectedColumns, setSelectedColumns] = useState<string[]>([])
  const [isInviteMembersOpen, setIsInviteMembersOpen] = useState(false)
  const [isViewOpenDialog, setIsViewDialogOpen] = useState(false)
  const [isDeactivateConfirmOpenDialog, setDeactivateConfirmDialogOpen] =
    useState(false)
  const [columnVisibility, setColumnVisibility] = useState({})
  const [dropdown, setDropdown] = useState(initialDropdown)
  const { projectStatus } = useContext(StatusContext)
  const [statusDisplayName, setStatusDisplayName] = useState('')
  const disableInviteButton = false
  const disableApproveButton = false
  const disableViewButton = false
  const disableViewFormSaveBtn = false

  const [value, setRole] = useState('female')
  const { logoutUser } = useAuth()
  const { t } = useTranslation()
  const { i18n } = useTranslation()
  const currentLanguage = i18n.language
  const basePath = getLocalizedPath(currentLanguage, location.pathname)

  const me = () => {
    meAPI()
      .then((response) => {
        if (projectStatus === 'COMPLETED') {
          setFilter('approved')
        }
        if (response) {
          setloggedInUserId(response?.id)
        }
      })
      .catch((error) => {
        // To handle cancel axios error
        if (axios.isCancel(error)) {
          console.debug('Request canceled:', error)
        } else if (
          error?.response?.data?.detail &&
          error.response.request.status === 403
        ) {
          logout()
          enqueueSnackbar(`${error?.response?.data?.detail}.`, {
            variant: 'error',
            persist: true,
          })
        }
        if (error?.response?.request?.status === 502) {
          enqueueSnackbar(`Something went wrong`, {
            variant: 'error',
            persist: true,
          })
        }
      })
  }
  const logout = () => {
    logoutAPI()
      .then((response) => {
        if (response) {
          Cookies.remove('csrftoken')
          // enqueueSnackbar(`Logout successful.`, {
          //   variant: 'success',
          // })
          logoutUser()
          localStorage.clear()
          goToLoginPage()
        }
      })
      .catch((error) => {
        // To handle cancel axios error
        if (axios.isCancel(error)) {
          console.debug('Request canceled:', error)
        } else if (
          error?.response?.data?.detail &&
          error?.response?.request?.status === 403
        ) {
          enqueueSnackbar(`${error?.response?.data?.detail}.`, {
            variant: 'error',
            persist: true,
          })
        }
        if (error?.response?.request?.status === 502) {
          enqueueSnackbar(`Something went wrong`, {
            variant: 'error',
            persist: true,
          })
        }
      })
  }
  // get current location | decides the layout dropdown visiblity
  const goToLoginPage = () => {
    navigate(`${getLocalizedPath(currentLanguage, '/')}`)
  }
  const getColumns = () => {
    const columnHeaders: string[] = []
    columns.forEach((column) => {
      if (column?.header !== 'ACTIONS') {
        columnHeaders.push(column?.header)
      }
    })
    setSelectedColumns(columnHeaders)
  }

  const getProjectData = () => {
    setDataLoading(true)
    getProjectSettingData(projectId)
      .then((res) => {
        if (res) {
          setDataLoading(false)
          setCurrentProjectName(res.data.name)
          setStatus(res?.data?.status?.name)
        }
      })
      .catch((error) => {
        // To handle cancel axios error
        if (axios.isCancel(error)) {
          console.debug('Request canceled:', error)
        } else if (
          error?.response?.data?.detail &&
          error?.response?.request?.status !== 500 &&
          error?.response?.request?.status !== 502
        ) {
          setDataLoading(false)
          enqueueSnackbar(`${error?.response?.data?.deatil}`, {
            variant: 'error',
            persist: true,
          })
        }
        if (error?.response?.request?.status === 502) {
          setDataLoading(false)
          enqueueSnackbar(`Something went wrong`, {
            variant: 'error',
            persist: true,
          })
        }
      })
  }
  const formatDateToLocalTime = (utcDateString) => {
    // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
    const date = moment(utcDateString)
    const formattedDate = date.format('YYYY/MM/DD')
    const formattedTime = date.format('HH:mm')
    return `${formattedDate} ${formattedTime}`
  }

  useEffect(() => {
    setIsMounted(true)
    return () => {
      setIsMounted(false)
    }
  }, [])

  useEffect(() => {
    if (searchTerm) {
      setNewSearchTerm('')
    }
  }, [filter])

  useEffect(() => {
    if (projectStatus === 'COMPLETED') {
      setFilter('approved')
    }
    // to change status text from slug format to displayName format
    if (
      projectStatus === 'COLLATION_AREA_SETTING' ||
      projectStatus === 'Collation Area Setting'
    ) {
      setStatusDisplayName(t('Collation Area Setting'))
    } else if (
      projectStatus === 'COLLATION_IN_PROGRESS' ||
      projectStatus === 'PREPARING_FOR_COLLATION' ||
      projectStatus === 'INHERITING_COLLATIONS'
    ) {
      setStatusDisplayName(t('Collation In Progress'))
    } else if (projectStatus === 'COLLATION_VERIFICATION') {
      setStatusDisplayName(t('Collation Verification'))
    } else if (projectStatus === 'WAITING_FOR_TARGET_DOCUMENTS') {
      setStatusDisplayName(t('Waiting for Target Documents'))
    } else if (projectStatus === 'WAITING_FOR_COLLATION_APPROVAL') {
      setStatusDisplayName(t('Waiting for Collation Approval'))
    } else if (projectStatus === 'COMPLETED') {
      setStatusDisplayName(t('Completed'))
    } else if (projectStatus === '') {
      setStatusDisplayName('')
    }
  }, [projectStatus])

  useEffect(() => {
    getResults()
  }, [
    currentSortVal,
    filter,
    page,
    rowsPerPage,
    approveClickedRowIds,
    completeSearchTerm,
  ])

  useEffect(() => {
    if (isMounted) {
      me()
      navigate(`${basePath}?project_id=${projectId}&type=${filter}`, {
        replace: true,
      })
      getColumns()
      getProjectData()
    }
  }, [isMounted])

  useEffect(() => {
    getSortValue()
  }, [sorting])

  // Approved or unapproved
  useEffect(() => {
    const result = dropdown.reduce((acc, item) => {
      const key = item.toLowerCase().replace(/\s+/g, '_')
      acc[key] = selectedColumns.includes(item)
      return acc
    }, {})

    setColumnVisibility(result)
  }, [selectedColumns, filter, dropdown, results])

  useEffect(() => {
    const savedRowsPerPage = localStorage.getItem(`resultsRowsPerPage`)
    if (savedRowsPerPage) {
      setRowsPerPage(parseInt(savedRowsPerPage, 10))
    }
  }, [location])

  useEffect(() => {
    localStorage.setItem(`resultsRowsPerPage`, rowsPerPage.toString())
  }, [rowsPerPage])

  const getSortValue = () => {
    if (sorting.length > 0) {
      setCurretSortVal(`${sorting[0]?.desc ? '-' : ''}collation_area_name`)
    } else {
      setCurretSortVal('')
    }
  }

  const goTo = (targetId, areaId, phraseId, collationId, resultId) => {
    navigate(
      `${getLocalizedPath(
        currentLanguage,
        `/projects?project_id=${projectId}&target_id=${targetId}&area_id=${areaId}&phrase_id=${phraseId}&collation_id=${collationId}#phrase-${phraseId};collation-${resultId}`,
      )}`,
    )
  }
  // The columns definition should match the MRT_ColumnDef type
  const columns: Array<MRT_ColumnDef<RowData>> = [
    {
      accessorKey: 'collation_area_name',
      accessorFn: (row) => (
        <div
          style={{
            width: 'inherit',
          }}
        >
          <div className="project-name-grey">
            {row.collation_area_name?.length > 30 ? (
              <TruncateText text={row.collation_area_name} maxLength={30} />
            ) : row.collation_area_name ? (
              row.collation_area_name
            ) : (
              '-'
            )}
          </div>
        </div>
      ),
      header: t('COLLATION AREA NAME'),
      enableSorting: true,
      grow: true,
    },
    {
      accessorKey: 'source_phrase',
      header: t('SOURCE PHRASE'),
      enableSorting: false,
      grow: true,
      accessorFn: (row) => (
        <>
          <TruncateText text={row?.source_phrase_text} maxLength={50} />
        </>
      ),
    },
    {
      accessorKey: 'target_phrase',
      header: t('TARGET PHRASE'),
      enableSorting: false,
      grow: true,
      accessorFn: (row) => (
        <>
          <TruncateText text={row?.target_phrase_text} maxLength={50} />
        </>
      ),
    },
    {
      accessorKey: 'status',
      header: t('STATUS'),
      enableSorting: false,
      grow: true,
      accessorFn: (row) => (
        <div className={row?.verification_status ? 'matching' : 'not-matching'}>
          {row?.verification_status ? 'Matching' : 'Not Matching'}
        </div>
      ),
    },
    {
      accessorKey: 'target_document',
      header: t('TARGET DOCUMENT'),
      enableSorting: false,
      grow: true,
      accessorFn: (row) => <>{row?.target_document}</>,
    },
    {
      accessorKey: 'verified_by',
      header: t('VERIFIED BY'),
      grow: true,
      enableSorting: false,
      accessorFn: (row) => (
        <div>
          {row.verified_by ? (
            <>
              <LightTooltip title={row.verified_by.email}>
                {row.verified_by.name as any}
              </LightTooltip>
              <span className="created-at">
                <DateFormatter
                  dateString={formatDateToLocalTime(row.verified_at)}
                />
              </span>
            </>
          ) : (
            <span>-</span>
          )}
        </div>
      ),
    },

    {
      accessorKey: 'result_action',
      header: t('ACTIONS'),
      enableSorting: false,

      accessorFn: (row) => (
        <Stack display={'flex'} direction={'row'} gap={2}>
          <OutlineTextButton
            text={t('View')}
            bgColor={'#fff'}
            outlineColor={
              row.belongs_to_latest_target_document
                ? 'var(--primary-600)'
                : 'var(--primary-300)'
            }
            disabled={!row.belongs_to_latest_target_document}
            color={'var(--primary-600) !important'}
            // disabled={disableInviteButton}
            // onClick={onInviteClick}
            style={{
              color: !row.belongs_to_latest_target_document
                ? 'var(--primary-300) !important' // Use primary-300 for the disable color
                : 'var(--primary-600) !important',
              height: '34px',
              fontSize: '12px',
            }}
            onClick={() => {
              goTo(
                row.target_document_id,
                row.area_id,
                row.source_phrase_id,
                row.collation_id,
                row.collation_result_id,
              )
            }}
          />

          {filter === 'unapproved' ? (
            <TextButton
              text={t(`Approve`)}
              color={'#fff'}
              bgColor={
                (row?.verified_by && loggedInUserId === row?.verified_by?.id) ||
                approveClickedRowIds.includes(row.collation_result_id) ||
                !row.belongs_to_latest_target_document
                  ? 'var(--primary-300)' // Use primary-300 for the disable color
                  : 'var(--primary-600)'
              }
              disabled={
                loggedInUserId === row?.verified_by?.id ||
                approveClickedRowIds.includes(row.collation_result_id) ||
                !row.belongs_to_latest_target_document
              }
              onClick={() => {
                handleApprove(
                  projectId,
                  row?.source_phrase_id,
                  row?.collation_result_id,
                  true,
                )
              }}
              style={{
                height: '34px',
                fontSize: '12px',
              }}
            />
          ) : (
            <TextButton
              text={t('Unapprove')}
              color={'#fff'}
              bgColor={
                unapproveClickedRowIds.includes(row.collation_result_id) ||
                !row.belongs_to_latest_target_document
                  ? 'var(--primary-300)' // Use primary-300 for the disable color
                  : 'var(--primary-600)'
              }
              disabled={!row.belongs_to_latest_target_document}
              onClick={() => {
                handleUnapprove(
                  projectId,
                  row.source_phrase_id,
                  row.collation_result_id,
                  false,
                )
              }}
              style={{
                height: '34px',
                fontSize: '12px',
              }}
            />
          )}
        </Stack>
      ),
    },
    ...(filter === 'approved'
      ? [
          {
            accessorKey: 'approved_by',
            header: t('APPROVED BY'),
            grow: true,
            enableSorting: false,
            accessorFn: (row) => (
              <div>
                {row.approved_by ? (
                  <>
                    <LightTooltip title={row.approved_by.email}>
                      {row.approved_by.name}
                    </LightTooltip>
                    <span className="created-at">
                      <DateFormatter
                        dateString={formatDateToLocalTime(row.approved_at)}
                      />
                    </span>
                  </>
                ) : (
                  <span>-</span>
                )}
              </div>
            ),
          },
        ]
      : []),
  ]

  //  Handle Search
  const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setNewSearchTerm(event.target.value)
  }

  const handleSearch = () => {
    setPage(0)
    setCompleteSearchTerm(searchTerm)
  }

  const handleKeyPress = (event) => {
    if (event.key === 'Enter') {
      handleSearch()
    }
  }
  // Handle filter
  const handleFilterChange = (event: SelectChangeEvent<string>) => {
    setIsManualFilter(true)
    const newFilter = event.target.value
    setFilter(newFilter)
    navigate(`${basePath}?project_id=${projectId}&type=${newFilter}`, {
      replace: true,
    })

    // Select the "APPROVED BY" column if the filter is set to 'approved'
    if (newFilter === 'approved' && !selectedColumns.includes('APPROVED BY')) {
      dropdown.push('APPROVED BY')
      setDropdown(dropdown)
      setSelectedColumns([...selectedColumns, 'APPROVED BY'])
    } else if (newFilter === 'unapproved') {
      // REmove the "APPROVED BY" column if the filter is set to 'unapproved'
      const updatedDropDown = dropdown.filter((d) => d !== 'APPROVED BY')
      setDropdown(updatedDropDown)
      if (selectedColumns.includes('APPROVED BY')) {
        const updatedColumns = selectedColumns.filter(
          (column) => column !== 'APPROVED BY',
        )
        setSelectedColumns(updatedColumns)
      }
    }
  }
  // Assuming your transformation function looks like this:
  const transformData = (data: any): ResultItem[] => {
    return data.map((item: any) => ({
      collation_area_name: item.area_name,
      area_id: item.area_id,
      verification_status: item.verification_status,
      belongs_to_latest_target_document: item.belongs_to_latest_target_document,
      collation_id: item.collation_id,
      collation_result_id: item.id,
      source_phrase_id: item.source_phrase_id,
      target_document: item.target_document,
      target_document_id: item.target_document_id,
      source_phrase_text: item.source_phrase.text,
      source_phrase_page: item.source_phrase.page,
      target_phrase_text: item.target_phrase.text,
      target_phrase_page: item.target_phrase.page,
      latest_comment: item.latest_comment.text,
      verified_by: item.verified_by,
      verified_at: item.verified_at,
      approved_by: item.approved_by,
      approved_at: item.approved_at,
    }))
  }
  const getResults = () => {
    const params: any = {
      page_size: rowsPerPage,
      page: page + 1,
      type: filter,
    }

    if (completeSearchTerm) {
      params.search = completeSearchTerm
    }

    if (currentSortVal) {
      params.sort = currentSortVal
    }
    setLoading(true)
    getResultsAPI(Number(projectId), params)
      .then((res) => {
        setLoading(false)
        if (res) {
          if (!currentSortVal?.includes('-')) {
            res.data.reverse()
          }
          setResults(transformData(res.data))
          setRowCount(res.total_count)
          if (
            filter === 'unapproved' &&
            res.total_count === 0 &&
            !isManualFilter
          ) {
            setDropdown((prevDropdown) => {
              if (!prevDropdown.includes('APPROVED BY')) {
                return [...prevDropdown, 'APPROVED BY']
              }
              return prevDropdown
            })
            setSelectedColumns((preVal) => [...preVal, 'APPROVED BY'])
            setFilter('approved')
          }
        }
      })
      .catch((error) => {
        // To handle cancel axios error
        if (axios.isCancel(error)) {
          console.debug('Request canceled:', error)
        } else if (
          error?.response?.request?.status !== 500 &&
          error?.response?.request?.status !== 502
        ) {
          setLoading(false)
          enqueueSnackbar(`${error?.response?.data?.detail}`, {
            variant: 'error',
            persist: true,
          })
        }
        if (error?.response?.request?.status === 404) {
          setLoading(false)
          navigate(`${getLocalizedPath(currentLanguage, '/home')}`)
        }
        if (error?.response?.request?.status === 502) {
          setLoading(false)
          enqueueSnackbar(`Something went wrong`, {
            variant: 'error',
            persist: true,
          })
        }
      })
  }

  const downloadCSV = () => {
    setDisableDownloadCSV(true)
    downloadAPI(projectId)
      .then((res) => {
        if (res.status === 200) {
          setDisableDownloadCSV(false)
          const blob = new Blob([res.data], { type: 'text/csv' })
          const contentDispositionHeader: string | undefined =
            res.headers?.['content-disposition']
          let filename = 'collation_results.csv'
          if (contentDispositionHeader) {
            const index = contentDispositionHeader.indexOf('filename=')
            if (index !== -1) {
              filename = contentDispositionHeader
                .substring(index + 9)
                .replace(/['"]/g, '')
            }
          }
          const a = document.createElement('a')
          a.href = window.URL.createObjectURL(blob)
          a.download = filename
          document.body.appendChild(a)
          a.click()
          document.body.removeChild(a)
        }
      })
      .catch((error) => {
        // To handle cancel axios error
        if (axios.isCancel(error)) {
          console.debug('Request canceled:', error)
        } else if (
          error?.response?.data?.detail &&
          error?.response?.request?.status !== 500 &&
          error?.response?.request?.status !== 502
        ) {
          enqueueSnackbar(`${error?.response?.data?.detail}`, {
            variant: 'error',
            persist: true,
          })
        }
        if (error?.response?.request?.status === 502) {
          enqueueSnackbar(`Something went wrong`, {
            variant: 'error',
            persist: true,
          })
        }
      })
  }

  const handleApprove = (
    projectID: any,
    sourcePhraseId: any,
    collationResultId: any,
    decisionVal: boolean,
  ) => {
    // setApprovClickedRowIds((prevState) => [...prevState, collationResultId])
    approvedOrUnapproveServiceCall(
      projectID,
      sourcePhraseId,
      collationResultId,
      decisionVal,
    )
  }

  const handleUnapprove = (
    projectID: any,
    sourcePhraseId: any,
    collationResultId: any,
    decisionVal: boolean,
  ) => {
    // setUnapprovClickedRowIds((prevState) => [...prevState, collationResultId])
    approvedOrUnapproveServiceCall(
      projectID,
      sourcePhraseId,
      collationResultId,
      decisionVal,
    )
  }

  const approvedOrUnapproveServiceCall = (
    projectID: any,
    sourcePhraseId: any,
    collationResultId: any,
    decisionVal: boolean,
  ) => {
    setLoading(true)
    approvedOrUnapproveAPI(
      projectID,
      sourcePhraseId,
      collationResultId,
      decisionVal,
    )
      .then((res) => {
        getResults()
        // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
        enqueueSnackbar(res.detail, {
          variant: 'success',
          persist: true,
        })
      })
      .catch((error) => {
        setLoading(false)
        // To handle cancel axios error
        if (axios.isCancel(error)) {
          console.debug('Request canceled:', error)
        } else if (
          error?.response?.data?.detail &&
          error?.response?.request?.status !== 500 &&
          error?.response?.request?.status !== 502
        ) {
          enqueueSnackbar(`${error?.response?.data?.detail}`, {
            variant: 'error',
            persist: true,
          })
        }
        if (error?.response?.request?.status === 502) {
          enqueueSnackbar(`Something went wrong`, {
            variant: 'error',
            persist: true,
          })
        }
      })
  }

  // Pagination
  const handleChangePage = (
    event: React.MouseEvent<HTMLButtonElement> | null,
    newPage: number,
  ) => {
    setPage(newPage)
  }

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
  ) => {
    setRowsPerPage(parseInt(event.target.value, 10))
    setPage(0)
  }

  const handleApprovedChange = (
    event: SelectChangeEvent<typeof selectedColumns>,
  ) => {
    const {
      target: { value },
    } = event

    const selected = value
    if (selected.length < 2) {
      setDisableSelect(true)
    } else {
      setSelectedColumns(typeof value === 'string' ? value.split(',') : value)
    }

    // columns.push({})
  }

  const handleCloseDeactivateConfirmDialog = () => {
    setDeactivateConfirmDialogOpen(false)
  }

  const refresh = () => {
    getResults()
    getColumns()
    setSelectedColumns([...selectedColumns])
  }

  const capitalize = (str) => {
    return str.replace(/\b\w/g, (char) => char.toUpperCase())
  }
  return (
    // Page-wrapper
    <div className="global-page-wrapper">
      <div className="current-project-name">
        {currentLanguage && !isDataLoading && (
          <Link
            to={getLocalizedPath(
              currentLanguage,
              `/projects?project_id=${projectId}`,
            )}
          >
            <h1>
              {currentProjectName.length > 30 ? (
                <TruncateText text={currentProjectName} maxLength={30} />
              ) : (
                currentProjectName
              )}
            </h1>
          </Link>
        )}
        {status && !isDataLoading && (
          <div className="status">
            {statusDisplayName !== '' ? statusDisplayName : status}
          </div>
        )}
      </div>
      <Stack className="content-wrapper">
        <div className="filters-container">
          {/* Filterbar Start here */}
          <div className="filters-contents">
            {/* Search Bar start here */}
            <div className="search-bar">
              <TextField
                id="search-members"
                placeholder={t('Search Source Phrases')}
                variant="outlined"
                size="small"
                value={searchTerm}
                onChange={handleSearchChange}
                onKeyDown={handleKeyPress} // Call handleSearch on Enter key press
                style={{
                  flex: 1,
                }}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <MagniferIcon />
                    </InputAdornment>
                  ),
                  endAdornment: (
                    <InputAdornment position="end">
                      {searchTerm.length > 0 && (
                        <XIcon
                          className="global-close-btn"
                          onClick={() => {
                            setCompleteSearchTerm('')
                            setNewSearchTerm('')
                          }}
                        />
                      )}
                    </InputAdornment>
                  ),
                  style: {
                    borderTopLeftRadius: '8px',
                    borderBottomLeftRadius: '8px',
                    borderTopRightRadius: '0px',
                    borderBottomRightRadius: '0px',
                  },
                }}
              />
              <TextButton
                text={t('Search')}
                color={'var(--gray-white)'}
                bgColor={
                  searchTerm ? 'var(--primary-600)' : 'var(--primary-300)'
                }
                disabled={!searchTerm}
                onClick={handleSearch} // Call handleSearch on button click
                style={{
                  borderTopLeftRadius: 0,
                  borderBottomLeftRadius: 0,
                  // To create the effect of the button being attached to the search bar
                }}
              />
            </div>
            {/* Search Bar End here */}

            {/* Filter start here */}
            <FormControl size="small" style={{ minWidth: 150 }}>
              <Select
                labelId="demo-multiple-checkbox-label"
                id="demo-multiple-checkbox"
                multiple
                label={t('Columns')}
                value={selectedColumns}
                onChange={handleApprovedChange}
                input={<OutlinedInput />}
                renderValue={() => t('Columns')}
                style={{
                  borderRadius: '8px',
                  fontSize: '14px',
                  padding: '1.5px 0',
                }}
              >
                {dropdown.map((name) => (
                  <MenuItem
                    key={name}
                    value={name}
                    disabled={
                      name === 'COLLATION AREA NAME' ||
                      name === 'SOURCE PHRASE' ||
                      name === 'TARGET PHRASE' ||
                      name === 'STATUS'
                    }
                  >
                    <Checkbox checked={selectedColumns?.includes(name)} />
                    <ListItemText
                      style={{ fontFamily: 'IBM Plex Sans' }}
                      primary={capitalize(name.toLowerCase())}
                    />
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            {/* Filter start here */}

            {/* Filter start here */}
            <FormControl size="small" style={{ minWidth: 150 }}>
              <Select
                id="filter-select"
                value={filter}
                onChange={handleFilterChange}
                style={{
                  borderRadius: '8px',
                  fontSize: '14px',
                  padding: '1.5px 0',
                }}
              >
                <MenuItem style={{ fontSize: '14px' }} value="approved">
                  {t('Approved')}
                </MenuItem>
                <MenuItem style={{ fontSize: '14px' }} value="unapproved">
                  {t('Unapproved')}
                </MenuItem>
              </Select>
            </FormControl>
            {/* Filter start here */}
          </div>

          <div className="refresh-action-container">
            <span className="icon-wrapper">
              <img
                src={RefreshIcon}
                className="default-icon"
                alt="Edit"
                onClick={refresh}
              />
              <img
                src={ActiveRefreshIcon}
                className="active-icon"
                alt="Edit"
                onClick={refresh}
              />
            </span>

            {/* Invite button */}
            <ButtonIcon
              text={t('Download')}
              color={'var(--gray-white)'}
              disable={disableDisableDownloadCSV}
              IconComponent={DownloadIcon}
              bgColor={
                disableDisableDownloadCSV
                  ? 'var(--primary-300)'
                  : 'var(--primary-600)'
              }
              onClick={downloadCSV}
            />
          </div>
        </div>

        {/* Filterbar End here */}
        {isLoading ? (
          <div style={{ padding: '15rem' }}>
            <CircularProgress />
          </div>
        ) : (
          <TableComponent
            rows={results}
            columns={columns}
            columnVisibility={columnVisibility}
            setColumnVisibility={setColumnVisibility}
            enableColumnPinning={true}
            errorMessage={t('No Results found')}
            sorting={sorting}
            setSorting={setSorting}
          />
        )}
        {results.length > 0 && !isLoading && (
          <TablePagination
            component="div"
            page={page}
            count={rowCount}
            rowsPerPage={rowsPerPage}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
            rowsPerPageOptions={[10, 20, 30]}
            labelRowsPerPage={t('Items per page:')}
          />
        )}

        <CancelConformationFormDialog
          open={isDeactivateConfirmOpenDialog}
          onClose={handleCloseDeactivateConfirmDialog}
          message={t(
            'Are you sure, you want to deactivate Person A (persona@gmail.com)?',
          )}
        ></CancelConformationFormDialog>
      </Stack>
    </div>
  )
}

export default ResultComponent
