/* eslint-disable @typescript-eslint/no-unsafe-argument */
// import { type GridRowsProp, type GridColDef } from '@mui/x-data-grid'
import { ReactComponent as DownloadIcon } from '../../assets/images/download_minimalistic.svg'
import { ReactComponent as ResultIcon } from '../../assets/images/download.svg'
import { ReactComponent as OpenIcon } from '../../assets/images/clipboard_check.svg'
import { ReactComponent as AddIcon } from '../../assets/images/add.svg'
import { ReactComponent as MagniferIcon } from '../../assets/images/magnifer.svg'
import { ReactComponent as ActiveDeleteIcon } from '../../assets/images/active_delete.svg'
import { ReactComponent as CheckedIcon } from '../../assets/images/filled_checkbox.svg'
import { ReactComponent as DefaultCheckIcon } from '../../assets/images/checkbox.svg'
import { ReactComponent as XIcon } from '../../assets/images/x.svg'
import { ReactComponent as RestartIcon } from '../../assets/images/restart.svg'

import {
  TextField,
  Select,
  MenuItem,
  FormControl,
  Typography,
  Button,
  InputLabel,
  Stack,
  IconButton,
  InputAdornment,
  FormLabel,
  RadioGroup,
  FormControlLabel,
  Radio,
  Box,
  Icon,
  Divider,
  List,
  ListItem,
  ListItemText,
  Badge,
  Checkbox,
  TablePagination,
  CircularProgress,
} from '@mui/material'
import ButtonIcon from '../../components/button/iconButton'
import type { SelectChangeEvent } from '@mui/material/Select'
import { useEffect, useMemo, useState } from 'react'
import TextButton from '../../components/button/textButton'
import FormDialog from '../../components/form-dialog/formDialog'

import InputBox from '../../components/input/input'
import { enqueueSnackbar } from 'notistack'
import TableComponent from '../../components/table/table'
import type { MRT_ColumnDef, MRT_SortingState } from 'material-react-table'

import OutlineTextButton from '../../components/button/textOutlineButton'
import '../user-management/userManagement.scss'
import RefreshIcon from '../../assets/images/refresh.svg'
import ActiveRefreshIcon from '../../assets/images/active_refresh.svg'
import { ReactComponent as CheckRadioIcon } from '../../assets/images/radio.svg'
import CancelConformationFormDialog from '../../components/form-dialog/cancel-conformation'
import { useTranslation } from 'react-i18next'
import axios from 'axios'
import {
  assignProjectsToUser,
  getAvailableSlots,
  getProjectsByUserId,
  getUsers,
  inviteUser,
  reInviteUser,
  removeProjectsToUser,
  updateUserRoleOrStatus,
} from '../../services/user-management-service'
import DateFormatter from '../../utils/dateFormatter'
import moment from 'moment'
import { useNavigate, useParams } from 'react-router-dom'
interface Props {}

interface RowData {
  id: any
  name: string
  email: string
  role: any
  number_of_projects: number
  last_login_at: string
  status: string
  invited_by: string
  deactivated_by: string
  invited_at: string
  deactivated_at: string
}
interface unAssignedProjectsType {
  id: string
  name: string
  // Add other properties as needed
}

function UserManagement(props: Props): JSX.Element {
  const [searchTerm, setSearchTerm] = useState('')
  const [completeSearchTerm, setCompleteSearchTerm] = useState('')
  const [projectSearchTerm, setProjectSearchTerm] = useState('')
  const [filter, setFilter] = useState('active')
  const [isInviteMembersOpen, setIsInviteMembersOpen] = useState(false)
  const [isViewDialogState, setViewDialogState] = useState({
    open: false,
    type: null,
  })
  const [isProjectsOpenDialog, setIsProjectsDialogOpen] = useState(false)
  const [isAssignProjectsOpenDialog, setIsAssignProjectsDialogOpen] =
    useState(false)
  const [dialogState, setDialogState] = useState<any>({
    isOpen: false,
    type: null, // 'delete' or 'update'
  })

  const [projectName, setProjectName] = useState('')
  const [file, setFile] = useState<File | null>(null)
  const [uploadProgress, setUploadProgress] = useState<any>(null)

  const [disableInviteButton, setDisableInviteButton] = useState<boolean>(false)
  const [disableProjectsButton, setDisableProjectsButton] =
    useState<boolean>(false)
  const [disableActivateButton, setDisableActivateButton] =
    useState<boolean>(false)

  const [disableViewFormSaveBtn, setDisableViewFormSaveBtn] =
    useState<boolean>(false)

  const [disableAssignBtn, setDisableAssignBtn] = useState<boolean>(false)
  const [totalSlot, setTotalSlot] = useState<any>(0)
  const [remainingSlot, setRemainingSlot] = useState<any>(0)
  const [users, setUsers] = useState<any[]>([])
  const [page, setPage] = useState<number>(0)
  const [rowsPerPage, setRowsPerPage] = useState<number>(10)
  const [rowCount, setRowCount] = useState<number>(0)
  // const [sorting, setSorting] = useState<MRT_SortingState>([
  //   {
  //     id: 'last_login',
  //     desc: true,
  //   },
  // ])
  const [projects, setProjects] = useState<unAssignedProjectsType[]>([])
  const [unAssignedProjects, setUnAssignedProjects] = useState<
    unAssignedProjectsType[]
  >([])
  const [isUnAssignedProjectsLoading, setIsUnAssignedProjectsLoading] =
    useState<boolean>(false)
  const [isAssignedProjectsLoading, setIsAssignedProjectsLoading] =
    useState<boolean>(false)
  const [selectedProjects, setSelectedProjects] = useState<any[]>([])
  const [isDataLoaded, setIsDataLoaded] = useState<boolean>(false)
  const [isMounted, setIsMounted] = useState<boolean>(false)
  const [inviteName, setInviteName] = useState<any>('')
  const [inviteEmail, setInviteEmail] = useState<any>('')
  const [inviteRole, setInviteRole] = useState<any>('')
  const [userName, setUserName] = useState<any>('')
  const [userEmail, setUserEmail] = useState<any>('')
  const [userRole, setUserRole] = useState<any>('')
  const [rowDetails, setRowDetails] = useState<any>(null)
  const [projectsMarkedForDeletion, setProjectsMarkedForDeletion] = useState<
    any[]
  >([])

  const [searchedProjects, setSearchedProjects] = useState<any[]>([])
  // const [columns, setColumns] = useState<any[]>([])
  const [disableReInvite, setDisableReInvite] = useState(false)
  const { filterParam } = useParams() // Get the filter param from the URL
  const navigate = useNavigate() // Initialize navigate
  const { t } = useTranslation()

  // Set the filter based on the URL parameter
  useEffect(() => {
    if (filterParam && filterParam !== filter) {
      if (filterParam !== 'active' && filterParam !== 'deactivated') {
        navigate(`/user-management/active`) // Update the URL parameter
        setFilter('active')
      } else {
        setFilter(filterParam)
      }
    }
  }, [filterParam])

  useEffect(() => {
    selectedProjects.length > 0
      ? setDisableAssignBtn(false)
      : setDisableAssignBtn(true)
  }, [selectedProjects])

  useEffect(() => {
    projectsMarkedForDeletion.length > 0
      ? setDisableViewFormSaveBtn(false)
      : setDisableViewFormSaveBtn(true)
  }, [projectsMarkedForDeletion])

  const handleKeyPress = (event) => {
    if (event.key === 'Enter') {
      handleSearch()
    }
  }
  const handleSearch = () => {
    setCompleteSearchTerm(searchTerm)
  }
  const handleFilterChange = (event: SelectChangeEvent<string>) => {
    setUsers([])
    setFilter(event.target.value)
  }
  const handleOpenInviteMembers = () => {
    handleGetSlots()
    setIsInviteMembersOpen(true)
  }
  const handleCloseInviteMember = () => {
    setIsInviteMembersOpen(false)
  }

  const handleOpenViewDialog = (row, type) => {
    setRowDetails(row)
    setUserEmail(row.email)
    setUserName(row.name)
    setUserRole(row.role.id)
    setViewDialogState({ open: true, type: type })
    if (type === 'deActive') {
      setIsAssignedProjectsLoading(true)
      handleGetProjects(row.id, 'assigned')
    }
  }
  const handleCloseViewDialog = () => {
    setViewDialogState({ open: false, type: null })
    setUserEmail('')
    setUserRole('')
    setUserName('')
  }

  const handleOpenDeactivateConfirmDialog = () => {
    setDialogState({ isOpen: true, type: 'deactivate' })
  }
  const handleCloseDeactivateConfirmDialog = (result: string) => {
    if (result === 'confirm') {
      if (dialogState?.type === 'delete') {
        setIsProjectsDialogOpen(false)
        setProjects([])
        setUnAssignedProjects([])
        setSelectedProjects([])
        setProjectsMarkedForDeletion([])
      } else {
        handleUpdateUser({ status: 'deactivated' }, rowDetails?.id)
        handleCloseViewDialog()
      }
    }
    setDialogState({ isOpen: false, type: null })
  }

  const onProjectsClick = (id) => {
    setIsAssignedProjectsLoading(true)
    handleGetProjects(id, 'assigned')
    setIsProjectsDialogOpen(true)
  }
  const handleCloseProjectDialog = () => {
    if (projectsMarkedForDeletion.length > 0) {
      setDialogState({ isOpen: true, type: 'delete' })
    } else {
      setIsProjectsDialogOpen(false)
      setProjects([])
      setUnAssignedProjects([])
      setSelectedProjects([])
      setProjectsMarkedForDeletion([])
    }
  }
  const onAssignProjectsClick = (id) => {
    setIsUnAssignedProjectsLoading(true)
    handleGetProjects(id, 'not-assigned')
    setIsAssignProjectsDialogOpen(true)
    setProjectsMarkedForDeletion([])
  }
  const handleCloseAssignProjectDialog = () => {
    setIsAssignProjectsDialogOpen(false)
    setProjectSearchTerm('')
    setUnAssignedProjects([])
    setSearchedProjects([])
    setSelectedProjects([])
  }
  const handleRoleRadioChange = (
    event: React.ChangeEvent<HTMLInputElement>,
  ) => {
    setInviteRole((event.target as HTMLInputElement).value)
  }
  const handleUserRoleRadioChange = (
    event: React.ChangeEvent<HTMLInputElement>,
  ) => {
    setUserRole((event.target as HTMLInputElement).value)
  }

  const formatDateToLocalTime = (utcDateString) => {
    // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
    const date = moment(utcDateString)
    const formattedDate = date.format('YYYY/MM/DD')
    const formattedTime = date.format('HH:mm')
    return `${formattedDate} ${formattedTime}`
  }

  const columns: Array<MRT_ColumnDef<RowData>> = [
    {
      accessorKey: 'name',
      accessorFn: (row) => {
        return (
          <div>
            <div className="person-name">{row?.name}</div>
            <span className="person-email">{row?.email}</span>
          </div>
        )
      }, // Use an accessor function to return the formatted string
      id: 'Name', // Unique ID for the column
      header: 'NAME',
      maxSize: 120,
    },
    {
      accessorKey: 'role',
      header: 'ROLE',
      maxSize: 120,
      // grow: true,
      accessorFn: (row) => <div>{row?.role?.name}</div>,
    },
    {
      accessorKey: 'number_of_projects',
      header: '# OF PROJECT',
      size: 120,
      accessorFn: (row) => <div>{row?.number_of_projects}</div>,
    },
    {
      accessorKey: 'last_login_at',
      accessorFn: (row) => {
        const currentTime = moment()
        const invitedTime = moment(row.invited_at)
        const timeDiffInSeconds = currentTime.diff(invitedTime, 'seconds')

        return (
          <>
            <div>
              {row?.last_login_at ? (
                formatDateToLocalTime(row?.last_login_at)
              ) : (
                <div style={{ display: 'flex' }}>
                  <span>Invited</span>
                  <img
                    style={{
                      marginLeft: '5px',
                      cursor:
                        timeDiffInSeconds > 30 ? 'pointer' : 'not-allowed',
                      opacity: timeDiffInSeconds > 30 ? 1 : 0.8,
                    }}
                    width={'20px'}
                    src={ActiveRefreshIcon}
                    onClick={() => {
                      if (timeDiffInSeconds > 30) {
                        handleReInviteUser(row.id)
                      }
                    }}
                    title={
                      timeDiffInSeconds > 30
                        ? 'Click to re-invite'
                        : 'Wait for 30 seconds before retrying'
                    }
                  />
                </div>
              )}
            </div>
          </>
        )
      },
      header: 'LAST LOGIN',
      size: 120,
    },
    {
      accessorKey: filter === 'active' ? 'invited_by' : 'deactivated_by',
      header: filter === 'active' ? 'INVITED BY' : 'DEACTIVATED BY',
      accessorFn: (row) => (
        <div>
          {filter === 'active'
            ? row?.invited_by ?? '-'
            : row?.deactivated_by ?? '-'}
        </div>
      ),
    },
    {
      accessorKey: 'invited_at',
      header: filter === 'active' ? 'INVITED AT' : 'DEACTIVATED AT',
      accessorFn: (row) => {
        return (
          <div>
            {row[filter === 'active' ? 'invited_at' : 'deactivated_at'] ? (
              <DateFormatter
                dateString={formatDateToLocalTime(
                  row[filter === 'active' ? 'invited_at' : 'deactivated_at'],
                )}
              />
            ) : (
              '-'
            )}
          </div>
        )
      },
    },
    {
      accessorKey: 'actions',
      header: 'ACTIONS',
      maxSize: 130,
      accessorFn: (row) => (
        <Stack display={'flex'} direction={'row'} gap={2}>
          <OutlineTextButton
            text={'View'}
            bgColor={'#fff'}
            color={'var(--primary-600)'}
            outlineColor={'var(--primary-600)'}
            style={{
              height: '34px',
              fontSize: '12px',
            }}
            onClick={() => {
              filter === 'active'
                ? handleOpenViewDialog(row, 'active')
                : handleOpenViewDialog(row, 'deActive')
            }}
          />
          {filter === 'active' ? (
            <TextButton
              text={'Projects'}
              color={'#fff'}
              bgColor={
                disableProjectsButton
                  ? 'var(--primary-300)' // Use primary-300 for the disable color
                  : 'var(--primary-600)'
              }
              disabled={disableProjectsButton}
              onClick={() => {
                onProjectsClick(row?.id)
                setRowDetails(row)
              }}
              style={{
                height: '34px',
                fontSize: '12px',
              }}
            />
          ) : (
            <TextButton
              text={'Activate'}
              color={'#fff'}
              bgColor={
                disableActivateButton
                  ? 'var(--primary-300)' // Use primary-300 for the disable color
                  : 'var(--primary-600)'
              }
              disabled={disableActivateButton}
              onClick={() => {
                handleActiveUser(row?.id, 'active')
              }}
              style={{
                height: '34px',
                fontSize: '12px',
              }}
            />
          )}
        </Stack>
      ),
    },
  ]
  const handleCheckboxChange = (project: any) => {
    if (selectedProjects.includes(project)) {
      setSelectedProjects(selectedProjects.filter((p) => p !== project))
    } else {
      setSelectedProjects([...selectedProjects, project])
    }
  }

  useEffect(() => {
    setIsMounted(true) // Set isMounted to true when component is mounted

    return () => {
      setIsMounted(false) // Set isMfilter === 'active' ? 'ounted to false when component is unmounted
    }
  }, [])

  useEffect(() => {
    if (isMounted) {
      getUserList()
    }
  }, [isMounted, page, rowsPerPage, completeSearchTerm, filter])

  // Pagination
  const handleChangePage = (
    event: React.MouseEvent<HTMLButtonElement> | null,
    newPage: number,
  ) => {
    setPage(newPage)
  }

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
  ) => {
    setRowsPerPage(parseInt(event.target.value, 10))
    setPage(0)
  }

  const getUserList = () => {
    setIsDataLoaded(false)
    const params: any = {
      page_size: rowsPerPage,
      page: page + 1,
    }
    if (filter) {
      params.status = filter
    }
    if (completeSearchTerm) {
      params.search = completeSearchTerm
    }
    // if (sort) {
    //   params.sort = currentSortVal
    // }
    getUsers(params)
      .then((res: any) => {
        if (res.status === 200) {
          setIsDataLoaded(true)
          setUsers([...res?.data?.data])
          // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
          setRowCount(res?.data?.total_count)
        }
      })
      .catch((error) => {
        setIsDataLoaded(true)
        // To handle cancel axios error
        if (axios.isCancel(error)) {
          console.debug('Request canceled:', error)
        } else if (
          error?.response?.data?.detail &&
          error?.response?.request?.status !== 500 &&
          error?.response?.request?.status !== 502
        ) {
          enqueueSnackbar(`${error?.response?.data?.detail}`, {
            variant: 'error',
            persist: true,
          })
        }
        if (error?.response?.request?.status === 502) {
          enqueueSnackbar(`Something went wrong`, {
            variant: 'error',
            persist: true,
          })
        }
      })
  }
  const handleGetSlots = () => {
    getAvailableSlots()
      .then((res: any) => {
        if (res.status === 200) {
          setTotalSlot(res?.data?.total_slots)
          setRemainingSlot(res?.data?.available_slots)
        }
      })
      .catch((error) => {
        setIsDataLoaded(true)
        // To handle cancel axios error
        if (axios.isCancel(error)) {
          console.debug('Request canceled:', error)
        } else if (
          error?.response?.data?.detail &&
          error?.response?.request?.status !== 500 &&
          error?.response?.request?.status !== 502
        ) {
          enqueueSnackbar(`${error?.response?.data?.detail}`, {
            variant: 'error',
            persist: true,
          })
        }
        if (error?.response?.request?.status === 502) {
          enqueueSnackbar(`Something went wrong`, {
            variant: 'error',
            persist: true,
          })
        }
      })
  }

  const handleGetProjects = (id, status) => {
    setIsAssignedProjectsLoading(true)
    getProjectsByUserId(id, status)
      .then((res: any) => {
        if (res.status === 200) {
          if (status === 'assigned') {
            setProjects(res?.data?.data)
          } else {
            setUnAssignedProjects(res?.data?.data)
            setSearchedProjects(res?.data?.data)
          }
          setIsAssignedProjectsLoading(false)
          setIsUnAssignedProjectsLoading(false)
        }
      })
      .catch((error) => {
        setIsUnAssignedProjectsLoading(false)
        setIsAssignedProjectsLoading(false)
        // To handle cancel axios error
        if (axios.isCancel(error)) {
          console.debug('Request canceled:', error)
        } else if (
          error?.response?.data?.detail &&
          error?.response?.request?.status !== 500 &&
          error?.response?.request?.status !== 502
        ) {
          enqueueSnackbar(`${error?.response?.data?.detail}`, {
            variant: 'error',
            persist: true,
          })
        }
        if (error?.response?.request?.status === 502) {
          enqueueSnackbar(`Something went wrong`, {
            variant: 'error',
            persist: true,
          })
        }
      })
  }
  const handleInviteUser = () => {
    const data = {
      name: inviteName,
      email: inviteEmail,
      role: inviteRole,
    }
    inviteUser(data)
      .then((res: any) => {
        if (res.status === 200) {
          handleCloseInviteMember()
          enqueueSnackbar(`${res?.data?.detail}`, {
            variant: 'success',
            persist: true,
          })
        }
      })
      .catch((error) => {
        setIsDataLoaded(true)
        // To handle cancel axios error
        if (axios.isCancel(error)) {
          console.debug('Request canceled:', error)
        } else if (
          error?.response?.data?.detail &&
          error?.response?.request?.status !== 500 &&
          error?.response?.request?.status !== 502
        ) {
          enqueueSnackbar(`${error?.response?.data?.detail}`, {
            variant: 'error',
            persist: true,
          })
        }
        if (error?.response?.request?.status === 502) {
          enqueueSnackbar(`Something went wrong`, {
            variant: 'error',
            persist: true,
          })
        }
      })
  }
  const handleUpdateUser = (data, id) => {
    const payload: any = {}
    if (data.status) {
      payload.status = data.status
    }
    if (data.name && data.email && data.role) {
      payload.name = data.name
      payload.role = data.role
    }

    updateUserRoleOrStatus(id, payload)
      .then((res: any) => {
        if (res.status === 200) {
          getUserList()
          if (userName && userEmail && userRole) {
            setUserEmail('')
            setUserRole('')
            setUserName('')
          }
        }
      })
      .catch((error) => {
        setIsDataLoaded(true)
        // To handle cancel axios error
        if (axios.isCancel(error)) {
          console.debug('Request canceled:', error)
        } else if (
          error?.response?.data?.detail &&
          error?.response?.request?.status !== 500 &&
          error?.response?.request?.status !== 502
        ) {
          enqueueSnackbar(`${error?.response?.data?.detail}`, {
            variant: 'error',
            persist: true,
          })
        }
        if (error?.response?.request?.status === 502) {
          enqueueSnackbar(`Something went wrong`, {
            variant: 'error',
            persist: true,
          })
        }
      })
  }
  const handleAssignProjectsToUser = (id) => {
    const payload = {
      project_ids: selectedProjects,
    }

    assignProjectsToUser(id, payload)
      .then((res: any) => {
        if (res.status === 200) {
          handleGetProjects(id, 'assigned')
        }
      })
      .catch((error) => {
        setIsDataLoaded(true)
        // To handle cancel axios error
        if (axios.isCancel(error)) {
          console.debug('Request canceled:', error)
        } else if (
          error?.response?.data?.detail &&
          error?.response?.request?.status !== 500 &&
          error?.response?.request?.status !== 502
        ) {
          enqueueSnackbar(`${error?.response?.data?.detail}`, {
            variant: 'error',
            persist: true,
          })
        }
        if (error?.response?.request?.status === 502) {
          enqueueSnackbar(`Something went wrong`, {
            variant: 'error',
            persist: true,
          })
        }
      })
  }
  const handleActiveUser = (id, status) => {
    const data: any = {}
    if (status) {
      data.status = status
    }
    updateUserRoleOrStatus(id, data)
      .then((res: any) => {
        if (res.status === 200) {
          getUserList()
        }
      })
      .catch((error) => {
        setIsDataLoaded(true)
        // To handle cancel axios error
        if (axios.isCancel(error)) {
          console.debug('Request canceled:', error)
        } else if (
          error?.response?.data?.detail &&
          error?.response?.request?.status !== 500 &&
          error?.response?.request?.status !== 502
        ) {
          enqueueSnackbar(`${error?.response?.data?.detail}`, {
            variant: 'error',
            persist: true,
          })
        }
        if (error?.response?.request?.status === 502) {
          enqueueSnackbar(`Something went wrong`, {
            variant: 'error',
            persist: true,
          })
        }
      })
  }
  const handleReInviteUser = (id) => {
    reInviteUser(id)
      .then((res: any) => {
        if (res.status === 200) {
          enqueueSnackbar(`${res?.data?.detail}`, {
            variant: 'success',
            persist: true,
          })
          getUserList()
        }
      })
      .catch((error) => {
        setIsDataLoaded(true)
        // To handle cancel axios error
        if (axios.isCancel(error)) {
          console.debug('Request canceled:', error)
        } else if (
          error?.response?.data?.detail &&
          error?.response?.request?.status !== 500 &&
          error?.response?.request?.status !== 502
        ) {
          enqueueSnackbar(`${error?.response?.data?.detail}`, {
            variant: 'error',
            persist: true,
          })
        }
        if (error?.response?.request?.status === 502) {
          enqueueSnackbar(`Something went wrong`, {
            variant: 'error',
            persist: true,
          })
        }
      })
  }

  const handleRemoveProject = (project) => {
    setProjectsMarkedForDeletion([...projectsMarkedForDeletion, project])
  }

  const handleUndoRemoveProject = (project) => {
    setProjectsMarkedForDeletion(
      projectsMarkedForDeletion.filter((p) => p.id !== project.id),
    )
  }

  const handleRemoveProjects = (id) => {
    const data = {
      project_ids: projectsMarkedForDeletion,
    }
    removeProjectsToUser(id, data)
      .then((res: any) => {
        if (res.status === 200) {
          enqueueSnackbar(`${res?.data?.detail}`, {
            variant: 'success',
            persist: true,
          })
          setIsAssignedProjectsLoading(true)
          handleGetProjects(id, 'assigned')
        }
      })
      .catch((error) => {
        setIsDataLoaded(true)
        // To handle cancel axios error
        if (axios.isCancel(error)) {
          console.debug('Request canceled:', error)
        } else if (
          error?.response?.data?.detail &&
          error?.response?.request?.status !== 500 &&
          error?.response?.request?.status !== 502
        ) {
          enqueueSnackbar(`${error?.response?.data?.detail}`, {
            variant: 'error',
            persist: true,
          })
        }
        if (error?.response?.request?.status === 502) {
          enqueueSnackbar(`Something went wrong`, {
            variant: 'error',
            persist: true,
          })
        }
      })
    setProjectsMarkedForDeletion([])
  }

  const handleProjectSearch = (value: string) => {
    setProjectSearchTerm(value)
    const filteredProjects = unAssignedProjects.filter((project) =>
      project.name.toLowerCase().includes(value.toLowerCase()),
    )
    setSearchedProjects(filteredProjects)
  }
  return (
    // Page-wrapper
    <div className="global-page-wrapper">
      <Stack className="content-wrapper">
        <div className="filters-container">
          {/* Filterbar Start here */}
          <div className="filters-contents">
            {/* Search Bar start here */}
            <div className="search-bar">
              <TextField
                id="search-members"
                placeholder={t('Search Users')}
                variant="standard"
                size="small"
                value={searchTerm}
                onChange={(event) => {
                  setSearchTerm(event.target.value)
                }}
                onKeyDown={handleKeyPress} // Call handleSearch on Enter key press
                sx={{
                  flex: 1,
                  border: '1px solid var(--gray-200) !important',
                  '& .MuiInput-root::before': {
                    border: 'none !important',
                  },
                  '& .MuiInput-root::after': {
                    border: 'none !important',
                  },
                  borderTopLeftRadius: '8px',
                  borderBottomLeftRadius: '8px',
                  borderTopRightRadius: '0px',
                  borderBottomRightRadius: '0px',
                }}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <MagniferIcon />
                    </InputAdornment>
                  ),
                  endAdornment: (
                    <InputAdornment position="end">
                      {searchTerm?.length > 0 && (
                        <XIcon
                          className="global-close-btn"
                          onClick={() => {
                            setSearchTerm('')
                          }}
                        />
                      )}
                    </InputAdornment>
                  ),
                }}
              />
              <TextButton
                text={t('Search')}
                color={'var(--gray-white)'}
                bgColor={
                  searchTerm ? 'var(--primary-600)' : 'var(--primary-300)'
                }
                disabled={!searchTerm}
                style={{
                  borderTopLeftRadius: 0,
                  borderBottomLeftRadius: 0,
                  // To create the effect of the button being attached to the search bar
                }}
              />
            </div>
            {/* Search Bar End here */}

            {/* Filter start here */}
            <FormControl size="small" style={{ minWidth: 150 }}>
              <Select
                id="filter-select"
                value={filter}
                onChange={handleFilterChange}
                style={{
                  borderRadius: '8px',
                  fontSize: '14px',
                  padding: '1.5px 0',
                }}
              >
                <MenuItem style={{ fontSize: '14px' }} value="active">
                  {t('Active Users')}
                </MenuItem>
                <MenuItem style={{ fontSize: '14px' }} value="deactivated">
                  {t('Deactivated Users')}
                </MenuItem>
              </Select>
            </FormControl>
            {/* Filter start here */}
          </div>

          <div className="refresh-action-container">
            <span className="icon-wrapper">
              <img className="default-icon" src={RefreshIcon} alt="Edit" />
              <img className="active-icon" src={ActiveRefreshIcon} alt="Edit" />
            </span>

            {/* Invite button */}
            <ButtonIcon
              text="Invite User"
              color={'var(--gray-white)'}
              IconComponent={AddIcon}
              bgColor={'var(--primary-600)'}
              onClick={handleOpenInviteMembers}
            />
          </div>
        </div>

        {/* Filterbar End here */}

        {/* Projects table component */}
        {!isDataLoaded ? (
          <div style={{ padding: '15rem' }}>
            <CircularProgress />
          </div>
        ) : (
          <TableComponent
            rows={users}
            columns={columns}
            enableBottomTool={true}
            errorMessage={t('No users found')}
            enableColumnPinning={false}
            enableResize={true}
            sorting={[]}
          />
        )}
        {users.length > 0 && isDataLoaded && (
          <TablePagination
            component="div"
            page={page}
            count={rowCount}
            rowsPerPage={rowsPerPage}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
            rowsPerPageOptions={[10, 20, 30]}
            labelRowsPerPage={t('Items per page:')}
          />
        )}

        {/* Dialog Start here */}
        <FormDialog
          open={isInviteMembersOpen}
          title={t('Invite')}
          onClose={handleCloseInviteMember}
          setProjectName={setProjectName}
          setFile={setFile}
          setUploadProgress={setUploadProgress}
        >
          <Stack display={'flex'} direction={'column'} gap={2}>
            {remainingSlot && totalSlot && (
              <span
                style={{
                  color:
                    remainingSlot < 3 ? 'var(--red-600)' : 'var(--gray-600)',
                  fontSize: '14px',
                }}
              >
                {remainingSlot}/{totalSlot} {t('slots remaining')}
              </span>
            )}
            <FormControl variant="standard">
              <InputLabel shrink htmlFor="member-name-input">
                {t('User Name')}
              </InputLabel>
              <InputBox
                id="member-name-input"
                onChange={(e) => {
                  setInviteName(e.target.value)
                }}
                disabled={remainingSlot <= 0}
              />
            </FormControl>
            <FormControl variant="standard">
              <InputLabel shrink htmlFor="member-email-input">
                {t('User Email')}
              </InputLabel>
              <InputBox
                id="member-name-input"
                onChange={(e) => {
                  setInviteEmail(e.target.value)
                }}
                disabled={remainingSlot <= 0}
              />
            </FormControl>
          </Stack>

          <FormControl>
            <FormLabel
              sx={{
                marginTop: '13px',
                fontSize: '13.6px',
                letterSpacing: '0.019700em !important',
              }}
            >
              {t('User Role')}
            </FormLabel>
            <RadioGroup
              aria-labelledby="demo-controlled-radio-buttons-group"
              name="controlled-radio-buttons-group"
              value={inviteRole}
              onChange={handleRoleRadioChange}
            >
              <Stack display={'flex'} direction={'row'} gap={2}>
                <FormControlLabel
                  disabled={remainingSlot <= 0}
                  value="admin"
                  control={<Radio checkedIcon={<CheckRadioIcon />} />}
                  label={t('Admin')}
                  sx={{
                    '& .MuiSvgIcon-root': {
                      fontSize: '18px',
                    },
                    '& .MuiFormControlLabel-label': {
                      fontSize: '14px',
                    },
                  }}
                />
                <FormControlLabel
                  disabled={remainingSlot <= 0}
                  value="manager"
                  control={<Radio checkedIcon={<CheckRadioIcon />} />}
                  label={t('Manager')}
                  sx={{
                    '& .MuiSvgIcon-root': {
                      fontSize: '18px',
                    },
                    '& .MuiFormControlLabel-label': {
                      fontSize: '14px',
                    },
                  }}
                />
                <FormControlLabel
                  disabled={remainingSlot <= 0}
                  value="operator"
                  control={<Radio checkedIcon={<CheckRadioIcon />} />}
                  label={t('Operator')}
                  sx={{
                    '& .MuiSvgIcon-root': {
                      fontSize: '18px',
                    },
                    '& .MuiFormControlLabel-label': {
                      fontSize: '14px',
                    },
                  }}
                />
              </Stack>
            </RadioGroup>
          </FormControl>

          <div
            style={{
              display: 'flex',
              justifyContent: 'flex-end',
              marginTop: '16px',
            }}
          >
            {/* Dialog Craete Button */}
            <TextButton
              text={t('Invite')}
              color={'#fff'}
              bgColor={
                remainingSlot <= 0 || disableInviteButton
                  ? 'var(--primary-300)' // Use primary-300 for the disable color
                  : 'var(--primary-600)'
              }
              disabled={remainingSlot <= 0 || disableInviteButton}
              onClick={handleInviteUser}
              style={{
                width: '100%',
                fontSize: '14px',
                textTransform: 'capitalize',
                boxShadow: 'none',
                padding: '8px 0',
              }}
            />
            {/* <Button
            variant="contained"
            fullWidth
            disabled={disableCreateButton} // Disable button based on state
            onClick={createProject}
            style={{
              backgroundColor: disableCreateButton
                ? 'var(--primary-300)' // Use primary-300 for the disable color
                : 'var(--primary-600)', // Use primary-600 for the button color
              color: 'var(--gray-white)',
              fontSize: '14px',
              textTransform: 'capitalize',
              boxShadow: 'none',
              padding: '8px 0',
            }}
          >
            Create
          </Button> */}
          </div>
        </FormDialog>
        {/* Dialog End here */}

        {/* View Dialog start here */}
        <FormDialog
          open={isViewDialogState.open}
          onClose={handleCloseViewDialog}
          setProjectName={setProjectName}
          setFile={setFile}
          setUploadProgress={setUploadProgress}
        >
          <Stack display={'flex'} direction={'column'} gap={2}>
            <FormControl variant="standard">
              <Typography fontSize={'15px'} fontWeight={600}>
                {t('Email')}
              </Typography>
              <InputBox id="email-input" value={userEmail} disabled />
            </FormControl>
            <FormControl variant="standard">
              <Typography fontSize={'15px'} fontWeight={600}>
                {t('Name')}
              </Typography>
              <InputBox
                disabled={isViewDialogState.type === 'deActive'}
                id="username-input"
                value={userName}
                onChange={(e) => {
                  setUserName(e.target.value)
                }}
              />
            </FormControl>
          </Stack>

          <FormControl>
            <Typography
              fontSize={'15px'}
              fontWeight={600}
              sx={{ marginTop: '13px' }}
            >
              {t('Role')}
            </Typography>
            <RadioGroup
              aria-labelledby="demo-controlled-radio-buttons-group"
              name="controlled-radio-buttons-group"
              value={userRole}
              onChange={handleUserRoleRadioChange}
            >
              <Stack display={'flex'} direction={'row'} gap={2}>
                <FormControlLabel
                  value="admin"
                  control={<Radio checkedIcon={<CheckRadioIcon />} />}
                  label={t('Admin')}
                  disabled={isViewDialogState.type === 'deActive'}
                  sx={{
                    '& .MuiSvgIcon-root': {
                      fontSize: '18px',
                    },
                    '& .MuiFormControlLabel-label': {
                      fontSize: '14px',
                    },
                  }}
                />
                <FormControlLabel
                  value="manager"
                  control={<Radio checkedIcon={<CheckRadioIcon />} />}
                  label={t('Manager')}
                  disabled={isViewDialogState.type === 'deActive'}
                  sx={{
                    '& .MuiSvgIcon-root': {
                      fontSize: '18px',
                    },
                    '& .MuiFormControlLabel-label': {
                      fontSize: '14px',
                    },
                  }}
                />
                <FormControlLabel
                  value="operator"
                  control={<Radio checkedIcon={<CheckRadioIcon />} />}
                  label={t('Operator')}
                  disabled={isViewDialogState.type === 'deActive'}
                  sx={{
                    '& .MuiSvgIcon-root': {
                      fontSize: '18px',
                    },
                    '& .MuiFormControlLabel-label': {
                      fontSize: '14px',
                    },
                  }}
                />
              </Stack>
            </RadioGroup>
          </FormControl>
          {isViewDialogState.type === 'deActive' && (
            <>
              <Typography fontSize={'15px'} fontWeight={'600'}>
                {' '}
                {t('Projects')}
                <Badge
                  style={{
                    marginLeft: '15px',
                  }}
                  showZero
                  color="secondary"
                  badgeContent={projects?.length}
                ></Badge>
              </Typography>
              <List
                sx={{
                  maxHeight: '200px',
                  borderRadius: '8px',
                  margin: '10px 0',
                  overflowY: 'auto',
                  border: '1px solid var(--gray-400)',
                }}
              >
                {isAssignedProjectsLoading ? (
                  <CircularProgress
                    size={20}
                    sx={{
                      position: 'absolute',
                      left: '50%',
                      top: '40%',
                      transform: 'translate(-60%, -50%)',
                    }}
                  />
                ) : (
                  <>
                    {projects?.length === 0 ? (
                      <span
                        style={{
                          position: 'absolute',
                          left: '50%',
                          top: '50%',
                          transform: 'translate(-50%, -50%)',
                        }}
                      >
                        {t('No Active projects')}
                      </span>
                    ) : (
                      <>
                        {projects?.map((project, index) => (
                          <ListItem key={index} secondaryAction={<></>}>
                            <ListItemText
                              primary={
                                <span
                                  style={{
                                    textDecoration:
                                      projectsMarkedForDeletion.includes(
                                        project,
                                      )
                                        ? 'line-through'
                                        : 'none',
                                  }}
                                >
                                  {project.name}
                                </span>
                              }
                            />
                          </ListItem>
                        ))}
                      </>
                    )}
                  </>
                )}
              </List>
            </>
          )}
          {isViewDialogState.type !== 'deActive' && (
            <Stack display={'flex'} direction={'column'} gap={1}>
              <OutlineTextButton
                text={t('Deactivate')}
                color={'var(--primary-600)'}
                bgColor={'#fff'}
                outlineColor={'var(--primary-600)'}
                onClick={() => {
                  handleOpenDeactivateConfirmDialog()
                }}
                style={{
                  width: '100%',
                  height: '42px',
                  fontSize: '14px',
                  textTransform: 'capitalize',
                  boxShadow: 'none',
                  padding: '8px 0',
                }}
              />
              {/* Dialog Craete Button */}
              <TextButton
                text={t('Save')}
                color={'#fff'}
                bgColor={
                  !userName || !userRole
                    ? 'var(--primary-300)' // Use primary-300 for the disable color
                    : 'var(--primary-600)'
                }
                disabled={!userName || !userRole}
                onClick={() => {
                  handleCloseViewDialog()
                  handleUpdateUser(
                    { name: userName, email: userEmail, role: userRole },
                    rowDetails?.id,
                  )
                }}
                style={{
                  width: '100%',
                  height: '42px',
                  fontSize: '14px',
                  textTransform: 'capitalize',
                  boxShadow: 'none',
                  padding: '8px 0',
                }}
              />
            </Stack>
          )}
        </FormDialog>
        {/* View Dilaog ends here */}

        {/* Projects Dialog start here */}
        <FormDialog
          open={isProjectsOpenDialog}
          onClose={handleCloseProjectDialog}
          setProjectName={setProjectName}
          setFile={setFile}
          setUploadProgress={setUploadProgress}
        >
          <Box color={'var(--gray-600)'}>
            <Typography variant="h6" fontWeight={'600'} fontSize={'24px'}>
              {rowDetails?.name}
            </Typography>
            <Typography variant="subtitle1">{rowDetails?.email}</Typography>
            <br />
            <Typography variant="h6" fontWeight={'500'}>
              {' '}
              {t('Projects')}
              <Badge
                style={{
                  marginLeft: '15px',
                }}
                showZero
                color="secondary"
                badgeContent={projects?.length}
              ></Badge>
            </Typography>
            <List
              sx={{
                width: '100%',
                height: '200px',
                borderRadius: '8px',
                margin: '10px 0',
                overflowY: 'auto',
                border: '1px solid var(--gray-400)',
              }}
            >
              {isAssignedProjectsLoading ? (
                <CircularProgress
                  size={20}
                  sx={{
                    position: 'absolute',
                    left: '50%',
                    top: '40%',
                    transform: 'translate(-60%, -50%)',
                  }}
                />
              ) : (
                <>
                  {projects?.length === 0 ? (
                    <span
                      style={{
                        position: 'absolute',
                        left: '50%',
                        top: '50%',
                        transform: 'translate(-50%, -50%)',
                      }}
                    >
                      {t('No Active projects')}
                    </span>
                  ) : (
                    <>
                      {projects?.map((project, index) => (
                        <ListItem
                          key={index}
                          secondaryAction={
                            projectsMarkedForDeletion.includes(project) ? (
                              <IconButton
                                edge="end"
                                onClick={() => {
                                  handleUndoRemoveProject(project)
                                }}
                                sx={{
                                  '&:hover': { backgroundColor: 'transparent' },
                                }}
                              >
                                <RestartIcon />
                              </IconButton>
                            ) : (
                              <IconButton
                                edge="end"
                                aria-label="delete"
                                onClick={() => {
                                  handleRemoveProject(project)
                                }}
                                sx={{
                                  '&:hover': { backgroundColor: 'transparent' },
                                }}
                              >
                                <ActiveDeleteIcon />
                              </IconButton>
                            )
                          }
                        >
                          <ListItemText
                            primary={
                              <span
                                style={{
                                  textDecoration:
                                    projectsMarkedForDeletion.includes(project)
                                      ? 'line-through'
                                      : 'none',
                                }}
                              >
                                {project.name}
                              </span>
                            }
                          />
                        </ListItem>
                      ))}
                    </>
                  )}
                </>
              )}
            </List>
            <Stack display={'flex'} direction={'column'} gap={1}>
              <OutlineTextButton
                text={t('Assign Projects')}
                bgColor={'#fff'}
                color={
                  disableViewFormSaveBtn
                    ? 'var(--primary-600)'
                    : 'var(--primary-300)' // Use primary-300 for the disable color
                }
                disabled={!disableViewFormSaveBtn}
                outlineColor={'var(--primary-600)'}
                style={{
                  width: '100%',
                  height: '42px',
                  fontSize: '14px',
                  textTransform: 'capitalize',
                  boxShadow: 'none',
                  padding: '8px 0',
                }}
                onClick={() => {
                  onAssignProjectsClick(rowDetails?.id)
                }}
              />

              {/* Dialog Craete Button */}
              <TextButton
                text={t('Save')}
                color={'#fff'}
                bgColor={
                  disableViewFormSaveBtn
                    ? 'var(--primary-300)' // Use primary-300 for the disable color
                    : 'var(--primary-600)'
                }
                disabled={disableViewFormSaveBtn}
                onClick={() => {
                  handleCloseProjectDialog()
                  handleRemoveProjects(rowDetails.id)
                }}
                style={{
                  width: '100%',
                  height: '42px',
                  fontSize: '14px',
                  textTransform: 'capitalize',
                  boxShadow: 'none',
                  padding: '8px 0',
                }}
              />
            </Stack>
          </Box>
        </FormDialog>
        {/* Projects Dilaog ends here */}
        {/* Assign Project Dialog start here */}
        <FormDialog
          open={isAssignProjectsOpenDialog}
          onClose={handleCloseAssignProjectDialog}
          setProjectName={setProjectName}
          setFile={setFile}
          setUploadProgress={setUploadProgress}
        >
          <Box>
            <Typography
              variant="h6"
              fontWeight={'600'}
              fontSize={'19px'}
              color={'var(--gray-600)'}
            >
              {t('Assign Projects')}
            </Typography>
            <Box
              sx={{
                width: '100%',
                height: '250px',
                borderRadius: '8px',
                margin: '10px 0',
                overflowY: 'hidden',
                border: '1px solid var(--gray-200)',
              }}
            >
              <TextField
                placeholder={t('Search Active Projects')}
                variant="standard"
                size="small"
                fullWidth
                value={projectSearchTerm}
                onChange={(e) => {
                  handleProjectSearch(e.target.value)
                }}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <MagniferIcon />
                    </InputAdornment>
                  ),
                }}
                sx={{
                  border: 'none',
                  '& fieldset': { border: 'none' },
                  '& .MuiInput-root::before': {
                    border: 'none !important',
                  },
                  '& .MuiInput-root::after': {
                    border: 'none !important',
                  },
                  borderBottom: '1px solid var(--gray-200) !important',
                  background: 'var(--gray-50)',
                  '& input::placeholder': {
                    fontSize: '15px',
                  },
                }}
              />
              <List
                sx={{
                  position: 'relative',
                  overflowY: 'auto',
                  height: '200px',
                }}
              >
                {isUnAssignedProjectsLoading ? (
                  <CircularProgress
                    size={20}
                    sx={{
                      position: 'absolute',
                      left: '50%',
                      top: '40%',
                      transform: 'translate(-60%, -50%)',
                    }}
                  />
                ) : (
                  <>
                    {searchedProjects?.length === 0 ? (
                      <span
                        style={{
                          position: 'absolute',
                          left: '50%',
                          top: '50%',
                          transform: 'translate(-50%, -50%)',
                        }}
                      >
                        {t('No Unassigned projects')}
                      </span>
                    ) : (
                      <>
                        {searchedProjects?.length > 0 &&
                          searchedProjects.map((project, index) => (
                            <ListItem
                              sx={{
                                paddingLeft: '5px !important',
                                alignItems: 'center',
                              }}
                              key={index}
                            >
                              <Checkbox
                                checkedIcon={<CheckedIcon />}
                                icon={<DefaultCheckIcon />}
                                checked={selectedProjects.includes(project.id)}
                                onChange={() => {
                                  handleCheckboxChange(project.id)
                                }}
                              />
                              <ListItemText
                                sx={{
                                  color: 'var(--primary-600)',
                                  '& .MuiTypography-root': {
                                    fontSize: '14px !important',
                                  },
                                }}
                                primary={project.name}
                              />
                            </ListItem>
                          ))}
                      </>
                    )}
                  </>
                )}
              </List>
            </Box>

            <TextButton
              text={t('Assign')}
              color={'#fff'}
              bgColor={
                disableAssignBtn ? 'var(--primary-300)' : 'var(--primary-600)'
              }
              disabled={disableAssignBtn}
              onClick={() => {
                handleAssignProjectsToUser(rowDetails?.id)
                handleCloseAssignProjectDialog()
              }}
              style={{
                width: '100%',
                height: '42px',
                fontSize: '14px',
                textTransform: 'capitalize',
                boxShadow: 'none',
                padding: '8px 0',
                marginTop: '10px',
              }}
            />
          </Box>
        </FormDialog>
        {/* Assign Project ends here */}
        <CancelConformationFormDialog
          open={dialogState?.isOpen}
          onClose={handleCloseDeactivateConfirmDialog}
          message={
            dialogState?.type === 'delete'
              ? t('Changes are not saved, you can lose them. Are you sure?')
              : `Are you sure, you want to deactivate ${rowDetails?.name} (${rowDetails?.email}?`
          }
        ></CancelConformationFormDialog>
      </Stack>
    </div>
  )
}

export default UserManagement
