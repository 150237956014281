import React, { useEffect, useState } from 'react'

interface PasswordStrengthMeterProps {
  password: string
}

const PasswordStrengthMeter: React.FC<PasswordStrengthMeterProps> = ({
  password,
}) => {
  const [color, setColor] = useState('')
  const [score, setScore] = useState(0)

  const strongRegex =
    /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[^a-zA-Z\d\s])[A-Za-z\d\S ](?=.{12,})/
  const mediumRegex =
    /^(((?=.*[a-z])(?=.*[A-Z]))|((?=.*[a-z])(?=.*\d))|((?=.*[A-Z])(?=.*\d)))(?=.*\s*)(?=.{12,})/

  useEffect(() => {
    if (password.length > 128) {
      setColor('#e5caa1') // Yellow for passwords longer than 128 characters
      setScore(2) // Set to 4 to fill all segments
    } else if (strongRegex.test(password)) {
      setColor('#0F9D58') // Green
      setScore(4) // Set to 4 to fill all segments
    } else if (mediumRegex.test(password)) {
      setColor('#F4B400') // Yellow
      setScore(3) // Set to 3 to fill 3 segments
    } else if (password.length >= 12) {
      setColor('#e5caa1') // Light Red
      setScore(2) // Set to 2 to fill 2 segments
    } else {
      setColor('#DB4437') // Red
      setScore(1) // Set to 1 to fill 1 segment
    }
  }, [password])

  // Function to style each segment of the progress bar
  const getSegmentStyle = (segmentIndex: number): React.CSSProperties => {
    return {
      flex: 1,
      height: '7px',
      backgroundColor: segmentIndex < score ? color : '#e0e0e0', // Fill segments up to the score
      margin: '0 2px',
      borderRadius: '2px',
      width: '100%',
    }
  }

  return (
    <>
      <div
        style={{
          display: 'flex',
          alignItems: 'center',
          height: '7px',
          marginTop: '10px',
          width: '100%',
        }}
      >
        {[0, 1, 2, 3].map((segmentIndex) => (
          <div key={segmentIndex} style={getSegmentStyle(segmentIndex)}></div>
        ))}
      </div>
    </>
  )
}

export default PasswordStrengthMeter
