import { MaterialReactTable, useMaterialReactTable } from 'material-react-table'

import { ReactComponent as ArrowDownIcon } from '../../assets/images/down_arrow.svg'
import type { MRT_ColumnDef } from 'material-react-table'

// Define the props for the TableComponent
interface TableComponentProps<T extends object> {
  columns?: any
  rows: T[]
  isSort?: boolean
  enableResize?: boolean
  enableBottomTool?: boolean
  columnVisibility?: any
  setColumnVisibility?: any
  enableColumnPinning?: boolean
  customCssForUploadTarget?: boolean
  errorMessage?: string
  sorting: any[]
  setSorting?: any
  hideBorder?: boolean
  isHomeDataLoading?: boolean
}

// Define a generic TableComponent with props
function TableComponent<T extends object>({
  columns,
  rows,
  isSort,
  enableResize,
  enableBottomTool,
  columnVisibility,
  setColumnVisibility,
  enableColumnPinning,
  customCssForUploadTarget,
  errorMessage,
  sorting,
  setSorting,
  hideBorder,
  isHomeDataLoading,
}: TableComponentProps<T>) {
  if (sorting === undefined || sorting.length === 0) {
    sorting = []
  }
  const table = useMaterialReactTable({
    columns,
    data: rows,
    // enableGlobalFilter: false,
    // enableColumnFilters: false,
    // enableDensityToggle: false,
    // enableFullScreenToggle: false,

    enableColumnActions: false,
    enableTopToolbar: false,
    enableColumnResizing: enableResize,
    enableHiding: false,
    enableBottomToolbar: enableBottomTool ?? true,
    enablePagination: false, // Enable pagination
    enableSorting: true,
    state: { columnVisibility, sorting }, // this is for results page: manage columnVisibility state
    onColumnVisibilityChange: setColumnVisibility,
    manualSorting: true, // Enable manual sorting
    onSortingChange: setSorting,
    enableColumnPinning,
    initialState: {
      columnPinning: { right: ['result_action'] },
    },
    layoutMode: 'grid', // constant column widths
    renderEmptyRowsFallback: ({ table }) =>
      !isHomeDataLoading && (
        <span
          style={{
            font: '500 20px/30px "IBM Plex Sans"',
            color: 'var(--gray-600)',
            padding: '10rem',
          }}
        >
          {errorMessage}
        </span>
      ),

    // muiPaginationProps: {
    //   showFirstButton: false,
    //   showLastButton: false,
    //   variant: 'outlined',
    //   sx: {
    //     display: 'none',
    //     padding: '0px !important',
    //   },
    //   SelectProps: {
    //     IconComponent: ArrowDownIcon,
    //     sx: {
    //       border: '1px solid var(--gray-200)',
    //       padding: '5px',
    //       borderRadius: '4px !important',
    //       width: '80px',
    //       height: '42px',
    //     },
    //   },
    //   // Consider adding count: rowCount if pagination is server-side
    // },
    defaultColumn: {
      maxSize: 400,
      minSize: 80,
      size: 160,
    },
    // layoutMode: 'grid',
    mrtTheme: (theme: any) => ({
      baseBackgroundColor: theme.palette.background.default, // change default background color
    }),
    muiBottomToolbarProps: {
      sx: {
        display: 'none !important',
        boxShadow: 'none',
        top: '7px',
        '& .MuiTablePagination-root': {
          padding: '0px !important',
        },
      },
    },
    muiTablePaperProps: {
      sx: {
        boxShadow: 'none',
      },
    },
    muiTableBodyRowProps: {
      hover: false,
    },
    muiTableBodyCellProps: hideBorder
      ? ({ table, row }) => ({
          sx: {
            borderBottom:
              row.index === table.getRowModel().rows.length - 1
                ? 'none'
                : undefined,
          },
        })
      : {},
    muiTableProps: customCssForUploadTarget
      ? // to change  height of upload target table
        {
          sx: {
            width: '100%',
            maxHeight: 'calc(100vh - 400px) !important', // Applied only when customCssForUploadTarget is true
            overflowY: 'auto', // Applied only when customCssForUploadTarget is true
          },
        }
      : {
          sx: {
            width: '100%', // Apply other default styles as needed
          },
        },
    muiTableHeadRowProps: {
      sx: {
        boxShadow: 'none',
      },
    },
    muiTableHeadCellProps: {
      sx: {
        backgroundColor: 'var(--gray-50)',
        color: 'var(--gray-500)',

        '.Mui-TableHeadCell-ResizeHandle-Divider': {
          display: 'none',
        },
        '&:hover .Mui-TableHeadCell-ResizeHandle-Divider': {
          display: 'block',
        },
        '.MuiTableSortLabel-icon': {
          display: 'none',
        },
        '&:hover .MuiTableSortLabel-icon': {
          display: 'block',
        },
        '.Mui-TableHeadCell-Content-Wrapper': {
          textOverflow: 'unset !important',
          whiteSpace: 'break-spaces !important',
        },
      },
    },
  })
  return <MaterialReactTable<T> table={table} />
}

export default TableComponent
