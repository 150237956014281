import React, { Component, RefObject, createRef } from 'react'
import './style/Tip.css'
import { Button, Tooltip } from '@mui/material'
import TextField from '@mui/material/TextField'

interface State {
  compact: boolean
  text: any
  emoji: string
}

interface Props {
  onConfirm: (comment: string) => void
  onOpen: () => void
  onUpdate?: () => void
  handleClose?: () => void
  collationName?: string
  isEditMode?: boolean
  t: any
  // isSearchable?: boolean
}

export class Tip extends Component<Props, State> {
  containerRef: RefObject<HTMLDivElement> // Declare containerRef as a class property
  constructor(props: Props) {
    super(props)
    this.state = {
      compact: true,
      text: this.props.collationName || null,
      emoji: '',
    }
    this.containerRef = createRef<HTMLDivElement>() // Initialize the ref explicitly
  }
  // for TipContainer
  componentDidUpdate(nextProps: Props, nextState: State) {
    const { onUpdate } = this.props

    if (onUpdate && this.state.compact !== nextState.compact) {
      onUpdate()
    }
  }

  handleChange = (e) => {
    this.setState({ text: e.target.value })
  }
  render() {
    const { onConfirm, handleClose, collationName, isEditMode, t } = this.props
    const { text } = this.state
    // if (isSearchable) {
    return (
      <>
        {isEditMode ? (
          <div className="Tip" ref={this.containerRef}>
            <div
              style={{
                display: 'flex',
                gap: 5,
                height: '30px',
                justifyContent: 'end',
                marginTop: '10px',
              }}
            >
              <Button
                style={{
                  borderRadius: 4,
                  border: '1px solid var(--gray-white)',
                  height: 'inherit !important',
                  color: 'var(--gray-white)',
                  backgroundColor: 'transparent',
                  textTransform: 'capitalize',
                }}
                onClick={() => {
                  // remove selection text on cancel
                  const selection: any = window.getSelection()
                  selection.rangeCount > 0 && selection.removeAllRanges()
                  handleClose && handleClose()
                }}
              >
                {t('Cancel')}
              </Button>
              <Tooltip title={t('save')}>
                <Button
                  style={{
                    borderRadius: 4,
                    color: 'var(--gray-600)',
                    backgroundColor: 'var(--gray-white)',
                    textTransform: 'capitalize',
                  }}
                  onClick={(event) => {
                    // event.preventDefault()
                    onConfirm(text)
                    // remove selection text on save
                    const selection: any = window.getSelection()
                    selection.rangeCount > 0 && selection.removeAllRanges()
                  }}
                >
                  {t('Save')}
                </Button>
              </Tooltip>
            </div>
          </div>
        ) : (
          <div className="Tip" ref={this.containerRef}>
            <TextField
              autoFocus
              margin="dense"
              id="name"
              type="text"
              variant="standard"
              disabled={collationName !== ''}
              defaultValue={collationName === null ? '-' : collationName}
              onKeyPress={(e: any) => {
                if (e.key === 'Enter') {
                  onConfirm(text)
                }
              }}
              onBlur={() => {
                if (this.state.text) {
                  this.setState({ text: this.state.text.trim() })
                }
              }}
              sx={{
                '& .MuiInput-root': {
                  width: 'calc(100% + 10px) !important',
                },
                '& .MuiInputBase-input': {
                  color: 'var(--gray-white) !important',
                  fontSize: '14px',
                },
                '& .Mui-disabled': {
                  color: 'var(--gray-300) !important',
                  fontSize: '14px',
                  textFillColor: 'var(--gray-300) !important',
                },
                '& .MuiInput-root::before': {
                  border: 'none !important',
                },
                '& .MuiInput-root::after': {
                  border: 'none !important',
                },
              }}
              placeholder={t('Enter collation area name')}
              //照合領域名を入力してください
              onChange={(e) => {
                this.handleChange(e)
              }}
            />
            <div
              style={{
                display: 'flex',
                gap: 5,
                height: '25px',
                justifyContent: 'end',
                marginTop: '10px',
              }}
            >
              <Button
                style={{
                  borderRadius: 4,
                  border: '1px solid var(--gray-white)',
                  height: 'inherit !important',
                  color: 'var(--gray-white)',
                  backgroundColor: 'transparent',
                  textTransform: 'capitalize',
                }}
                onClick={handleClose}
              >
                {t('Cancel')}
              </Button>
              <Tooltip title={t('save')}>
                <Button
                  style={{
                    borderRadius: 4,
                    color: 'var(--gray-600)',
                    backgroundColor: 'var(--gray-white)',
                    textTransform: 'capitalize',
                  }}
                  onClick={(event) => {
                    // event.preventDefault()
                    onConfirm(text)
                    // remove selection text on save
                    const selection: any = window.getSelection()
                    selection.rangeCount > 0 && selection.removeAllRanges()
                  }}
                >
                  {t('Save')}
                </Button>
              </Tooltip>
            </div>
          </div>
        )}
      </>
    )
    // } else return null
  }
}

export default Tip
