import AppBar from '@mui/material/AppBar'
import Toolbar from '@mui/material/Toolbar'
import caxLogo from '../../assets/images/macnica_business_LOGO_HealthTech.svg'
import CTA from '../../assets/images/cta.svg'
import membersIcon from '../../assets/images/members.svg'
import activeMembersIcon from '../../assets/images/active_members.svg'
import logoutIcon from '../../assets/images/logout.svg'
import activeLogoutIcon from '../../assets/images/active_logout.svg'
import './topbar.scss'
import { IconButton, Stack, Tooltip, Typography } from '@mui/material'
import {
  Navigate,
  useLocation,
  useNavigate,
  useSearchParams,
} from 'react-router-dom'
import Cookies from 'js-cookie'
import { useEffect, useState, useContext } from 'react'
import { enqueueSnackbar } from 'notistack'
import { logoutAPI, meAPI } from '../../modules/auth/signup/auth-services'
import { ReactComponent as GoBackIcon } from '../../assets/images/goback_arrow.svg'
import { ReactComponent as ActiveGoBackIcon } from '../../assets/images/active_goback_arrow.svg'
import {
  ProjectContext,
  StatusContext,
  UserContext,
  highlightsContext,
} from '../../context/globalContext'
import { getProjectStatus } from '../../services/collation-service'
import { useAuth } from '../../utils/AuthContext'
import { useTranslation } from 'react-i18next'
import TruncateText from '../tooltip/truncate'
import { getLocalizedPath } from '../../utils/shareLocalizePath'
import LightTooltip from '../tooltip/tooltip'
import debounce from 'lodash.debounce'
import axios from 'axios'

export default function Topbar(): JSX.Element {
  const [isMounted, setIsMounted] = useState(false)

  const location = useLocation()
  const navigate = useNavigate()
  const prevPath = useLocation()
  const { projectName, projectId } = useContext<any>(ProjectContext)
  const { setReqireCallGetTarget, isProgressComplete } =
    useContext<any>(highlightsContext)
  const [isFetchingStatus, setIsFetchingStatus] = useState(false)
  const [logoClick, setLogoClick] = useState('')
  const [statusDisplayName, setStatusDisplayName] = useState('')

  const {
    projectStatus,
    setCollationStatus,
    setTargetDocumentStatus,
    setSourcePhraseStatus,
    setProjectStatus,
    setTargetFinalizeStatus,
  } = useContext<any>(StatusContext)
  const { userRole } = useContext<any>(UserContext)
  const [queryParameters] = useSearchParams()
  const { logoutUser } = useAuth()
  const { t, i18n } = useTranslation()
  const currentLanguage = i18n.language
  const isUserManagementAvailable =
    process.env.REACT_APP_ENABLE_USER_MANAGEMENT === 'true'
  useEffect(() => {
    setIsMounted(true) // Set isMounted to true when component is mounted
    return () => {
      setIsMounted(false) // Set isMounted to false when component is unmounted
    }
  }, [])

  const goToHome = () => {
    navigate(`${getLocalizedPath(currentLanguage, '/home')}`)
    setLogoClick('')
  }

  useEffect(() => {
    const statusInterval = setInterval(() => {
      if (
        !isFetchingStatus &&
        (location.pathname.includes('result') ||
          location.pathname.includes('project-setting') ||
          (location.pathname.includes('projects') &&
            queryParameters.get('project_id') &&
            location.search.includes('project_id') &&
            isProgressComplete))
      ) {
        handleProjectStatus(queryParameters.get('project_id'))
      } else {
        clearInterval(statusInterval)
      }
    }, 5000)

    return () => {
      clearInterval(statusInterval)
    }
  })

  // useEffect(() => {
  //   const debounceId = setTimeout(() => {
  //     goToHome()
  //   }, 2000)
  // }, [logoClick])

  // To save all the project level status
  const handleProjectStatus = (newProjectId) => {
    setIsFetchingStatus(true)
    if (newProjectId) {
      getProjectStatus(newProjectId)
        .then((res) => {
          if (res && res.status === 200) {
            if (
              (projectStatus === 'COLLATION_IN_PROGRESS' ||
                projectStatus === 'PREPARING_FOR_COLLATION' ||
                projectStatus === 'INHERITING_COLLATIONS') &&
              (res?.data?.project_status !== 'COLLATION_IN_PROGRESS' ||
                res?.data?.project_status !== 'PREPARING_FOR_COLLATION' ||
                res?.data?.project_status !== 'INHERITING_COLLATIONS')
            ) {
              setReqireCallGetTarget(true)
            } else {
              setReqireCallGetTarget(false)
            }
            setProjectStatus(res?.data?.project_status)
            setCollationStatus(res?.data?.collation_status)
            setTargetDocumentStatus(res?.data?.target_documents_status)
            setSourcePhraseStatus(res?.data?.source_phrase_status)
            setTargetFinalizeStatus(res?.data?.are_targets_finalized)
            setIsFetchingStatus(false)
          }
        })
        .catch((error) => {
          setIsFetchingStatus(false)
          // To handle cancel axios error
          if (axios.isCancel(error)) {
            console.debug('Request canceled:', error)
          } else if (
            error?.response?.data?.detail &&
            error.response.request.status === 403
          ) {
            logout()
            enqueueSnackbar(`${error?.response?.data?.detail}.`, {
              variant: 'error',
              persist: true,
            })
          }
          if (error?.response?.request?.status === 502) {
            enqueueSnackbar(`Something went wrong`, {
              variant: 'error',
              persist: true,
            })
          }
        })
    }
  }

  // get current location | decides the layout dropdown visiblity
  const goToLoginPage = () => {
    navigate(`${getLocalizedPath(currentLanguage, '/')}`)
  }
  // get current location | decides the layout dropdown visiblity
  const goToHomePage = () => {
    // setLogoClick('clicked')
    navigate(`${getLocalizedPath(currentLanguage, '/home')}`)
  }
  // get current location | decides the layout dropdown visiblity
  const goToUserManagementPage = () => {
    // setLogoClick('clicked')
    navigate(`${getLocalizedPath(currentLanguage, '/user-management/active')}`)
  }
  const logout = () => {
    logoutAPI()
      .then((response) => {
        if (response) {
          Cookies.remove('csrftoken')
          // enqueueSnackbar(`Logout successful.`, {
          //   variant: 'success',
          // })
          logoutUser()
          localStorage.clear()
          goToLoginPage()
        }
      })
      .catch((error) => {
        // To handle cancel axios error
        if (axios.isCancel(error)) {
          console.debug('Request canceled:', error)
        } else if (
          error?.response?.data?.detail &&
          error?.response?.request?.status === 403
        ) {
          enqueueSnackbar(`${error?.response?.data?.detail}.`, {
            variant: 'error',
            persist: true,
          })
        }
        if (error?.response?.request?.status === 502) {
          enqueueSnackbar(`Something went wrong`, {
            variant: 'error',
            persist: true,
          })
        }
      })
  }
  // to change status text from slug format to displayName format
  useEffect(() => {
    if (
      projectStatus === 'COLLATION_AREA_SETTING' ||
      projectStatus === 'Collation Area Setting'
    ) {
      setStatusDisplayName(t('Collation Area Setting'))
    } else if (
      projectStatus === 'COLLATION_IN_PROGRESS' ||
      projectStatus === 'PREPARING_FOR_COLLATION' ||
      projectStatus === 'INHERITING_COLLATIONS'
    ) {
      setStatusDisplayName(t('Collation In Progress'))
    } else if (projectStatus === 'COLLATION_VERIFICATION') {
      setStatusDisplayName(t('Collation Verification'))
    } else if (projectStatus === 'WAITING_FOR_TARGET_DOCUMENTS') {
      setStatusDisplayName(t('Waiting for Target Documents'))
    } else if (projectStatus === 'WAITING_FOR_COLLATION_APPROVAL') {
      setStatusDisplayName(t('Waiting for Collation Approval'))
    } else if (projectStatus === 'COMPLETED') {
      setStatusDisplayName(t('Completed'))
    } else if (projectStatus === '') {
      setStatusDisplayName('')
    }
  }, [projectStatus])

  return (
    <AppBar position="fixed" className="app-bar">
      {/* toolbar */}
      <Toolbar sx={{ justifyContent: 'space-between' }} className="app-toolbar">
        {/* logo */}
        <Stack
          direction="row"
          spacing={2}
          justifyContent="space-between"
          alignItems="center"
        >
          <Tooltip title={t('Go to home')} placement="top">
            <span className="logo-wrapper" onClick={goToHomePage}>
              <img src={caxLogo} alt="caxLogo" className="logo" />
            </span>
          </Tooltip>
          {!location.pathname.includes('home') && (
            <div className="icon-wrapper arrow-icon">
              <GoBackIcon fontSize="inherit" className="default-icon" />
              <ActiveGoBackIcon
                onClick={() => {
                  navigate(`${getLocalizedPath(currentLanguage, '/home')}`)
                }}
                fontSize="inherit"
                className="active-icon"
              />
            </div>
          )}
          <div style={{ display: 'flex', gap: '10px' }}>
            <div
              style={{
                color: 'var(--gray-900)',
                fontFamily: 'var(--theme-font)',
                textAlign: 'left',
                fontSize: '20px',
                fontWeight: '600',
                display: projectName ? '-webkit-box' : 'flex',
                WebkitLineClamp: 1, // Number of lines to show
                WebkitBoxOrient: 'vertical',
                overflow: 'hidden',
                textOverflow: 'ellipsis',
                alignItems: 'center',
                justifyContent: 'center',
              }}
            >
              {t('Document Collation App')}&nbsp;
              {window.location.pathname.includes('/user-management') && (
                <span> - {t('User Management')} </span>
              )}
              {window.location.pathname.includes('/project-setting') && (
                <span> - {t('Project Settings')} </span>
              )}
              {window.location.pathname.includes('/result') && (
                <span> - {t('Results')} </span>
              )}
              {window.location.pathname.includes('/projects') && (
                <span>
                  {projectName ? (
                    <>
                      -&nbsp;
                      <LightTooltip title={projectName}>
                        <span>{projectName}</span>
                      </LightTooltip>
                    </>
                  ) : (
                    // bouncing dots while projectName is not loaded
                    <span style={{ display: 'flex' }}>
                      -&nbsp;
                      <span className="bouncing-loader">
                        <div></div>
                        <div></div>
                        <div></div>
                      </span>
                    </span>
                  )}
                  {/* <TruncateText text={projectName} maxLength={50} /> */}
                </span>
              )}
            </div>
            {statusDisplayName && location.pathname?.includes('projects') && (
              <div
                style={{
                  color: 'var(--primary-600)',
                  background: 'var(--primary-200)',
                  padding: '4px 6px',
                  borderRadius: '3px',
                  fontWeight: 500,
                  minWidth: 'max-content',
                }}
              >
                {statusDisplayName}
              </div>
            )}
          </div>
        </Stack>
        <Stack
          direction="row"
          spacing={2}
          justifyContent="space-between"
          alignItems="center"
          className="menu"
          marginRight={2}
          marginLeft={6}
        >
          {/* <div>
            <img src={CTA} alt="caxLogo" className="logo" />
          </div> */}
          {isUserManagementAvailable &&
            userRole === 'admin' &&
            (location.pathname.includes('user-management') ? (
              <img
                src={activeMembersIcon}
                alt="members"
                className="members active-icon"
              />
            ) : (
              <Tooltip title={t('User management')} placement="top">
                <div className="icon-wrapper" onClick={goToUserManagementPage}>
                  <img
                    src={membersIcon}
                    alt="members"
                    className="members default-icon"
                  />
                  <img
                    src={activeMembersIcon}
                    alt="members"
                    className="members active-icon"
                  />
                </div>
              </Tooltip>
            ))}
          <Tooltip title={t('Logout')} placement="top">
            <div className="icon-wrapper" onClick={logout}>
              <img
                src={logoutIcon}
                alt="logout"
                className="logout default-icon"
                style={{
                  cursor: 'pointer',
                }}
              />
              <img
                src={activeLogoutIcon}
                alt="logout"
                className="logout active-icon"
                style={{
                  cursor: 'pointer',
                }}
              />
            </div>
          </Tooltip>
        </Stack>
      </Toolbar>
    </AppBar>
  )
}
