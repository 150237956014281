import React, { useContext, useEffect, useRef, useState } from 'react'
import { Spinner } from '../../../components/spinner/spinner'
import {
  Box,
  CircularProgress,
  FormControl,
  IconButton,
  Stack,
  TableCell,
  Tooltip,
} from '@mui/material'
import MultiUpload from '../../../components/file-upload/multiUpload'
import {
  Link,
  useLocation,
  useNavigate,
  useSearchParams,
} from 'react-router-dom'
import type { MRT_ColumnDef, MRT_RowData } from 'material-react-table'
import { ReactComponent as DeleteIcon } from '../../../assets/images/delete.svg'
import { ReactComponent as ActiveDeleteIcon } from '../../../assets/images/active_delete.svg'
import { ReactComponent as UploadIcon } from '../../../assets/images/dark_upload.svg'
import { ReactComponent as ActiveUploadIcon } from '../../../assets/images/active_upload.svg'
import { ReactComponent as DisableUploadIcon } from '../../../assets/images/disable_dark_upload.svg'
import { ReactComponent as DisableDeleteIcon } from '../../../assets/images/disable_delete.svg'
import { enqueueSnackbar } from 'notistack'
import TextButton from '../../../components/button/textButton'
import TableComponent from '../../../components/table/table'
import './uploadTarget.scss'
import {
  addTargetFile,
  deleteTargetById,
  finalizeTargetFiles,
  getTargetFiles,
} from '../../../services/collation-service'
import {
  ProjectContext,
  StatusContext,
  TargetContext,
  highlightsContext,
} from '../../../context/globalContext'
import CancelActionConformationFormDialog from '../../../components/form-dialog/cancel-conformation'
import FormDialog from '../../../components/form-dialog/formDialog'
import { SingleUpload } from '../../../components/file-upload/singleUpload'
import ByteConverter from '../../../components/file-upload/byteConverter'
import { replaceProjectAPI } from '../../project-setting/project-setting-api-serive'
import OutlineTextButton from '../../../components/button/textOutlineButton'
import DateFormatter from '../../../utils/dateFormatter'
import moment from 'moment'
import TruncateText from '../../../components/tooltip/truncate'
import { useTranslation } from 'react-i18next'
import { getLocalizedPath } from '../../../utils/shareLocalizePath'
import axios from 'axios'

interface Props {
  targetFiles
  setTargetFiles
  uploadProgress
  setUploadProgress
  isFinalizeButtonDisabled
  handleGetTargetFiles
  setTargetTitle
  isTargetDataLoading
  setIsTargetDataLoading
}

interface ProgressObject {
  filename: any
  progress: any
  updated: boolean
}

export default function UploadTarget({
  targetFiles,
  setTargetFiles,
  uploadProgress,
  setUploadProgress,
  isFinalizeButtonDisabled,
  handleGetTargetFiles,
  setTargetTitle,
  isTargetDataLoading,
  setIsTargetDataLoading,
}: Props) {
  const [showUploadProgress, setshowUploadProgress] = React.useState(false)
  const [files, setFiles] = useState<File | any>([])
  const [targetDocFile, setTargetDocFile] = useState<File | any>(null)

  const [uploadProgressReplace, setReplaceUploadProgress] = useState<any>(null)
  const [isReplaceProgressComplete, setIsReplaceProgressComplete] =
    useState<any>(true)
  const [showFinalizeButton, setShowFinalizeButton] = useState<any>(false)
  const [isMounted, setIsMounted] = useState<any>(true)
  const [noOfTargetFiles, setNoOfTargetFiles] = useState<any>(
    targetFiles?.length,
  )
  const fileUploadLimit = 30
  const controllerRef = useRef<AbortController | null>(null)
  const controllerRefReplace = useRef<AbortController | null>(null)
  // Cancel Replace
  const [isCancelReplaceConfirmDialogOpen, setCancelReplaceConfirmDialogOpen] =
    useState(false)
  const [
    isReplaceTargetDocumentDialogOpen,
    setOpenReplaceTargetDocumentDialog,
  ] = useState(false)
  const [isCancelUploadConfirmDialogOpen, setCancelUploadConfirmDialogOpen] =
    useState(false)
  const [isFileSizeValid, setFileSizeValidation] = useState<boolean>(true)
  const [isTargetLoading, setIsTargetLoading] = useState<boolean>(true)
  const [replaceTargetId, setTargetIdToReplace] = useState<any>()
  const [cancelResult, setCancelResult] = useState<string>()

  const { setTargetId } = useContext(TargetContext)
  const { projectId } = useContext(ProjectContext)
  const { targetFinalizeStatus, projectStatus } = useContext(StatusContext)
  const {
    setShowTargetPdfAndCollation,
    setCollationList,
    setPhraseId,
    isProgressComplete,
    setIsProgressComplete,
  } = useContext(highlightsContext)
  const navigate = useNavigate()
  const [queryParameters] = useSearchParams()
  const location = useLocation()
  const disableReplaceButton =
    !targetDocFile || uploadProgressReplace !== null || !isFileSizeValid

  const maxFileSize = 30 * 1024 * 1024
  const { t, i18n } = useTranslation()
  const currentLanguage = i18n.language
  const basePath = getLocalizedPath(currentLanguage, location.pathname)
  useEffect(() => {
    setIsMounted(true) // Set isMounted to true when component is mounted
    return () => {
      setIsMounted(false) // Set isMounted to false when component is unmounted
      if (controllerRef.current) {
        controllerRef.current.abort()
      }
      if (controllerRefReplace.current) {
        controllerRefReplace.current.abort()
      }
    }
  }, [])

  useEffect(() => {
    if (cancelResult === 'confirm') {
      handleFileUploadCancel()
    } else {
      setCancelUploadConfirmDialogOpen(false)
    }
  }, [cancelResult])

  useEffect(() => {
    if (targetFinalizeStatus !== null && targetFinalizeStatus !== '') {
      setShowFinalizeButton(!targetFinalizeStatus)
    }
  }, [targetFinalizeStatus])

  useEffect(() => {
    const newNoOfTargetFiles = fileUploadLimit - targetFiles.length
    setNoOfTargetFiles(newNoOfTargetFiles)
  }, [targetFiles.length])
  // Add `targetFiles` as a dependency to this useEffect.
  const handleGetTargetFileById = (id: any) => {}

  // Opens a "Backgrowd prcrss again message "
  const handleOpenReplaceConfirmDialog = () => {
    setCancelReplaceConfirmDialogOpen(true)
  }
  const handlCloseCancelReplaceConfirmDialog = (result: string) => {
    // setCancelResult(result) // Set the result received from the dialog
    if (result === 'confirm') {
      setCancelReplaceConfirmDialogOpen(false)
      // opens replace target document dialog
      setOpenReplaceTargetDocumentDialog(true)
    } else {
      setCancelReplaceConfirmDialogOpen(false)
    }
  }

  // Close Replace target dociment dialog

  const handleCloseReplaceTargetDocumentDialog = () => {
    setOpenReplaceTargetDocumentDialog(false)
  }

  // To set cancel conformation dialog box
  const handleOpenCancelUploadConfirmDialog = () => {
    setCancelUploadConfirmDialogOpen(true)
  }

  const handleTargetDocumentReplace = (
    e: React.ChangeEvent<HTMLInputElement>,
  ) => {
    const selectedFile = e.target.files?.[0] ? e.target.files[0] : null
    if (selectedFile) {
      setTargetDocFile(selectedFile)
      if (selectedFile && selectedFile.size < maxFileSize) {
        setFileSizeValidation(true)
      } else {
        setFileSizeValidation(false)
      }
    }
  }
  // To set file and project name through props & close dialog
  const replaceProject = (e: any) => {
    setIsProgressComplete(false)
    // Set up AbortController
    const controller = new AbortController()
    controllerRefReplace.current = controller
    var productFormData = new FormData()

    if (targetDocFile) {
      const onUploadProgressReplace = (progressEvent: any) => {
        const progress = Math.round(
          (progressEvent.loaded * 100) / progressEvent.total,
        )
        setReplaceUploadProgress(
          (prevProgress: { filename: string; progress: number }) => ({
            filename: targetDocFile.name,
            progress: Math.max(progress, prevProgress?.progress ?? 0),
          }),
        )
      }

      // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
      productFormData.append('target_document', targetDocFile)

      replaceProjectAPI(
        productFormData,
        projectId,
        replaceTargetId,
        onUploadProgressReplace,
        controller.signal,
      )
        .then((res) => {
          if (res.status === 200) {
            setTargetDocFile(null)
            setReplaceUploadProgress(null)
            setIsProgressComplete(true)
            handleGetTargetFiles(projectId)
            handleCloseReplaceTargetDocumentDialog()
            enqueueSnackbar(`${res.data.detail}`, {
              variant: 'success',
              persist: true,
            })
          }
        })
        .catch((error) => {
          handleCloseReplaceTargetDocumentDialog()
          setTargetDocFile(null)
          setReplaceUploadProgress(null)
          setFileSizeValidation(true)
          setIsProgressComplete(true)
          if (
            error?.response?.request?.status !== 500 &&
            error?.response?.request?.status !== 502
          ) {
            if (error?.response?.data?.detail)
              enqueueSnackbar(`  ${error?.response?.data?.detail}`, {
                variant: 'error',
                persist: true,
              })
          }
          if (error?.response?.request?.status === 502) {
            enqueueSnackbar(`Something went wrong`, {
              variant: 'error',
              persist: true,
            })
          }
        })
    }
  }

  const handlCloseCancelUploadConfirmDialog = (result: string) => {
    setCancelResult(result) // Set the result received from the dialog
    setCancelUploadConfirmDialogOpen(false)
  }

  const handleFileUploadCancel = () => {
    if (controllerRefReplace.current) {
      controllerRefReplace.current.abort()
      setCancelUploadConfirmDialogOpen(false)
      enqueueSnackbar(t(`Cancelled file upload`), {
        variant: 'error',
        persist: true,
      })
      setTargetDocFile(null)
      setReplaceUploadProgress(null)
    }
    handleCloseReplaceTargetDocumentDialog()
    // setIsCreateProjectOpen(false)
  }

  const formatDateToLocalTime = (utcDateString) => {
    // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
    const date = moment(utcDateString)
    const formattedDate = date.format('YYYY/MM/DD')
    const formattedTime = date.format('HH:mm')
    return `${formattedDate} ${formattedTime}`
  }

  // The columns definition should match the MRT_ColumnDef type
  const targetFileColumns: Array<MRT_ColumnDef<MRT_RowData>> = [
    {
      accessorKey: 'name',
      header: t('FILE NAME'),
      enableSorting: false,
      accessorFn: (row) => (
        <div
          style={{
            width: '100%',
            pointerEvents:
              projectStatus === 'COLLATION_IN_PROGRESS' ||
              projectStatus === 'PREPARING_FOR_COLLATION' ||
              projectStatus === 'INHERITING_COLLATIONS' ||
              !isProgressComplete ||
              targetFiles?.some(
                (file) =>
                  file.status !== 'uploaded' &&
                  file.status !== 'embedded' &&
                  file.status !== 'failed',
              )
                ? 'none'
                : 'auto',
          }}
        >
          <span>{row.status}</span>
          <Tooltip title={row.name}>
            <div
              className="target-name"
              onClick={() => {
                handleGetTargetFileById(row.id)
                if (
                  queryParameters.get('phrase_id') &&
                  queryParameters.get('area_id')
                ) {
                  navigate(
                    `${basePath}?project_id=${queryParameters.get('project_id')}&target_id=${row.id}&area_id=${queryParameters.get('area_id')}&phrase_id=${queryParameters.get('phrase_id')}`,
                    { state: { location }, replace: true },
                  )
                } else if (
                  !queryParameters.get('phrase_id') &&
                  queryParameters.get('area_id')
                ) {
                  navigate(
                    `${basePath}?project_id=${queryParameters.get('project_id')}&target_id=${row.id}&area_id=${queryParameters.get('area_id')}`,
                    { state: { location }, replace: true },
                  )
                  setCollationList(null)
                } else {
                  navigate(
                    `${basePath}?project_id=${queryParameters.get('project_id')}&target_id=${row.id}`,
                    { state: { location }, replace: true },
                  )
                  setTargetId(row.id)
                  setCollationList(null)
                }
                setTargetId(row.id)
                setShowTargetPdfAndCollation(true)
                setTargetTitle(row.name)
                queryParameters.get('phrase_id') &&
                  setPhraseId(queryParameters.get('phrase_id'))
              }}
            >
              {row.name}
            </div>
          </Tooltip>
          <div className="created-at">
            <DateFormatter
              dateString={formatDateToLocalTime(row.last_modified_at)}
            />
          </div>
        </div>
      ),
      // grow: true,
    },
    {
      accessorKey: 'matched_results',
      header: t('MATCHED RESULTS'),
      enableSorting: false,
      maxSize: 100,
      // grow: true,
      accessorFn: (row) => (
        <div style={{ border: 'none', width: '100%', textAlign: 'left' }}>
          <div>{row?.matched_results ? row?.matched_results : '-'}</div>
        </div>
      ),
    },
    {
      accessorKey: 'best_rank',
      header: t('BEST RANK'),
      maxSize: 100,
      enableSorting: false,
      // grow: true,
      accessorFn: (row) => (
        <div style={{ border: 'none', width: '100%', textAlign: 'left' }}>
          <div>{row?.best_rank ? row?.best_rank : '-'}</div>
        </div>
      ),
    },
    {
      accessorKey: 'actions',
      header: t('ACTIONS'),
      enableSorting: false,
      maxSize: 90,
      Cell: (e) => (
        <div>
          {projectStatus === 'COLLATION_IN_PROGRESS' ||
          projectStatus === 'PREPARING_FOR_COLLATION' ||
          projectStatus === 'INHERITING_COLLATIONS' ||
          !isProgressComplete ||
          targetFiles?.some(
            (file) =>
              file.status !== 'uploaded' &&
              file.status !== 'embedded' &&
              file.status !== 'failed',
          ) ? (
            <Box display={'flex'} justifyContent={'start'}>
              <DisableUploadIcon
                style={{
                  width: '26px',
                  height: '26px',
                  padding: '8px 8px 8px 0',
                }}
              />
              <DisableDeleteIcon
                style={{
                  width: '26px',
                  height: '26px',
                  padding: '8px 8px 8px 0',
                }}
              />
            </Box>
          ) : (
            <>
              <Box display={'flex'} justifyContent={'start'}>
                <Tooltip title={t('Update target document')}>
                  <IconButton
                    onClick={() => {
                      handleOpenReplaceConfirmDialog()
                      setTargetIdToReplace(e.row.original.id)
                    }}
                    sx={{
                      '&:hover': { backgroundColor: 'transparent' },
                      padding: '8px 8px 8px 0',
                    }}
                  >
                    <span className="icon-wrapper">
                      <UploadIcon
                        className="default-icon"
                        style={{
                          width: '26px',
                          height: '26px',
                        }}
                      />{' '}
                      <ActiveUploadIcon
                        className="active-icon"
                        style={{
                          width: '26px',
                          height: '26px',
                        }}
                      />
                    </span>
                  </IconButton>
                </Tooltip>
                {projectStatus !== 'COLLATION_VERIFICATION' &&
                projectStatus !== 'WAITING_FOR_COLLATION_APPROVAL' ? (
                  <Tooltip title={t('Delete file')}>
                    <IconButton
                      onClick={() => {
                        handleDeleteTargetFile(e.row.original.id)
                      }}
                      sx={{
                        '&:hover': { backgroundColor: 'transparent' },
                      }}
                    >
                      <span className="icon-wrapper">
                        <DeleteIcon
                          className="default-icon"
                          style={{
                            width: '26px',
                            height: '26px',
                          }}
                        />
                        <ActiveDeleteIcon
                          className="active-icon"
                          style={{
                            width: '26px',
                            height: '26px',
                          }}
                        />
                      </span>
                    </IconButton>
                  </Tooltip>
                ) : (
                  <IconButton
                    sx={{
                      '&:hover': { backgroundColor: 'transparent' },
                      cursor: 'auto',
                    }}
                  >
                    <span>
                      <DisableDeleteIcon
                        style={{
                          width: '26px',
                          height: '26px',
                        }}
                      />
                    </span>
                  </IconButton>
                )}
              </Box>
            </>
          )}
        </div>
      ),
    },
  ]
  // handle the delete target file
  const removeSelectedTargetFile = (name: any) => {
    setNoOfTargetFiles((prev) => prev + 1)
    const updatedSelectedTargetFiles = files.filter((row) => {
      return row.name !== name
    })
    setFiles(updatedSelectedTargetFiles)
  }
  // handle the delete target file
  const handleDeleteTargetFile = (id: any) => {
    setIsTargetDataLoading(true)
    deleteTargetById(projectId, id)
      .then((res) => {
        if (res.status === 200) {
          handleGetTargetFiles(projectId)
          setNoOfTargetFiles((preVal) => {
            return preVal - 1
          })
        }
      })
      .catch((error) => {
        setFiles([])
        setIsTargetDataLoading(false)
        // To handle cancel axios error
        if (axios.isCancel(error)) {
          console.debug('Request canceled:', error)
        } else if (
          error?.response?.data?.detail &&
          error?.response?.request?.status !== 500 &&
          error?.response?.request?.status !== 502
        ) {
          enqueueSnackbar(`  ${error?.response?.data?.detail}`, {
            variant: 'error',
            persist: true,
          })
        }
        if (error?.response?.request?.status === 502) {
          enqueueSnackbar(`Something went wrong`, {
            variant: 'error',
            persist: true,
          })
        }
      })
  }

  const handleFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const MAX_FILE_SIZE_MB = 30
    const MAX_FILE_SIZE_BYTES = MAX_FILE_SIZE_MB * 1024 * 1024

    // Convert selected files into an array and add an index to each file object
    const newFilesArray = Array.from(
      e.target.files ?? ([] as unknown as FileList),
    ).map((file, index) => Object.assign(file, { id: index }))

    // Filter out non-PDF files and notify the user if any are found
    const validFiles = newFilesArray.filter((file) => {
      const isPdf =
        file.type === 'application/pdf' || file.name.endsWith('.pdf')
      if (!isPdf) {
        enqueueSnackbar(
          t(`Only PDF files are allowed. "${file.name}" is not a PDF file.`),
          {
            variant: 'error',
            persist: true,
          },
        )
      }

      const isValidSize = file.size <= MAX_FILE_SIZE_BYTES
      if (!isValidSize) {
        enqueueSnackbar(
          t(
            `"${file.name}" exceeds the maximum allowed size of ${MAX_FILE_SIZE_MB} MB.`,
          ),
          {
            variant: 'error',
            persist: true,
          },
        )
      }

      return isPdf && isValidSize
    })

    // Check if the number of valid files exceeds the upload limit
    if (validFiles.length > fileUploadLimit) {
      enqueueSnackbar(
        t(
          `Only the first ${fileUploadLimit} files have been selected as the upload limit is ${fileUploadLimit} files at a time.`,
        ),
        {
          variant: 'error',
          persist: true,
        },
      )
    }

    const firstFilesUpToLimit = validFiles.slice(0, fileUploadLimit)

    setFiles((prevFiles: any) => [...prevFiles, ...firstFilesUpToLimit])
    setNoOfTargetFiles((preVal: any) => preVal - firstFilesUpToLimit.length)
    setUploadProgress([])
    setshowUploadProgress(true)
  }

  const MAX_CONCURRENT_UPLOADS = 2

  const fileUpload = () => {
    if (isMounted) {
      setIsProgressComplete(false)

      const progressObjects: ProgressObject[] = files.map((file) => ({
        filename: file.name,
        progress: 0,
        updated: false,
      }))

      let completedUploads = 0
      let activeUploads = 0
      const uploadQueue = [...files]

      const uploadNext = () => {
        if (uploadQueue.length === 0) {
          return
        }

        if (activeUploads >= MAX_CONCURRENT_UPLOADS) {
          return
        }

        const file = uploadQueue.shift()
        activeUploads++

        const i = files.indexOf(file)
        const targetFileFormData = new FormData()
        // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
        targetFileFormData.append('target_document', file)

        const onUploadProgress = (progressEvent: ProgressEvent) => {
          const progress = Math.round(
            (progressEvent.loaded / progressEvent.total) * 100,
          )
          progressObjects[i].progress = progress
          setUploadProgress([...progressObjects])
        }

        addTargetFile(
          projectId,
          targetFileFormData,
          onUploadProgress,
          controllerRef.current,
        )
          .then((response) => {
            activeUploads--
            if (response.status === 200) {
              completedUploads++
              progressObjects[i].updated = true
              setUploadProgress([...progressObjects])
              if (completedUploads === files.length) {
                const allProgressCompleted = progressObjects.every(
                  (obj) => obj.progress === 100,
                )
                if (allProgressCompleted) {
                  setIsProgressComplete(true)
                  setFiles([])
                  setUploadProgress([])
                  handleGetTargetFiles(projectId)
                }
              }
            }
            uploadNext()
          })
          .catch((error) => {
            activeUploads--
            handleGetTargetFiles(projectId)
            setFiles([])
            setUploadProgress([])
            setIsProgressComplete(true)
            setNoOfTargetFiles(30 - targetFiles?.length)
            if (
              error?.response?.data?.detail &&
              error?.response?.request?.status !== 413
            ) {
              enqueueSnackbar(`  ${error?.response?.data?.detail}`, {
                variant: 'error',
                persist: true,
              })
            } else if (error?.response?.request?.status === 413) {
              enqueueSnackbar(
                `${error?.response?.data?.detail ? error?.response?.data?.detail : 'Please check if the file size is as per specification'}`,
                {
                  variant: 'error',
                  persist: true,
                },
              )
            }
            uploadNext()
          })

        uploadNext()
      }

      for (let i = 0; i < MAX_CONCURRENT_UPLOADS; i++) {
        uploadNext()
      }
    }
  }

  const handleFinalizeTargetFiles = () => {
    finalizeTargetFiles(projectId)
      .then((res) => {
        setShowFinalizeButton(false)
        if (res.status === 200) {
          handleGetTargetFiles(projectId)
        }
      })
      .catch((error) => {
        setShowFinalizeButton(true)
        setFiles([])
        // To handle cancel axios error
        if (axios.isCancel(error)) {
          console.debug('Request canceled:', error)
        } else if (
          error?.response?.data?.detail &&
          error?.response?.request?.status !== 500 &&
          error?.response?.request?.status !== 502
        ) {
          enqueueSnackbar(`  ${error?.response?.data?.detail}`, {
            variant: 'error',
            persist: true,
          })
        }
        if (error?.response?.request?.status === 502) {
          enqueueSnackbar(`Something went wrong`, {
            variant: 'error',
            persist: true,
          })
        }
      })
  }

  return (
    <>
      {isMounted && (
        <div>
          {/* {isTargetDataLoading && <Spinner />} */}

          <Box
            className="pane2"
            overflow={'auto'}
            gap={2}
            height={'calc(100% - 72px)'}
            mt={9}
          >
            <div style={{ position: 'relative', height: '100%' }}>
              {/* Show upload section when target file is below 10 files */}
              <Stack
                direction="row"
                justifyContent="center"
                alignItems="center"
                spacing={{ xs: 1, sm: 2 }}
              >
                {/* Using Upload component to upload file */}
                <div className="upload-container">
                  <MultiUpload
                    files={files}
                    handleFileChange={handleFileChange}
                    selectedFiles={files}
                    setFiles={setFiles}
                    uploadProgress={uploadProgress}
                    isProgressComplete={isProgressComplete}
                    removeSelectedTargetFile={removeSelectedTargetFile}
                    fileUploadLimit={noOfTargetFiles}
                    targetFiles={targetFiles}
                    setNoOfTargetFiles={setNoOfTargetFiles}
                  />

                  {files.length > 0 && (
                    <TextButton
                      text={
                        isProgressComplete
                          ? t('Upload')
                          : uploadProgress.length > 0
                            ? t('Uploading files')
                            : t('Upload')
                      }
                      color="var(--gray-white)"
                      bgColor={
                        !isProgressComplete || uploadProgress.length > 0
                          ? 'var(--primary-400)'
                          : 'var(--primary-600)'
                      }
                      onClick={fileUpload}
                      style={{ width: '96%', margin: '0 0 16px 0' }}
                      disabled={
                        !isProgressComplete || uploadProgress.length > 0
                      }
                    />
                  )}
                </div>
              </Stack>

              {/* Data grid to contain Target files */}
              {targetFiles?.length > 0 && (
                <Box
                  className="table-container"
                  sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    gap: 2,
                    marginTop: '16px !important',
                    position: 'relative',
                    height: '100%',
                    marginBottom: '4rem !important',
                  }}
                >
                  {isTargetDataLoading ? (
                    <div style={{ padding: '15rem' }}>
                      <CircularProgress />
                    </div>
                  ) : (
                    <TableComponent
                      rows={targetFiles}
                      columns={targetFileColumns}
                      isSort={false}
                      // isPagination={false}
                      enableResize={false}
                      enableBottomTool={false}
                      sorting={[]}
                      hideBorder={true}
                    />
                  )}
                </Box>
              )}
              {targetFiles.length > 0 && showFinalizeButton && (
                <Box
                  className="webkit-fill-available moz-available fill-available"
                  sx={{
                    display: 'flex',
                    justifyContent: 'center', // This centers the button horizontally
                    padding: '10px 20px', // Adjust padding as needed
                    borderTop: '1px solid #e0e0e0', // Add a top border or other styling as needed
                    position: 'fixed',
                    width: '100%',
                    bottom: '0px',
                    background: '#fff',
                  }}
                >
                  <TextButton
                    text={t('Finalize Target Documents')}
                    color="var(--gray-white)"
                    bgColor={
                      isFinalizeButtonDisabled
                        ? 'var(--primary-400)'
                        : 'var(--primary-600)'
                    }
                    style={{
                      cursor: isFinalizeButtonDisabled ? 'none' : 'pointer',
                      pointerEvents: isFinalizeButtonDisabled ? 'none' : 'auto',
                      width: '100%',
                    }}
                    onClick={handleFinalizeTargetFiles}
                    disabled={isFinalizeButtonDisabled}
                  />
                </Box>
              )}
            </div>
          </Box>
        </div>
      )}

      <FormDialog
        open={isReplaceTargetDocumentDialogOpen}
        title={t('Replace Target Document')}
        onClose={() => {
          uploadProgressReplace >= 0
            ? handleCloseReplaceTargetDocumentDialog()
            : handleOpenCancelUploadConfirmDialog()
        }}
        setFile={setTargetDocFile}
        setUploadProgress={setReplaceUploadProgress}
      >
        <Stack display={'flex'} direction={'column'} gap={2}>
          {/* Dialog ProjectName input field */}

          <FormControl variant="standard">
            {/* Dialog Upload Part using SingleUpload component */}
            <SingleUpload
              setFile={setTargetDocFile}
              file={targetDocFile}
              handleFileChange={handleTargetDocumentReplace}
              uploadProgress={uploadProgressReplace}
              isProgressComplete={isProgressComplete}
              placeholder={t('Add a Target Document')}
              documentTitle={t('Target Document')}
              buttonName={t('Choose')}
              color={'var(--gray-white)'}
              bgColor={
                uploadProgressReplace && !isProgressComplete
                  ? 'var(--primary-300)'
                  : 'var(--primary-600)'
              }
              style={{
                borderTopLeftRadius: 0,
                borderBottomLeftRadius: 0,
                borderTopRightRadius: '8px',
                borderBottomRightRadius: '8px',
                marginTop: '25px',
                paddingTop: '9px',
                paddingBottom: '8px',
                minWidth: 'fit-content',
              }}
            />
            <div className="file-size-error">
              {!isFileSizeValid && <>{t('File exceeds maximum limit')}</>}
            </div>
            <div className="selected-file-size">
              {targetDocFile && uploadProgressReplace >= 0 && (
                <ByteConverter bytes={targetDocFile?.size} decimal={2} />
              )}
            </div>
          </FormControl>
          <div
            style={{
              display: 'flex',
              justifyContent: 'flex-end',
            }}
          >
            {uploadProgressReplace <= 0 ? (
              <TextButton
                text={t('Replace')}
                color={'#fff'}
                bgColor={
                  disableReplaceButton
                    ? 'var(--primary-300)' // Use primary-300 for the disable color
                    : 'var(--primary-600)'
                }
                disabled={disableReplaceButton}
                onClick={replaceProject}
                style={{
                  width: '100%',
                  fontSize: '14px',
                  textTransform: 'capitalize',
                  boxShadow: 'none',
                  padding: '8px 0',
                }}
              />
            ) : (
              <OutlineTextButton
                text={t('Cancel')}
                bgColor={'#fff'}
                color={'var(--primary-600)'}
                outlineColor={'var(--primary-600)'}
                // bgColor={
                //   disableInviteButton
                //     ? 'var(--primary-300)' // Use primary-300 for the disable color
                //     : 'var(--primary-600)'
                // }
                // disabled={disableInviteButton}
                // onClick={onInviteClick}
                style={{
                  width: '100%',
                  height: '42px',
                  fontSize: '14px',
                  fontWeight: '500',
                }}
                onClick={handleOpenCancelUploadConfirmDialog}
                // onClick={handleFileUploadCancel}
              />
            )}
          </div>
        </Stack>
      </FormDialog>

      {/*  On cancel of upload file */}
      <CancelActionConformationFormDialog
        open={isCancelUploadConfirmDialogOpen}
        cancelbtnText={t('No')}
        onClose={handlCloseCancelUploadConfirmDialog}
        message={t('Your progress will be lost, Are you sure?')}
      ></CancelActionConformationFormDialog>

      <CancelActionConformationFormDialog
        open={isCancelReplaceConfirmDialogOpen}
        onClose={handlCloseCancelReplaceConfirmDialog}
        message={t(
          'Replacing target document is a long process. We should start background process again. Are you sure?',
        )}
      ></CancelActionConformationFormDialog>
    </>
  )
}
