import { IconButton, styled } from '@mui/material'
import {
  SnackbarProvider as Snackbar,
  MaterialDesignContent,
  closeSnackbar,
  type SnackbarKey,
} from 'notistack'
import { ReactComponent as XIcon } from '../../assets/images/x.svg'
import { useEffect, useImperativeHandle, useRef } from 'react'
export const SnackbarProvider = ({ children }: any) => {
  const MAX_SNACK = 1 // maximum number of children to show when showing Snackbar
  const POSITION: any = {
    // position of the snackbar
    vertical: 'top',
    horizontal: 'center',
  }
  const TRANSITION_DURATION: any = {
    enter: 300,
    exit: 500,
  }
  const StyledMaterialDesignContent = styled(MaterialDesignContent)(() => ({
    '&.notistack-MuiContent-success': {
      backgroundColor: 'var(--green-50)',
      color: 'var(--green-600)',
      font: '500 14px var(--theme-font)',
      boxShadow: 'none',
      flexWrap: 'nowrap',
      display: 'flex',
      justifyContent: 'flex-start',
      alignItems: 'baseline',
      textAlign: 'left',
    },

    '&.notistack-MuiContent-error': {
      backgroundColor: 'var(--red-50)',
      color: 'var(--red-600)',
      font: '500 14px var(--theme-font)',
      boxShadow: 'none',
      flexWrap: 'nowrap',
      display: 'flex',
      justifyContent: 'flex-start',
      alignItems: 'baseline',
      textAlign: 'left',
    },
    '&.notistack-MuiContent-info': {
      backgroundColor: 'var(--primary-600)',
      color: 'var(--gray-white)',
      font: '500 14px var(--theme-font)',
      boxShadow: 'none',
      flexWrap: 'nowrap',
      display: 'flex',
      justifyContent: 'flex-start',
      alignItems: 'baseline',
      textAlign: 'left',
    },
  }))

  const action = (snackbarId: SnackbarKey) => (
    <XIcon
      className="global-close-btn"
      onClick={() => {
        closeSnackbar(snackbarId)
      }}
    />
  )
  const notistackRef = useRef<any>(null)

  const handleClickCapture = (event: MouseEvent) => {
    if (notistackRef.current) {
      notistackRef.current.closeSnackbar()
    }
  }

  useEffect(() => {
    document.addEventListener('click', handleClickCapture, true)

    return () => {
      document.removeEventListener('click', handleClickCapture, true)
    }
  }, [])
  return (
    <div className="snack-bar-container">
      <Snackbar
        ref={notistackRef}
        maxSnack={MAX_SNACK}
        anchorOrigin={POSITION}
        transitionDuration={TRANSITION_DURATION}
        action={action}
        Components={{
          success: StyledMaterialDesignContent,
          error: StyledMaterialDesignContent,
          info: StyledMaterialDesignContent,
        }}
      >
        {children}
      </Snackbar>
    </div>
  )
}
