/* eslint-disable @typescript-eslint/no-unsafe-argument */
// import './upload-target-docs.scss'
import React, {
  useEffect,
  useState,
  useRef,
  useContext,
  useCallback,
} from 'react'
import {
  Badge,
  Box,
  CircularProgress,
  Divider,
  IconButton,
  LinearProgress,
  Menu,
  MenuItem,
  Popover,
  Stack,
  Tooltip,
  Typography,
} from '@mui/material'
import { SourcePdf } from '../../components/pdf-highlighter/sourcepdf'
import {
  ProjectContext,
  StatusContext,
  TargetContext,
  highlightsContext,
  layoutContext,
} from '../../context/globalContext'
import { SourcePhrases } from './source-phrases/sourcePhrases'
import { ReactComponent as CloseToogleIcon } from '../../assets/images/left_arrow.svg'
import { ReactComponent as ActiveCloseToogleIcon } from '../../assets/images/active_left_arrow.svg'
import { ReactComponent as OpenToogleIcon } from '../../assets/images/right_arrow.svg'
import { ReactComponent as ActiveOpenToogleIcon } from '../../assets/images/active_right_arrow.svg'
import { ReactComponent as DisabledCloseToogleIcon } from '../../assets/images/disabled_left_arrow.svg'
import { ReactComponent as DisabledOpenToogleIcon } from '../../assets/images/disable_right_arrow.svg'
import { ReactComponent as GoBackIcon } from '../../assets/images/goback_arrow.svg'
import { ReactComponent as ActiveGoBackIcon } from '../../assets/images/active_goback_arrow.svg'
import { ReactComponent as DisableGoBackIcon } from '../../assets/images/disable_go_back.svg'
import { ReactComponent as InfoIcon } from '../../assets/images/info_circle.svg'
import { ReactComponent as ActiveInfoIcon } from '../../assets/images/active_info_circle.svg'
import { ReactComponent as FilledInfoIcon } from '../../assets/images/info_filled.svg'
import './uploadSearchCollation.scss'
// import * as SASH from 'allotment/dist/types/src/sash'
import { Panel, PanelGroup, PanelResizeHandle } from 'react-resizable-panels'
import UploadTarget from './upload-target/uploadtarget'
import { TargetPdf } from '../../components/pdf-highlighter/targetpdf'
import { Collations } from './collations/collations'
import {
  Link,
  useLocation,
  useNavigate,
  useSearchParams,
} from 'react-router-dom'
import {
  getProjectById,
  getTargetById,
  getTargetFiles,
} from '../../services/collation-service'
import { enqueueSnackbar } from 'notistack'
import { useTranslation } from 'react-i18next'
import { getLocalizedPath } from '../../utils/shareLocalizePath'
import axios from 'axios'
import debounce from 'lodash.debounce'

type LayoutState = [number[], boolean, boolean, boolean, boolean]

const UploadSearchCollation = (props: any) => {
  const [sourceTitle, setSourceTitle] = useState<string>('')
  const [targetTitle, setTargetTitle] = useState<string>('')
  const [targetFiles, setTargetFiles] = useState<File | any>([])
  const [uploadProgress, setUploadProgress] = useState<any>([])

  const [hideSourceControls, setHideSourceControls] = useState<boolean | any>(
    false,
  )
  const [isTarget, setIstarget] = useState<boolean | any>(false)
  const [isFinalizeButtonDisabled, setIsFinalizeButtonDisabled] = useState(true)
  const {
    areaList,
    roiList,
    isAreaDataLoading,
    isROIDataLoading,
    highlightList,
    handleGetAreaList,
    handleGetROIs,
    showTargetPdfAndCollation,
    setShowTargetPdfAndCollation,
    collationList,
    isManualCollationInProgress,
    setCollationList,
    isNewAreaAdded,
    isNewRoiAdded,
    isAreaSearched,
    reqireCallGetTarget,
    setReqireCallGetTarget,
    isProgressComplete,
  } = useContext(highlightsContext)
  // get layout context
  const { layout, updateLayout } = useContext(layoutContext)
  const { projectId, updateProjectName } = useContext(ProjectContext)
  const { targetId } = useContext(TargetContext)
  const [isTargetExpanded, setIsTargetExpanded] = useState(false)
  const [isROIExpanded, setIsROIExpanded] = useState(false)
  const { projectStatus, targetDocumentStatus, setProjectStatus } =
    useContext(StatusContext)

  const [showPhrasePane, setShowPhrasePane] = useState<boolean>(
    areaList?.length > 0 || roiList?.length > 0 || false,
  )
  const [hideTarget, setHideTarget] = useState<boolean | any>(false)
  const [hideSource, setHideSource] = useState<boolean | any>(false)
  const [sourceCount, setSourceCount] = useState<any>(0)
  const [hideSourcePhrase, setHideSourcePhrase] = useState<boolean | any>(false)
  const [hideCollationPhrase, setHideCollationPhrase] = useState<boolean | any>(
    false,
  )
  const [isTargetDataLoading, setIsTargetDataLoading] = useState(true)

  const [currentPage, setCurrentPage] = useState<any>(0)
  const [projectData, setProjectData] = useState<any>(null)
  const [defaultLayout, setDefaultLayout] = useState<any>([])
  const [selectedResultId, setSelectedResultId] = useState<any>(null)
  const myRef = useRef<any>(null)
  const sourcePhrasePaneRef = useRef<any>(null)
  const sourcePaneRef = useRef<any>(null)
  const targetPaneRef = useRef<any>(null)
  const isMounted = useRef<any>(false)
  const collationsPaneRef = useRef<any>(null)
  const layoutTypeObject = localStorage.getItem('layoutTypeObject')
  const [queryParameters] = useSearchParams()
  const location = useLocation()
  const navigate = useNavigate()
  const phraseRef: any = useRef(null)
  const { t, i18n } = useTranslation()

  const currentLanguage = i18n.language
  const basePath = getLocalizedPath(currentLanguage, location.pathname)
  // To decide on first load should we open app in 4 panels layout or 3 panels layout
  useEffect(() => {
    isMounted.current = true
    // Directly extract projectId from URL search params
    const searchParams = new URLSearchParams(location.search)

    const targetIdFromUrl = searchParams.get('target_id')
    if (targetIdFromUrl) {
      setShowTargetPdfAndCollation(true)
    } else {
      setShowTargetPdfAndCollation(false)
    }

    return () => {
      isMounted.current = false // Cleanup function to set isMounted to false
    }
  }, [location.search, queryParameters, projectId]) // React to changes in the URL's search part
  // setting Default panels Layout when areaLoading and roiLoading update to false
  useEffect(() => {
    if (!isAreaDataLoading && !isROIDataLoading) {
      setDefaultLayout(getDefaultLayout())
    }
  }, [isAreaDataLoading, isROIDataLoading])
  // When project status is changed from progress or preparing or inheriting to some other status then call targetFiles list api
  useEffect(() => {
    if (reqireCallGetTarget && projectId && queryParameters.get('phrase_id')) {
      handleGetTargetFiles(projectId, 'poll', queryParameters.get('phrase_id'))
      setReqireCallGetTarget(false)
    }
  }, [reqireCallGetTarget])

  useEffect(() => {
    if (projectId) {
      // Assuming these functions handle fetching data based on projectId
      handleGetProjectById(projectId)
      if (queryParameters.get('phrase_id')) {
        handleGetTargetFiles(projectId, '', queryParameters.get('phrase_id'))
      } else {
        handleGetTargetFiles(projectId)
      }
      handleGetAreaList(projectId)
      handleGetROIs(projectId)
    }
  }, [projectId])
  // To set sourcePhrases count
  useEffect(() => {
    if (areaList?.length > 0) {
      const totalCount = areaList?.reduce(
        (acc, item) => acc + Number(item.source_phrase_count),
        0,
      )
      setSourceCount(totalCount)
    }
  }, [areaList])

  useEffect(() => {
    if (isAreaDataLoading && isROIDataLoading) {
      setDefaultLayout(getDefaultLayout())
      storeLayoutAndVisibilityTOLS(layout[0])
    }
  }, [isNewAreaAdded, isNewRoiAdded])

  useEffect(() => {
    let statusInterval

    const handlePolling = () => {
      if (
        targetFiles?.length > 0 &&
        targetFiles?.every(
          (file) => file.status === 'embedded' || file.status === 'failed',
        )
      ) {
        // If status is 'DONE' or 'EMBEDDED', stop polling
        if (queryParameters.get('phrase_id')) {
          handleGetTargetFiles(
            projectId,
            'poll',
            queryParameters.get('phrase_id'),
          )
        } else {
          handleGetTargetFiles(projectId, 'poll') // Or however you fetch/update target documents
        }
        clearInterval(statusInterval)
      } else {
        // Otherwise, continue or start polling
        if (!statusInterval) {
          statusInterval = setInterval(() => {
            if (queryParameters.get('phrase_id')) {
              handleGetTargetFiles(
                projectId,
                'poll',
                queryParameters.get('phrase_id'),
              )
            } else {
              handleGetTargetFiles(projectId, 'poll') // Or however you fetch/update target documents
            }
          }, 5000) // Adjust the interval time as needed
        }
      }
    }

    if (
      targetFiles?.some(
        (file) => file.status !== 'embedded' && file.status !== 'failed',
      )
    ) {
      handlePolling() // Start polling on component mount or statfalseus change
    }

    return () => {
      // Clear interval on component unmount or when targetDocumentStatus changes
      if (statusInterval) {
        clearInterval(statusInterval)
      }
    }
  }, [targetDocumentStatus, projectStatus, projectId, location]) // Add handleGetTargetFiles if it's a prop or state

  // This effect handles the visibility and layout adjustments for the search phrase pane based on search highlights
  useEffect(() => {
    if (layout[0] && isNewAreaAdded && isNewRoiAdded) {
      if (showTargetPdfAndCollation) {
        if (areaList.length > 0 || roiList.length > 0 || isAreaSearched) {
          if (
            layout[0][3] === 0 ||
            (layout[0][3] === 2 && !hideCollationPhrase)
          ) {
            setShowPhrasePane(true)
            setHideSourcePhrase(false)
            setHideSource(false)
            setHideTarget(false)
            setHideCollationPhrase(false)
            myRef.current?.setLayout([20, 30, 30, 20])
          } else {
            // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
            setShowPhrasePane(true)
            setHideSourcePhrase(layout[1])
            setHideSource(layout[2])
            setHideTarget(layout[3])

            layout[0][3] > 2
              ? setHideCollationPhrase(false)
              : setHideCollationPhrase(true)
            myRef.current?.setLayout(layout[0])
          }
        } else {
          // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
          setShowPhrasePane(true)
          setHideSourcePhrase(layout[1])
          setHideSource(layout[2])
          setHideTarget(layout[3])
          setHideCollationPhrase(layout[4])
          myRef.current?.setLayout(layout[0])
        }
      } else {
        if (areaList.length > 0 || roiList.length > 0 || isAreaSearched) {
          if (layout[0][0] === 0 || (layout[0][0] === 2 && !hideSourcePhrase)) {
            setShowPhrasePane(true)
            setHideSourcePhrase(false)
            setHideSource(false)
            setHideTarget(false)
            setHideCollationPhrase(true)
            myRef.current?.setLayout([20, 40, 40, 0])
          } else {
            // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
            setShowPhrasePane(true)
            setHideSourcePhrase(layout[1])
            setHideSource(layout[2])
            setHideTarget(layout[3])
            setHideCollationPhrase(layout[4])
            myRef.current?.setLayout(layout[0])
          }
        } else {
          setShowPhrasePane(false)
          setHideSourcePhrase(layout[1])
          setHideSource(layout[2])
          setHideTarget(layout[3])
          setHideCollationPhrase(layout[4])
          myRef.current?.setLayout(layout[0])
        }
      }
    }
  }, [areaList, showTargetPdfAndCollation, roiList, collationList])

  // This effect manages the state of the finalize button based on the presence of target files and the upload progress
  useEffect(() => {
    // Enable the finalize button only when there are target files and no ongoing uploads
    setIsFinalizeButtonDisabled(
      !(targetFiles?.length > 0 && uploadProgress?.length === 0),
    )
  }, [targetFiles, uploadProgress])

  // This effect manages the state of the finalize button based on the presence of target files and the upload progress
  useEffect(() => {
    storeLayoutAndVisibilityTOLS(layout[0])
  }, [hideSourcePhrase, hideSource, hideTarget, hideCollationPhrase])

  const handleGetProjectById = (id) => {
    getProjectById(id)
      .then((res) => {
        if (res) {
          setProjectStatus(res.data.data.status.id)
          setProjectData(res.data.data)
          setSourceTitle(res.data.data.source_document.name as string)
          updateProjectName(res.data.data.name as string)
        }
      })
      .catch((error) => {
        // To handle cancel axios error
        if (axios.isCancel(error)) {
          console.debug('Request canceled:', error)
        } else if (
          error?.response?.request?.status !== 500 &&
          error?.response?.request?.status !== 502
        ) {
          enqueueSnackbar(`${error?.response?.data?.detail}`, {
            variant: 'error',
            persist: true,
          })
        }
        if (error?.response?.request?.status === 404) {
          navigate(`${getLocalizedPath(currentLanguage, '/home')}`)
        }
        if (error?.response?.request?.status === 502) {
          enqueueSnackbar(`Something went wrong`, {
            variant: 'error',
            persist: true,
          })
        }
      })
  }
  // function call to get target files API to get the uploaded targetheart files

  const handleGetTargetFiles = (id, str?: any, phraseId?: any) => {
    // If this function called due to polling then setIsTargetDataLoading should be changed based on length of targetFiles list
    if (str && str === 'poll') {
      targetFiles?.length > 0
        ? setIsTargetDataLoading(false)
        : setIsTargetDataLoading(true)
    } else {
      setIsTargetDataLoading(true)
    }

    // Pass the phraseId to the getTargetFiles function
    getTargetFiles(id, phraseId)
      .then((res) => {
        if (res.status === 200) {
          setTargetFiles(res.data.data)
          const searchParams = new URLSearchParams(location.search)
          const targetIdFromUrl = searchParams.get('target_id')
          res.data.data.forEach((file) => {
            if (targetIdFromUrl && file.id === +targetIdFromUrl) {
              setTargetTitle(file.name as string)
            }
          })
          setIsTargetDataLoading(false)
        }
      })
      .catch((error) => {
        setIsTargetDataLoading(false)
        // To handle cancel axios error
        if (axios.isCancel(error)) {
          console.debug('Request canceled:', error)
        } else if (
          error?.response?.data?.detail &&
          error?.response?.request?.status !== 500 &&
          error?.response?.request?.status !== 502
        ) {
          enqueueSnackbar(`${error?.response?.data?.detail}`, {
            variant: 'error',
            persist: true,
          })
        } else if (error?.response?.request?.status === 404) {
          navigate(`${getLocalizedPath(currentLanguage, '/home')}`)
        } else if (error?.response?.request?.status === 502) {
          enqueueSnackbar(`Something went wrong`, {
            variant: 'error',
            persist: true,
          })
        }
      })
  }

  const updateLayoutAndVisibility = ([
    layout,
    hideSourcePhrase,
    hideSource,
    hideTarget,
    hideCollationPhrase,
  ]) => {
    myRef.current?.setLayout(layout)
    setHideSourcePhrase(hideSourcePhrase)
    setHideSource(hideSource)
    setHideTarget(hideTarget)
    setHideCollationPhrase(hideCollationPhrase)
    // storeLayoutAndVisibilityTOLS(layout)
  }
  const storeLayoutAndVisibilityTOLS = (layout1?: any) => {
    const layoutArray: LayoutState = [
      layout1,
      hideSourcePhrase,
      hideSource,
      hideTarget,
      hideCollationPhrase,
    ]

    updateLayout(layoutArray)
    localStorage.setItem(
      'layoutTypeObject',
      JSON.stringify([
        layout1,
        hideSourcePhrase,
        hideSource,
        hideTarget,
        hideCollationPhrase,
      ]),
    )
  }
  /* 
  [20,30,30,20] [  ][   ][   ][  ]

  [20,2,58,20]  [  ][][      ][  ]

  [20,2,58,2]   [  ][][        ][]

  [20,58,2,20]  [  ][      ][][  ]

  [20,39,39,2]  [  ][    ][    ][]

  [20,76,2,2]   [  ][        ][][]

  [2,39,39,20]  [][    ][    ][  ]

  [2,2,76,20]   [][][        ][  ]

  [2,76,2,20]   [][        ][][  ]

  [2,48,48,2]   [][        ][][  ]

  [2,2,94,2]    [][][          ][]

  [2,94,2,2]    [][          ][][]

  */

  // These conditions are to handle expand and collapse of each panel to fulfill above scenerios
  const handleToggle = (from) => {
    if (isMounted.current) {
      // Initial checks and setup
      const isSourcePhraseCollapsed =
        sourcePhrasePaneRef?.current?.isCollapsed()
      const isSourceCollapsed = sourcePaneRef?.current?.isCollapsed()
      const isTargetCollapsed = targetPaneRef?.current?.isCollapsed()
      const isCollationCollapsed = collationsPaneRef?.current?.isCollapsed()

      // Determining the layout and visibility based on the 'from' parameter and pane states
      if (showTargetPdfAndCollation) {
        if (from === 'search_phrase') {
          if (isSourcePhraseCollapsed) {
            // Expanding search phrase scenarios
            if (
              !isSourceCollapsed &&
              !isTargetCollapsed &&
              !isCollationCollapsed
            ) {
              updateLayoutAndVisibility([
                [20, 30, 30, 20],
                false,
                false,
                false,
                false,
              ])
            } else if (
              !isSourceCollapsed &&
              !isTargetCollapsed &&
              isCollationCollapsed
            ) {
              updateLayoutAndVisibility([
                [20, 39, 39, 2],
                false,
                false,
                false,
                true,
              ])
            } else if (
              !isSourceCollapsed &&
              isTargetCollapsed &&
              !isCollationCollapsed
            ) {
              updateLayoutAndVisibility([
                [20, 58, 2, 20],
                false,
                false,
                true,
                false,
              ])
            } else if (
              !isSourceCollapsed &&
              isTargetCollapsed &&
              isCollationCollapsed
            ) {
              updateLayoutAndVisibility([
                [20, 76, 2, 2],
                false,
                false,
                true,
                true,
              ])
            } else if (
              isSourceCollapsed &&
              !isTargetCollapsed &&
              isCollationCollapsed
            ) {
              updateLayoutAndVisibility([
                [20, 2, 76, 2],
                false,
                true,
                false,
                true,
              ])
            } else if (
              isSourceCollapsed &&
              !isTargetCollapsed &&
              !isCollationCollapsed
            ) {
              updateLayoutAndVisibility([
                [20, 2, 58, 20],
                false,
                true,
                false,
                false,
              ])
            }
          } else {
            // Collapsing search phrase scenarios
            if (
              !isSourceCollapsed &&
              !isTargetCollapsed &&
              !isCollationCollapsed
            ) {
              updateLayoutAndVisibility([
                [2, 39, 39, 20],
                true,
                false,
                false,
                false,
              ])
            } else if (
              !isSourceCollapsed &&
              !isTargetCollapsed &&
              isCollationCollapsed
            ) {
              updateLayoutAndVisibility([
                [2, 48, 48, 2],
                true,
                false,
                false,
                true,
              ])
            } else if (
              !isSourceCollapsed &&
              isTargetCollapsed &&
              !isCollationCollapsed
            ) {
              updateLayoutAndVisibility([
                [2, 76, 2, 20],
                true,
                false,
                true,
                false,
              ])
            } else if (
              !isSourceCollapsed &&
              isTargetCollapsed &&
              isCollationCollapsed
            ) {
              updateLayoutAndVisibility([
                [2, 94, 2, 2],
                true,
                false,
                true,
                true,
              ])
            } else if (
              isSourceCollapsed &&
              !isTargetCollapsed &&
              isCollationCollapsed
            ) {
              updateLayoutAndVisibility([
                [2, 2, 94, 2],
                true,
                true,
                false,
                true,
              ])
            } else if (
              isSourceCollapsed &&
              !isTargetCollapsed &&
              !isCollationCollapsed
            ) {
              updateLayoutAndVisibility([
                [2, 2, 76, 20],
                true,
                true,
                false,
                false,
              ])
            }
          }
        } else if (from === 'source') {
          if (isSourceCollapsed) {
            // Expanding source scenarios
            if (
              !isSourcePhraseCollapsed &&
              !isTargetCollapsed &&
              !isCollationCollapsed
            ) {
              sourcePaneRef?.current?.expand()
              updateLayoutAndVisibility([
                [20, 30, 30, 20],
                false,
                false,
                false,
                false,
              ])
            } else if (
              !isSourcePhraseCollapsed &&
              !isTargetCollapsed &&
              isCollationCollapsed
            ) {
              sourcePaneRef?.current?.expand()
              updateLayoutAndVisibility([
                [20, 39, 39, 2],
                false,
                false,
                false,
                true,
              ])
            } else if (
              !isSourcePhraseCollapsed &&
              isTargetCollapsed &&
              !isCollationCollapsed
            ) {
              sourcePaneRef?.current?.expand()
              updateLayoutAndVisibility([
                [20, 58, 2, 20],
                false,
                false,
                true,
                false,
              ])
            } else if (
              !isSourcePhraseCollapsed &&
              isTargetCollapsed &&
              isCollationCollapsed
            ) {
              sourcePaneRef?.current?.expand()
              updateLayoutAndVisibility([
                [20, 76, 2, 2],
                false,
                false,
                true,
                true,
              ])
            } else if (
              isSourcePhraseCollapsed &&
              !isTargetCollapsed &&
              isCollationCollapsed
            ) {
              sourcePaneRef?.current?.expand()
              updateLayoutAndVisibility([
                [2, 48, 48, 2],
                true,
                false,
                false,
                true,
              ])
            } else if (
              isSourcePhraseCollapsed &&
              !isTargetCollapsed &&
              !isCollationCollapsed
            ) {
              sourcePaneRef?.current?.expand()
              updateLayoutAndVisibility([
                [2, 39, 39, 20],
                true,
                false,
                false,
                false,
              ])
            } else if (
              isSourcePhraseCollapsed &&
              isTargetCollapsed &&
              isCollationCollapsed
            ) {
              sourcePaneRef?.current?.expand()
              updateLayoutAndVisibility([
                [2, 94, 2, 2],
                true,
                false,
                true,
                true,
              ])
            } else if (
              isSourcePhraseCollapsed &&
              isTargetCollapsed &&
              !isCollationCollapsed
            ) {
              sourcePaneRef?.current?.expand()
              updateLayoutAndVisibility([
                [2, 76, 2, 20],
                true,
                false,
                true,
                false,
              ])
            }
          } else {
            // Collapsing source scenarios
            if (
              !isSourcePhraseCollapsed &&
              !isTargetCollapsed &&
              !isCollationCollapsed
            ) {
              sourcePaneRef?.current?.collapse()
              updateLayoutAndVisibility([
                [20, 2, 58, 20],
                false,
                true,
                false,
                false,
              ])
            } else if (
              !isSourcePhraseCollapsed &&
              !isTargetCollapsed &&
              isCollationCollapsed
            ) {
              sourcePaneRef?.current?.collapse()
              updateLayoutAndVisibility([
                [20, 2, 76, 2],
                false,
                true,
                false,
                true,
              ])
            } else if (
              isSourcePhraseCollapsed &&
              !isTargetCollapsed &&
              isCollationCollapsed
            ) {
              sourcePaneRef?.current?.collapse()
              updateLayoutAndVisibility([
                [2, 2, 94, 2],
                true,
                true,
                false,
                true,
              ])
            } else if (
              isSourcePhraseCollapsed &&
              !isTargetCollapsed &&
              !isCollationCollapsed
            ) {
              sourcePaneRef?.current?.collapse()
              updateLayoutAndVisibility([
                [2, 2, 76, 20],
                true,
                true,
                false,
                false,
              ])
            }
          }
        } else if (from === 'target') {
          if (isTargetCollapsed) {
            // Expanding target scenarios
            if (
              !isSourcePhraseCollapsed &&
              !isSourceCollapsed &&
              !isCollationCollapsed
            ) {
              targetPaneRef?.current?.expand()
              updateLayoutAndVisibility([
                [20, 30, 30, 20],
                false,
                false,
                false,
                false,
              ])
            } else if (
              !isSourcePhraseCollapsed &&
              !isSourceCollapsed &&
              isCollationCollapsed
            ) {
              targetPaneRef?.current?.expand()
              updateLayoutAndVisibility([
                [20, 39, 39, 2],
                false,
                false,
                false,
                true,
              ])
            } else if (
              isSourcePhraseCollapsed &&
              !isSourceCollapsed &&
              !isCollationCollapsed
            ) {
              targetPaneRef?.current?.expand()
              updateLayoutAndVisibility([
                [2, 39, 39, 20],
                true,
                false,
                false,
                false,
              ])
            } else if (
              isSourcePhraseCollapsed &&
              !isSourceCollapsed &&
              isCollationCollapsed
            ) {
              targetPaneRef?.current?.expand()
              updateLayoutAndVisibility([
                [2, 48, 48, 2],
                true,
                false,
                false,
                true,
              ])
            }
          } else {
            // Collapsing target scenarios
            if (
              !isSourcePhraseCollapsed &&
              !isSourceCollapsed &&
              !isCollationCollapsed
            ) {
              targetPaneRef?.current?.collapse()
              updateLayoutAndVisibility([
                [20, 58, 2, 20],
                false,
                false,
                true,
                false,
              ])
            } else if (
              !isSourcePhraseCollapsed &&
              !isSourceCollapsed &&
              isCollationCollapsed
            ) {
              targetPaneRef?.current?.collapse()
              updateLayoutAndVisibility([
                [20, 76, 2, 2],
                false,
                false,
                true,
                true,
              ])
            } else if (
              isSourcePhraseCollapsed &&
              !isSourceCollapsed &&
              !isCollationCollapsed
            ) {
              targetPaneRef?.current?.collapse()
              updateLayoutAndVisibility([
                [2, 76, 2, 20],
                true,
                false,
                true,
                false,
              ])
            } else if (
              isSourcePhraseCollapsed &&
              !isSourceCollapsed &&
              isCollationCollapsed
            ) {
              targetPaneRef?.current?.collapse()
              updateLayoutAndVisibility([
                [2, 96, 2, 2],
                true,
                false,
                true,
                true,
              ])
            }
          }
        } else if (from === 'collation') {
          if (isCollationCollapsed) {
            // Expanding collation scenarios
            if (
              !isSourcePhraseCollapsed &&
              !isSourceCollapsed &&
              !isTargetCollapsed
            ) {
              collationsPaneRef?.current?.expand()
              updateLayoutAndVisibility([
                [20, 30, 30, 20],
                false,
                false,
                false,
                false,
              ])
            } else if (
              !isSourcePhraseCollapsed &&
              !isSourceCollapsed &&
              isTargetCollapsed
            ) {
              collationsPaneRef?.current?.expand()
              updateLayoutAndVisibility([
                [20, 76, 2, 20],
                false,
                false,
                true,
                false,
              ])
            } else if (
              !isSourcePhraseCollapsed &&
              isSourceCollapsed &&
              !isTargetCollapsed
            ) {
              collationsPaneRef?.current?.expand()
              updateLayoutAndVisibility([
                [20, 2, 76, 20],
                false,
                true,
                false,
                false,
              ])
            } else if (
              isSourcePhraseCollapsed &&
              !isSourceCollapsed &&
              isTargetCollapsed
            ) {
              collationsPaneRef?.current?.expand()
              updateLayoutAndVisibility([
                [2, 76, 2, 20],
                true,
                false,
                true,
                false,
              ])
            } else if (
              isSourcePhraseCollapsed &&
              isSourceCollapsed &&
              !isTargetCollapsed
            ) {
              updateLayoutAndVisibility([
                [2, 2, 76, 20],
                true,
                true,
                false,
                false,
              ])
            } else if (
              !isSourcePhraseCollapsed &&
              isSourceCollapsed &&
              !isTargetCollapsed
            ) {
              updateLayoutAndVisibility([
                [20, 2, 78, 20],
                false,
                true,
                false,
                false,
              ])
            } else if (
              isSourcePhraseCollapsed &&
              !isSourceCollapsed &&
              !isTargetCollapsed
            ) {
              updateLayoutAndVisibility([
                [2, 39, 39, 20],
                true,
                false,
                false,
                false,
              ])
            }
          } else {
            // Collapsing source scenarios
            if (
              !isSourcePhraseCollapsed &&
              !isSourceCollapsed &&
              !isTargetCollapsed
            ) {
              collationsPaneRef?.current?.collapse()
              updateLayoutAndVisibility([
                [20, 39, 39, 2],
                false,
                false,
                false,
                true,
              ])
            } else if (
              !isSourcePhraseCollapsed &&
              !isSourceCollapsed &&
              isTargetCollapsed
            ) {
              collationsPaneRef?.current?.collapse()
              updateLayoutAndVisibility([
                [20, 76, 2, 2],
                false,
                false,
                true,
                true,
              ])
            } else if (
              !isSourcePhraseCollapsed &&
              isSourceCollapsed &&
              !isTargetCollapsed
            ) {
              updateLayoutAndVisibility([
                [20, 2, 76, 2],
                false,
                true,
                false,
                true,
              ])
            } else if (
              isSourcePhraseCollapsed &&
              !isSourceCollapsed &&
              !isTargetCollapsed
            ) {
              updateLayoutAndVisibility([
                [2, 48, 48, 2],
                true,
                false,
                false,
                true,
              ])
            } else if (
              isSourcePhraseCollapsed &&
              !isSourceCollapsed &&
              isTargetCollapsed
            ) {
              collationsPaneRef?.current?.collapse()
              updateLayoutAndVisibility([
                [2, 94, 2, 2],
                true,
                false,
                true,
                true,
              ])
            } else if (
              isSourcePhraseCollapsed &&
              isSourceCollapsed &&
              !isTargetCollapsed
            ) {
              collationsPaneRef?.current?.collapse()
              updateLayoutAndVisibility([
                [2, 2, 94, 2],
                true,
                true,
                false,
                true,
              ])
            }
          }
        }
      } else {
        if (showPhrasePane) {
          if (from === 'target') {
            if (targetPaneRef?.current?.isCollapsed()) {
              if (sourcePhrasePaneRef?.current?.isCollapsed()) {
                // To show the target pane when sourcePhrase is collapsed
                updateLayoutAndVisibility([
                  [2, 49, 49, 0],
                  true,
                  false,
                  false,
                  true,
                ])
                // myRef.current?.setLayout([2, 49, 49, 0])
                // setHideSourcePhrase(true)
                // setHideSource(false)
                // setHideTarget(false)
              } else {
                // To show the target pane when all other pane are expanded
                updateLayoutAndVisibility([
                  [20, 40, 40, 0],
                  false,
                  false,
                  false,
                  true,
                ])
                // myRef.current?.setLayout([20, 40, 40, 0])
                // setHideSourcePhrase(false)
                // setHideSource(false)
                // setHideTarget(false)
              }
            } else {
              if (sourcePhrasePaneRef?.current?.isCollapsed()) {
                // To hide the target pane when sourcePhrase is collapsed
                updateLayoutAndVisibility([
                  [2, 96, 2, 0],
                  true,
                  false,
                  true,
                  true,
                ])
                // targetPaneRef?.current?.collapse()
                // myRef.current?.setLayout([2, 96, 2, 0])
                // setHideSourcePhrase(true)
                // setHideSource(false)
                // setHideTarget(true)
              } else {
                // To hide the target pane when all other pane are expanded
                updateLayoutAndVisibility([
                  [20, 78, 2, 0],
                  false,
                  false,
                  true,
                  true,
                ])
                // targetPaneRef?.current?.collapse()
                // myRef.current?.setLayout([20, 78, 2, 0])
                // setHideSourcePhrase(false)
                // setHideSource(false)
                // setHideTarget(true)
              }
            }
          } else if (from === 'search_phrase') {
            if (!sourcePhrasePaneRef?.current?.isExpanded()) {
              if (targetPaneRef?.current?.isCollapsed()) {
                // To show the sourcePhrase pane when target is collapsed
                updateLayoutAndVisibility([
                  [20, 78, 2, 0],
                  false,
                  false,
                  true,
                  true,
                ])
                // sourcePhrasePaneRef?.current?.expand()
                // myRef.current?.setLayout([20, 78, 2, 0])
                // setHideSourcePhrase(false)
                // setHideSource(false)
                // setHideTarget(true)
              } else {
                // To show the sourcePhrase pane when all other pane are expanded
                updateLayoutAndVisibility([
                  [20, 40, 40, 0],
                  false,
                  false,
                  false,
                  true,
                ])
                // sourcePhrasePaneRef?.current?.expand()
                // myRef.current?.setLayout([20, 40, 40, 0])
                // setHideSourcePhrase(false)
                // setHideSource(false)
                // setHideTarget(false)
              }
            } else {
              if (targetPaneRef?.current?.isCollapsed()) {
                // To hide the sourcePhrase pane when target is collapsed
                updateLayoutAndVisibility([
                  [2, 96, 2, 0],
                  true,
                  false,
                  true,
                  true,
                ])
                // sourcePhrasePaneRef?.current?.collapse()
                // myRef.current?.setLayout([2, 96, 2, 0])
                // setHideSourcePhrase(true)
                // setHideSource(false)
                // setHideTarget(true)
              } else {
                // To hide the sourcePhrase pane when all other pane are expanded
                updateLayoutAndVisibility([
                  [2, 49, 49, 0],
                  true,
                  false,
                  false,
                  true,
                ])
                // sourcePhrasePaneRef?.current?.collapse()
                // myRef.current?.setLayout([2, 49, 49, 0])
                // setHideSourcePhrase(true)
                // setHideSource(false)
                // setHideTarget(false)
              }
            }
          }
        }
        // When sourcePhrase is hidden
        else {
          if (from === 'target') {
            if (targetPaneRef?.current?.isCollapsed()) {
              // myRef.current?.setLayout([0, 50, 50, 0])
              updateLayoutAndVisibility([
                [0, 50, 50, 0],
                true,
                false,
                false,
                true,
              ])
            } else {
              // myRef.current?.setLayout([0, 98, 2, 0])
              updateLayoutAndVisibility([
                [0, 98, 2, 0],
                true,
                false,
                true,
                true,
              ])
            }
          } else if (from === 'source') {
            if (sourcePaneRef?.current?.isCollapsed()) {
              // myRef.current?.setLayout([0, 50, 50, 0])
              updateLayoutAndVisibility([
                [0, 50, 50, 0],
                true,
                false,
                false,
                true,
              ])
            } else {
              // myRef.current?.setLayout([0, 2, 98, 0])
              updateLayoutAndVisibility([
                [0, 2, 98, 0],
                true,
                true,
                false,
                true,
              ])
            }
          }
        }
      }
    }
  }

  // this function will run whenever any change occur in layout
  const onLayout = (sizes) => {
    if (sizes && myRef.current?.getLayout()) {
      storeLayoutAndVisibilityTOLS(sizes)
    }
  }
  // This function will run on every initial render of the layout
  const getDefaultLayout = () => {
    try {
      if (layoutTypeObject) {
        const parsedLayout = JSON.parse(layoutTypeObject)
        if (showTargetPdfAndCollation) {
          if (areaList?.length > 0 || roiList?.length > 0) {
            // Further validate parsedLayout if necessary, for example:
            if (
              Array.isArray(parsedLayout) &&
              parsedLayout?.length > 0 &&
              Array.isArray(parsedLayout[0]) &&
              parsedLayout[0][3] > 2
            ) {
              return parsedLayout
            } else {
              return [[20, 40, 40, 20], false, false, false, false]
            }
          }
        } else {
          if (areaList?.length > 0 || roiList?.length > 0) {
            // Further validate parsedLayout if necessary, for example:
            if (
              Array.isArray(parsedLayout) &&
              parsedLayout?.length > 0 &&
              Array.isArray(parsedLayout[0]) &&
              parsedLayout[0][0] > 2
            ) {
              return parsedLayout
            } else {
              return [[20, 40, 40, 0], false, false, false, true]
            }
          } else {
            return [[0, 50, 50, 0], true, false, false, true]
          }
        }
      }
    } catch (error) {
      console.error('Error parsing layout from localStorage:', error)
    }
    // Fallback if localStorage data is missing, malformed, or on error
    return [[0, 50, 50, 0], true, false, false, true]
  }

  const [anchorEl, setAnchorEl] = React.useState(null)

  const handleClick = (event: any) => {
    // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
    setAnchorEl(event.currentTarget)
  }

  const handleClose = () => {
    setAnchorEl(null)
  }

  // setting tooltip message to show indeterminate progress bar based on conditions
  const getTooltipMessage = () => {
    // Default message
    let message

    // Check for specific file statuses to update the message
    if (
      projectStatus === 'COLLATION_IN_PROGRESS' ||
      projectStatus === 'PREPARING_FOR_COLLATION' ||
      projectStatus === 'INHERITING_COLLATIONS'
    ) {
      message = t('Collation is in progress, Please wait...')
    } else if (targetFiles?.some((file) => file.status === 'parsing')) {
      message = t('Parsing is in progress, please wait...')
    } else if (targetFiles?.some((file) => file.status === 'phrasing')) {
      message = t('Phrasing is in progress, please wait...')
    } else if (targetFiles?.some((file) => file.status === 'embedding')) {
      message = t('Embedding is in progress, please wait...')
    }

    return message
  }
  const open = Boolean(anchorEl)
  const id = open ? 'simple-popover' : undefined
  // Callback function to handle keydown events
  const handleKeyDown = useCallback(
    (e) => {
      // If the event target is an input or textarea, return early to allow typing
      if (['INPUT', 'TEXTAREA'].includes(e.target.tagName)) {
        return
      }

      // Check if the Tab key is pressed and the conditions are met
      if (e.key === 'Tab') {
        e.preventDefault() // Prevent the default tabbing behavior
      }
    },
    [projectStatus, targetDocumentStatus, targetFiles],
  ) // Dependencies to re-run the callback if these values change
  useEffect(() => {
    // Add the event listener for keydown
    window.addEventListener('keydown', handleKeyDown)

    // Cleanup the event listener on component unmount
    return () => {
      window.removeEventListener('keydown', handleKeyDown)
    }
  }, [handleKeyDown]) // Adding the callback function as a dependency
  return (
    <div
      className="page-wrapper"
      style={{
        pointerEvents:
          projectStatus === 'COLLATION_IN_PROGRESS' ||
          projectStatus === 'PREPARING_FOR_COLLATION' ||
          projectStatus === 'INHERITING_COLLATIONS' ||
          !isProgressComplete
            ? 'none'
            : 'auto',
        opacity:
          projectStatus === 'COLLATION_IN_PROGRESS' ||
          projectStatus === 'PREPARING_FOR_COLLATION' ||
          projectStatus === 'INHERITING_COLLATIONS' ||
          !isProgressComplete
            ? 0.5
            : 1,
      }}
    >
      {(isManualCollationInProgress ||
        projectStatus === 'COLLATION_IN_PROGRESS' ||
        projectStatus === 'PREPARING_FOR_COLLATION' ||
        projectStatus === 'INHERITING_COLLATIONS') && (
        <Box sx={{ width: '100%', marginTop: '53px' }}>
          <Tooltip title={getTooltipMessage()} placement="bottom">
            <LinearProgress />
          </Tooltip>
        </Box>
      )}
      {/* Search Phrase Pane */}
      {projectStatus &&
      !isAreaDataLoading &&
      !isROIDataLoading &&
      projectId &&
      defaultLayout &&
      defaultLayout?.length > 0 ? (
        <PanelGroup ref={myRef} direction="horizontal" onLayout={onLayout}>
          <Panel
            className="panel"
            ref={sourcePhrasePaneRef}
            style={{
              display: showPhrasePane ? 'block' : 'none',
              overflow: 'hidden',
              pointerEvents: isManualCollationInProgress ? 'none' : 'auto',
              opacity: isManualCollationInProgress ? 0.5 : 1,
            }}
            order={1}
            defaultSize={
              defaultLayout[0] && defaultLayout[0]?.length > 0
                ? defaultLayout[0][0]
                : 0
            }
            collapsedSize={showPhrasePane ? 2 : 0}
            collapsible
            minSize={20}
            maxSize={40}
            onCollapse={() => {
              setIsTargetExpanded(false)
              setHideSourcePhrase(true)
            }}
            onExpand={(): void => {
              setIsTargetExpanded(false)
              setHideSourcePhrase(false)
            }}
          >
            {hideSourcePhrase ? (
              <Box
                mt={2.4}
                sx={{
                  display: 'flex',
                  gap: '10px',
                  flexDirection: 'column',
                  alignItems: 'center',
                  justifyContent: 'start',
                  height: '100%', // Ensure full height
                }}
              >
                <span className="icon-wrapper">
                  <OpenToogleIcon
                    onClick={() => {
                      handleToggle('search_phrase')
                    }}
                    style={{ cursor: 'pointer' }} // Add pointer cursor on hover
                    className="default-icon"
                  />
                  <ActiveOpenToogleIcon
                    onClick={() => {
                      handleToggle('search_phrase')
                    }}
                    style={{ cursor: 'pointer' }} // Add pointer cursor on hover
                    className="active-icon"
                  />
                </span>
                <Typography
                  fontWeight={600}
                  fontSize={'20px'}
                  color={'var(--gray-900)'}
                  style={{ writingMode: 'vertical-rl' }}
                >
                  {t('Source Phrases')}
                </Typography>
              </Box>
            ) : (
              <div style={{ width: '100%', height: '100%' }}>
                <Box
                  display={'flex'}
                  justifyContent={'space-between'}
                  alignItems={'center'}
                  height={'65px'}
                >
                  <Box textAlign={'left'} m={2}>
                    <Typography
                      style={{ fontWeight: '600', fontSize: '20px' }}
                      component="div"
                    >
                      {t('Source Phrases')}
                      {(sourceCount === 0 || sourceCount > 0) && (
                        <Badge
                          badgeContent={sourceCount}
                          showZero
                          color="secondary"
                          style={{ marginLeft: '15px', marginBottom: '3px' }}
                        ></Badge>
                      )}
                    </Typography>
                  </Box>
                  <Box
                    m={1}
                    sx={{
                      display: 'flex',
                      alignItems: 'center',
                      textAlign: 'center',
                    }}
                  >
                    <Tooltip title={t('Information')}>
                      {anchorEl ? (
                        <IconButton
                          onClick={handleClick}
                          size="small"
                          aria-controls={open ? 'info-menu' : undefined}
                          aria-haspopup="true"
                          aria-expanded={open ? 'true' : undefined}
                        >
                          <FilledInfoIcon />
                        </IconButton>
                      ) : (
                        <IconButton
                          sx={{
                            '&:hover': { backgroundColor: 'transparent' },
                          }}
                          onClick={handleClick}
                          size="small"
                          aria-controls={open ? 'info-mMenuenu' : undefined}
                          aria-haspopup="true"
                          aria-expanded={open ? 'true' : undefined}
                        >
                          <span className="icon-wrapper">
                            <InfoIcon className="default-icon" />
                            <ActiveInfoIcon className="active-icon" />
                          </span>
                        </IconButton>
                      )}
                    </Tooltip>

                    <InfoMenu
                      anchorEl={anchorEl}
                      open={open}
                      handleClose={handleClose}
                      t={t}
                    />
                    {isNewAreaAdded && isNewRoiAdded ? (
                      <span className="icon-wrapper">
                        <CloseToogleIcon
                          style={{ cursor: 'pointer' }}
                          className="default-icon"
                          onClick={() => {
                            handleToggle('search_phrase')
                          }}
                        />
                        <ActiveCloseToogleIcon
                          style={{ cursor: 'pointer' }}
                          className="active-icon"
                          onClick={() => {
                            handleToggle('search_phrase')
                          }}
                        />
                      </span>
                    ) : (
                      <DisabledCloseToogleIcon
                        style={{ cursor: 'none', pointerEvents: 'none' }} // Add pointer cursor on hover
                      />
                    )}
                  </Box>
                </Box>
                <SourcePhrases
                  targetFiles={targetFiles}
                  setShowTargetPdfAndCollation={setShowTargetPdfAndCollation}
                  handleGetTargetFiles={handleGetTargetFiles}
                  isTargetDataLoading={isTargetDataLoading}
                  setTargetTitle={setTargetTitle}
                  isTargetExpanded={isTargetExpanded}
                  setIsTargetExpanded={setIsTargetExpanded}
                  isROIExpanded={isROIExpanded}
                  setIsROIExpanded={setIsROIExpanded}
                  phraseRef={phraseRef}
                  setSelectedResultId={setSelectedResultId}
                />
              </div>
            )}
          </Panel>
          <PanelResizeHandle
            className="sash"
            style={{
              width: '10px',
              direction: 'rtl',
              display: showPhrasePane ? 'block' : 'none',
            }}
            disabled={!showPhrasePane}
          />
          {/* Source PDF Pane */}
          <Panel
            minSize={20}
            order={2}
            defaultSize={
              defaultLayout?.[0] && defaultLayout[0]?.length > 0
                ? defaultLayout[0][1]
                : hideSource
                  ? 2
                  : 20
            }
            ref={sourcePaneRef}
            collapsible={
              !(showPhrasePane && !showTargetPdfAndCollation) &&
              !(
                showTargetPdfAndCollation &&
                targetPaneRef?.current?.isCollapsed()
              )
            }
            collapsedSize={2}
            className="panel"
            onCollapse={() => {
              setHideSource(true)
            }}
            onExpand={(): void => {
              setHideSource(false)
            }}
          >
            {hideSource ? (
              <Box
                mt={2.4}
                sx={{
                  display: 'flex',
                  gap: '10px',
                  flexDirection: 'column',
                  alignItems: 'center',
                  justifyContent: 'start',
                  height: '100%', // Ensure full height
                }}
              >
                <span className="icon-wrapper">
                  <OpenToogleIcon
                    onClick={() => {
                      handleToggle('source')
                    }}
                    style={{ cursor: 'pointer' }} // Add pointer cursor on hover
                    className="default-icon"
                  />

                  <ActiveOpenToogleIcon
                    onClick={() => {
                      handleToggle('source')
                    }}
                    className="active-icon"
                    style={{ cursor: 'pointer' }} // Add pointer cursor on hover
                  />
                </span>

                <Typography
                  fontWeight={600}
                  fontSize={'20px'}
                  color={'var(--gray-900)'}
                  style={{ writingMode: 'vertical-rl' }}
                >
                  {t('Source Document')}
                </Typography>
              </Box>
            ) : (
              <div className="pane1" id="pane1">
                <Box
                  display={'flex'}
                  justifyContent={'space-between'}
                  alignItems={'center'}
                >
                  <Box textAlign={'left'} m={2}>
                    <Typography
                      style={{ fontWeight: '600', fontSize: '20px' }}
                      component="div"
                    >
                      {t('Source Document')}
                    </Typography>
                    {!showPhrasePane && (
                      <Typography
                        style={{
                          fontWeight: '500',
                          fontSize: '14px',
                          color: 'var(--gray-600)',
                        }}
                      >
                        {t('Please select a phrase or draw a RoI box')}
                      </Typography>
                    )}
                  </Box>
                  {showTargetPdfAndCollation ? (
                    <span className="icon-wrapper">
                      <CloseToogleIcon
                        style={{ marginRight: '5px', cursor: 'pointer' }}
                        onClick={() => {
                          handleToggle('source')
                        }}
                        className="default-icon"
                      />
                      <ActiveCloseToogleIcon
                        style={{ marginRight: '5px', cursor: 'pointer' }}
                        onClick={() => {
                          handleToggle('source')
                        }}
                        className="active-icon"
                      />
                    </span>
                  ) : (
                    <DisabledCloseToogleIcon
                      style={{ cursor: 'none', pointerEvents: 'none' }} // Add pointer cursor on hover
                    />
                  )}
                </Box>

                <SourcePdf
                  setIstarget={setIstarget}
                  isTarget={isTarget}
                  sourceTitle={sourceTitle}
                  hideSourceControls={hideSourceControls}
                  projectId={projectId}
                />

                {/* Passing URL which is coming from route as state */}
              </div>
            )}
          </Panel>
          <PanelResizeHandle
            className="sash"
            style={{ width: '10px', direction: 'rtl' }}
          />

          {/* Target Part Pane */}

          <Panel
            className={`panel ${showTargetPdfAndCollation ? '' : 'target-panel'}`}
            minSize={20}
            defaultSize={
              defaultLayout?.[0] && defaultLayout[0]?.length > 0
                ? defaultLayout[0][2]
                : hideTarget
                  ? 2
                  : 20
            }
            order={3}
            ref={targetPaneRef}
            collapsible={
              !(
                showTargetPdfAndCollation &&
                sourcePaneRef?.current?.isCollapsed()
              )
            }
            collapsedSize={2}
            onCollapse={() => {
              setHideTarget(true)
              storeLayoutAndVisibilityTOLS(myRef.current?.getLayout())
            }}
            onExpand={(): void => {
              setHideTarget(false)
            }}
          >
            {hideTarget ? (
              <Box
                id={'targetPane'}
                mt={2.2}
                sx={{
                  display: 'flex',
                  gap: '10px',
                  flexDirection: 'column',
                  alignItems: 'center',
                  justifyContent: 'start',
                  height: '100%', // Ensure full height
                }}
              >
                <span className="icon-wrapper">
                  <CloseToogleIcon
                    onClick={() => {
                      handleToggle('target')
                    }}
                    className="default-icon"
                    style={{ cursor: 'pointer' }} // Add pointer cursor on hover
                  />
                  <ActiveCloseToogleIcon
                    onClick={() => {
                      handleToggle('target')
                    }}
                    className="active-icon"
                    style={{ cursor: 'pointer' }} // Add pointer cursor on hover
                  />
                </span>
                <Typography
                  fontWeight={600}
                  fontSize={'20px'}
                  color={'var(--gray-900)'}
                  style={{ writingMode: 'vertical-rl' }}
                >
                  {showTargetPdfAndCollation
                    ? t('Target Document')
                    : t('Target Documents')}
                </Typography>
              </Box>
            ) : (
              <>
                <Box
                  className="webkit-fill-available moz-available fill-available"
                  position={!showTargetPdfAndCollation ? 'fixed' : 'relative'}
                  zIndex={10}
                  width={'100%'}
                  style={{
                    background: 'var(--gray-white)',
                  }}
                >
                  {targetDocumentStatus === 'DONE' &&
                    targetFiles?.some(
                      (file) =>
                        file.status !== 'uploaded' &&
                        file.status !== 'embedded' &&
                        file.status !== 'failed',
                    ) && (
                      <Box style={{ zIndex: '100' }}>
                        <Tooltip title={getTooltipMessage()} placement="bottom">
                          <LinearProgress />
                        </Tooltip>
                      </Box>
                    )}
                  <Box
                    p={2}
                    display={'flex'}
                    justifyContent={'space-between'}
                    alignItems={'center'}
                  >
                    <Box display={'flex'} alignItems={'center'} gap={1}>
                      {showTargetPdfAndCollation && (
                        <Tooltip
                          title={t('Go Back to Upload Target')}
                          placement="top"
                        >
                          {isManualCollationInProgress ? (
                            <DisableGoBackIcon fontSize="inherit" />
                          ) : (
                            <div className="icon-wrapper">
                              <GoBackIcon
                                fontSize="inherit"
                                className="default-icon"
                              />
                              <ActiveGoBackIcon
                                onClick={() => {
                                  setShowTargetPdfAndCollation(false)
                                  setCollationList(null)
                                  setHideCollationPhrase(true)
                                  updateLayout([
                                    [20, 40, 40, 0],
                                    false,
                                    false,
                                    false,
                                    true,
                                  ])
                                  localStorage.removeItem('target_id')
                                  if (queryParameters.get('phrase_id')) {
                                    handleGetTargetFiles(
                                      projectId,
                                      '',
                                      queryParameters.get('phrase_id'),
                                    )
                                  } else {
                                    handleGetTargetFiles(projectId)
                                  }
                                  if (
                                    queryParameters.get('phrase_id') &&
                                    queryParameters.get('area_id')
                                  ) {
                                    navigate(
                                      `${basePath}?project_id=${queryParameters.get('project_id')}&area_id=${queryParameters.get('area_id')}&phrase_id=${queryParameters.get('phrase_id')}`,
                                      { state: { location }, replace: true },
                                    )
                                  } else if (
                                    !queryParameters.get('phrase_id') &&
                                    queryParameters.get('area_id')
                                  ) {
                                    navigate(
                                      `${basePath}?project_id=${queryParameters.get('project_id')}&area_id=${queryParameters.get('area_id')}`,
                                      { state: { location }, replace: true },
                                    )
                                  } else {
                                    navigate(
                                      `${basePath}?project_id=${queryParameters.get(
                                        'project_id',
                                      )}`,
                                      { state: { location }, replace: true },
                                    )
                                  }
                                }}
                                fontSize="inherit"
                                className="active-icon"
                              />
                            </div>
                          )}
                        </Tooltip>
                      )}
                      <Typography
                        style={{ fontWeight: '600', fontSize: '20px' }}
                        component="div"
                        textAlign={'left'}
                      >
                        {showTargetPdfAndCollation
                          ? t('Target Document')
                          : t('Target Documents')}
                      </Typography>
                    </Box>

                    <Stack direction={'row'} alignItems={'center'}>
                      {!showTargetPdfAndCollation && (
                        <Link
                          to={getLocalizedPath(
                            currentLanguage,
                            `/result?project_id=${projectId}`,
                          )}
                        >
                          <span style={{ color: 'var(--primary-600)' }}>
                            {t('view results')}
                          </span>
                        </Link>
                      )}
                      <span className="icon-wrapper">
                        <OpenToogleIcon
                          onClick={() => {
                            handleToggle('target')
                          }}
                          className="default-icon"
                          style={{ cursor: 'pointer' }} // Add pointer cursor on hover
                        />
                        <ActiveOpenToogleIcon
                          onClick={() => {
                            handleToggle('target')
                          }}
                          className="active-icon"
                          style={{ cursor: 'pointer' }} // Add pointer cursor on hover
                        />
                      </span>
                    </Stack>
                  </Box>
                </Box>
                <Box
                  style={{
                    pointerEvents:
                      targetDocumentStatus === 'DONE' &&
                      targetFiles?.some(
                        (file) =>
                          file.status !== 'uploaded' &&
                          file.status !== 'embedded' &&
                          file.status !== 'failed',
                      )
                        ? 'none'
                        : 'auto',
                    opacity:
                      targetDocumentStatus === 'DONE' &&
                      targetFiles?.some(
                        (file) =>
                          file.status !== 'uploaded' &&
                          file.status !== 'embedded' &&
                          file.status !== 'failed',
                      )
                        ? 0.5
                        : 1,
                  }}
                >
                  {showTargetPdfAndCollation ? (
                    <TargetPdf
                      setIstarget={setIstarget}
                      isTarget={isTarget}
                      targetTitle={targetTitle}
                      currentPage={currentPage}
                      setCurrentPage={setCurrentPage}
                      handleGetTargetFiles={handleGetTargetFiles}
                    />
                  ) : (
                    <UploadTarget
                      targetFiles={targetFiles}
                      setTargetFiles={setTargetFiles}
                      uploadProgress={uploadProgress}
                      setUploadProgress={setUploadProgress}
                      isFinalizeButtonDisabled={isFinalizeButtonDisabled}
                      handleGetTargetFiles={handleGetTargetFiles}
                      setTargetTitle={setTargetTitle}
                      isTargetDataLoading={isTargetDataLoading}
                      setIsTargetDataLoading={setIsTargetDataLoading}
                    />
                  )}
                </Box>
              </>
            )}
          </Panel>
          <>
            {showTargetPdfAndCollation && (
              <PanelResizeHandle
                className="sash"
                style={{ width: '10px', direction: 'rtl' }}
                disabled={collationList?.length === 0}
              />
            )}

            <Panel
              className="panel"
              id="collationPane"
              ref={collationsPaneRef}
              style={{
                display: showTargetPdfAndCollation ? 'block' : 'none',
                pointerEvents: isManualCollationInProgress ? 'none' : 'auto',
                opacity: isManualCollationInProgress ? 0.5 : 1,
              }}
              order={4}
              defaultSize={
                defaultLayout?.[0] && defaultLayout[0]?.length > 0
                  ? defaultLayout[0][3]
                  : 0
              }
              collapsible
              collapsedSize={showTargetPdfAndCollation ? 2 : 0}
              minSize={showTargetPdfAndCollation ? 20 : 0}
              maxSize={showTargetPdfAndCollation ? 40 : 0}
              onCollapse={() => {
                setHideCollationPhrase(true)
              }}
              onExpand={(): void => {
                setHideCollationPhrase(false)
              }}
            >
              {hideCollationPhrase ? (
                <Box
                  mt={2.2}
                  sx={{
                    display: 'flex',
                    gap: '10px',
                    flexDirection: 'column',
                    alignItems: 'center',
                    justifyContent: 'start',
                    height: '100%', // Ensure full height
                  }}
                >
                  {(showTargetPdfAndCollation ||
                    projectStatus === 'WAITING_FOR_COLLATION_APPROVAL' ||
                    projectStatus === 'COMPLETED' ||
                    projectStatus === 'COLLATION_VERIFICATION') &&
                  (areaList?.length > 0 || roiList?.length > 0) &&
                  isNewAreaAdded &&
                  isNewRoiAdded ? (
                    <span className="icon-wrapper">
                      <OpenToogleIcon
                        onClick={() => {
                          handleToggle('collation')
                        }}
                        className="default-icon"
                        style={{ cursor: 'pointer' }} // Add pointer cursor on hover
                      />
                      <ActiveOpenToogleIcon
                        onClick={() => {
                          handleToggle('collation')
                        }}
                        className="active-icon"
                        style={{ cursor: 'pointer' }} // Add pointer cursor on hover
                      />
                    </span>
                  ) : (
                    <DisabledOpenToogleIcon />
                  )}
                  <Typography
                    fontWeight={600}
                    fontSize={'20px'}
                    color={'var(--gray-900)'}
                    style={{ writingMode: 'vertical-rl' }}
                  >
                    {t('Collations')}
                  </Typography>
                </Box>
              ) : (
                <div style={{ width: '100%', height: '100%' }}>
                  <Box
                    display={'flex'}
                    justifyContent={'space-between'}
                    alignItems={'center'}
                  >
                    <Box
                      textAlign={'left'}
                      m={2}
                      display={'flex'}
                      alignItems={'center'}
                      gap={2.5}
                    >
                      <Typography
                        style={{ fontWeight: '600', fontSize: '20px' }}
                        component="div"
                      >
                        {t('Collations')}
                      </Typography>
                      <Badge
                        badgeContent={collationList?.length}
                        color="secondary"
                        showZero
                        style={{ marginBottom: '3px' }}
                      ></Badge>
                    </Box>
                    <Box
                      display={'flex'}
                      justifyContent={'center'}
                      alignItems={'center'}
                    >
                      <Link
                        to={getLocalizedPath(
                          currentLanguage,
                          `/result?project_id=${projectId}`,
                        )}
                      >
                        <p style={{ color: 'var(--primary-600)' }}>
                          {t('view results')}
                        </p>
                      </Link>
                      {showTargetPdfAndCollation ? (
                        <span className="icon-wrapper">
                          <CloseToogleIcon
                            onClick={() => {
                              handleToggle('collation')
                            }}
                            className="default-icon"
                          />
                          <ActiveCloseToogleIcon
                            onClick={() => {
                              handleToggle('collation')
                            }}
                            className="active-icon"
                          />
                        </span>
                      ) : (
                        <DisabledCloseToogleIcon />
                      )}
                    </Box>
                  </Box>

                  <Collations
                    setCurrentPage={setCurrentPage}
                    handleGetTargetFiles={handleGetTargetFiles}
                    isTargetDataLoading={isTargetDataLoading}
                    selectedResultId={selectedResultId}
                    setSelectedResultId={setSelectedResultId}
                  />
                </div>
              )}
            </Panel>
          </>
        </PanelGroup>
      ) : (
        <div
          style={{
            paddingTop: '20rem',
          }}
        >
          <CircularProgress />
        </div>
      )}
    </div>
  )
}
export default UploadSearchCollation

// eslint-disable-next-line react/prop-types
function InfoMenu({ anchorEl, open, handleClose, t }) {
  // Prevent the menu from closing when clicking inside
  const handleMenuClick = (event) => {
    event.stopPropagation()
  }

  return (
    <Menu
      anchorEl={anchorEl}
      id="info-menu"
      open={open}
      onClose={handleClose}
      onClick={handleMenuClick} // Changed to handleMenuClick to stop propagation
      PaperProps={{
        elevation: 0,
        sx: {
          overflow: 'visible',
          filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
          mt: 3.5,
          '&::before': {
            content: '""',
            display: 'block',
            position: 'absolute',
            top: 0,
            left: 12,
            width: 10,
            height: 10,
            bgcolor: 'background.paper',
            transform: 'translateY(-50%) rotate(45deg)',
            zIndex: 0,
          },
        },
      }}
      transformOrigin={{
        horizontal: 'left',
        vertical: 'top',
      }}
      anchorOrigin={{ horizontal: 'left', vertical: 'top' }}
    >
      <MenuItem disableRipple style={{ cursor: 'text' }}>
        <Typography fontWeight={600}>{t('Info')}</Typography>
      </MenuItem>
      <MenuItem
        disableRipple
        style={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'start',
          cursor: 'text',
        }}
      >
        <Typography fontWeight={500}>
          {t('Please select a Region of Interest in the Source Document')}
        </Typography>
        <Typography fontWeight={500}>
          {t('To create a Region of Interest use ALT + drag')}
        </Typography>
        <Typography fontWeight={500}>
          {t('To create a Source Phrase Select text and drag')}
        </Typography>
      </MenuItem>
      <Divider />
      <MenuItem disableRipple style={{ cursor: 'text' }}>
        <Typography fontWeight={600}>
          {t('Source Phrase color code')}
        </Typography>
      </MenuItem>
      <MenuItem
        sx={{
          cursor: 'text',
          paddingLeft: '20px',
          '&::before': {
            content: '""',
            display: 'block',
            position: 'absolute',
            width: 10,
            height: 10,
            bgcolor: 'var(--red-600)',
            borderRadius: '50%',
            zIndex: 0,
          },
        }}
      >
        <Typography pl={'20px'} color={'var(--red-600)'} fontWeight={500}>
          {t('Missing from new file')}
        </Typography>
      </MenuItem>
      <MenuItem
        disableRipple
        sx={{
          paddingLeft: '20px',
          '&::before': {
            content: '""',
            display: 'block',
            position: 'absolute',
            width: 10,
            height: 10,
            bgcolor: 'var(--yellow-500)',
            borderRadius: '50%',
            zIndex: 0,
          },
        }}
      >
        <Typography pl={'20px'} fontWeight={500} color={'var(--yellow-500)'}>
          {t('Page Number(s) Changed')}
        </Typography>
      </MenuItem>
    </Menu>
  )
}
